import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios"; // Ensure axios is imported

// Async thunk to fetch fuel data
const AppToken = localStorage.getItem("APP_TOKEN");
export const fetchVendorPaymentData = createAsyncThunk(
  "vendorpayment/fetchVendorPayment",
  async ({ formData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NEW_APP_API_URL}/get-vendor-payment-details`,
        formData,
        {
          headers: {
            Authorization: AppToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch fuel data"
      );
    }
  }
);

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const VendorPaymentSlice = createSlice({
  name: "vendorpayment",
  initialState,
  reducers: {
    resetVendorPaymentState(state) {
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendorPaymentData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVendorPaymentData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchVendorPaymentData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetVendorPaymentState } = VendorPaymentSlice.actions;
export default VendorPaymentSlice.reducer;
