import React, { useEffect, useState } from 'react'
import { headerLogo } from '../../images'
import { genderList, relationshipOPDList } from '../../data';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { validateNumberInput } from '../../utils/AllFunction';
import { doctorTimeSlotForm, fetchCommonForm, opd_book } from '../../Redux/actions/PostApiCall';
import LoaderAnime from "../Medicine/animation/prescription_loader";
import Medicine_Success from '../Medicine/animation/Medicine_Success';
import useLocationState from '../Medicine/hooks/useLocationState';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { opdBanner } from '../Medicine/Images';
import toast from 'react-hot-toast';



const OceanCommonForm = () => {
    const [successmodal, setSuccessModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Data = useLocationState();
    console.log(Data)
    const opd_response = useSelector((state) => state?.vendor_common_api);
    console.log(opd_response)
    const UserData = JSON.parse(localStorage.getItem("UserData"))
    const { register, handleSubmit, formState: { errors } } = useForm();
    const AppToken = localStorage.getItem("APP_TOKEN");
    const [successMessage, setSuccessMessage] = useState("")
    const onSubmit = async (data) => {
        const formattedDate = selectedDate ? format(selectedDate, 'dd-MM-yyyy') : null;
        setModalShow(true);

        // Create FormData instance
        const formData = new FormData();

        // Define your data object
        const onDataSubmit = {
            name: UserData?.name,
            number: UserData?.mobile_number,
            email: UserData?.email_id,
            type: Data?.Type,
            message: "",
            partner_name: Data?.data?.vendor_name || "",
            package_name: "",
            amount: "",
            // user_id: UserData?.id,
            patient_name: data?.patient_name,
            age: data?.patient_age,
            age_type: data?.age_type,
            gender: data?.gender,
            relation: data?.relation,
            pincode: data?.pincode,
            city: data?.city,
            state: data?.state,
            description: "",
            date: formattedDate,
            time: data?.time
        };

        // Log the data before appending to formData (optional)
        console.log(onDataSubmit);

        // Append each key-value pair from onDataSubmit to the FormData instance
        Object.keys(onDataSubmit).forEach((key) => {
            formData.append(key, onDataSubmit[key]);
        });

        const apiEndpoint = `${process.env.REACT_APP_NEW_APP_API_URL}/save-request-data`;

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                body: formData,
                headers: {
                    "Authorization": AppToken
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data)
            if (data?.success === true) {
                setModalShow(false)
                setSuccessModal(true)
                setSuccessMessage(data?.message)

                setTimeout(() => {
                    setSuccessModal(false)
                    navigate("/home")
                }, 2500);
            }
            else if (data?.success === false) {
                setModalShow(false)
                toast.error(data?.message)
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }
    };

    const [loading, setLoading] = useState(false);
    const DoctorTimeSlot = useSelector(state => state.doctor_time_slot_form);
    const [selectedDate, setSelectedDate] = useState('');

    const handleDateChange = (date) => {
        const formData = new FormData();
        setSelectedDate(date);
        const formattedDate = format(date, 'yyyy-MM-dd');

        if (formattedDate) {
            const RepoData = {
                date: formattedDate
            }
            dispatch(doctorTimeSlotForm(RepoData))
                .finally(() => setLoading(false)); // End loading after the API call completes
        }
    };

    const minDate = new Date(); // Current date
    const maxDate = new Date(); // 3 months from now
    maxDate.setMonth(maxDate.getMonth() + 3);


    return (
        <div className="home-review-banner">
            {modalShow && <LoaderAnime showModal={modalShow} />}
            <div className="home-app-header d-flex justify-content-start align-items-center sticky-top">

                <div className="header-logo mx-3">
                    <img
                        src={headerLogo}
                        className="header-medibhai-logo"
                        width="100%"
                        alt="header-logo"
                    />
                    <p className='opd-text'>{Data?.title}</p>
                </div>
            </div>
            <div className="opd-wrapper-cover-data mt-3">
                <div className="container">
                    <div className="image-form-opd">
                        <img src={Data?.data?.form_banner} alt="" />
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-data-repo-box">
                            <input type="text" value={UserData?.mobile_number} disabled />
                            <input type="text" value={UserData?.email_id} disabled />
                        </div>
                        <div className="form-page-data">
                            <div className="page-heading-text">
                                <p>Consultation Details</p>
                            </div>

                            <div className="form-page">
                                <input type="text" placeholder='Enter Patient Name' {...register('patient_name', { required: true })} />
                                {errors.patient_name && <span className='error-text-mssg'>This field is Required</span>}
                                <div className="option-data-page">
                                    <div className="gender-select-page">
                                        <select
                                            name="gender"
                                            {...register('gender', { required: true })}>
                                            <option value="" selected disabled>Select Gender</option>
                                            {genderList.map((data, index) => (
                                                <option key={index} value={data?.text}>{data?.text}</option>

                                            ))}
                                        </select>
                                        {errors.gender && <span className='error-text-mssg gen-text'>Gender is required</span>}
                                    </div>
                                    <div className="gender-select-page">
                                        <select
                                            name="relation"
                                            {...register('relation', { required: true })}>
                                            <option value="">Select Relation</option>
                                            {relationshipOPDList.map((data, index) => (
                                                <option key={index} value={data?.text}>{data?.text}</option>
                                            ))}
                                        </select>
                                        {errors.relation && <span className='error-text-mssg gen-text'>Relation is required</span>}
                                    </div>
                                </div>
                                <div className="option-data-page mb-0">
                                    <div className="gender-select-page">
                                        <input type="text" placeholder='Enter Patient Age' onInput={validateNumberInput} maxLength={3} {...register('patient_age', { required: true })} />
                                        {errors.patient_age && <span className='error-text-mssg'>age is required</span>}
                                    </div>
                                    <div className="gender-select-page">
                                        <select
                                            {...register("age_type", {
                                                required: "Age type is required",
                                            })}
                                            defaultValue="" // sets default selected value
                                        >
                                            <option value="" disabled>
                                                Select Age Type
                                            </option>
                                            <option value="days">Days</option>
                                            <option value="months">Month</option>
                                            <option value="years">Years</option>
                                        </select>

                                        <span className="error-text-mssg gen-text">
                                            {errors.age_type && errors.age_type.message}
                                        </span>
                                    </div>
                                </div>
                                <div className="option-data-page mb-0">
                                    <div className="gender-select-page">
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            className="bankai-date"
                                            id="cancelDate"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Select Date"
                                            minDate={new Date(new Date().setDate(new Date().getDate() + 1))}  // Set minDate to tomorrow
                                            maxDate={maxDate}
                                            autoComplete="off"
                                            required
                                        />

                                    </div>
                                    <div className="gender-select-page">
                                        <select
                                            id="cancelReason"
                                            defaultValue=""
                                            {...register("time", {
                                                required: "time is required",
                                            })}
                                            disabled={loading || !DoctorTimeSlot?.data?.time_slot?.length}
                                            style={{ padding: '0px', height: '40px' }}
                                        >
                                            <option value="" disabled>
                                                Select Time
                                            </option>
                                            {DoctorTimeSlot?.data?.time_slot?.map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="error-text-mssg gen-text">
                                            {errors.time && errors.time.message}
                                        </span>

                                    </div>

                                </div>
                                <input
                                    placeholder="Enter Pincode"
                                    {...register("pincode", {
                                        required: "Pincode is required",
                                        pattern: {
                                            value: /^[0-9]{6}$/,
                                            message: "Invalid pincode"
                                        },
                                    })}
                                    maxLength={6}
                                    onInput={validateNumberInput}
                                />
                                {errors.pincode && <span className="error-text-mssg">{errors.pincode.message}</span>}
                                <input
                                    type="text"
                                    id="city"
                                    placeholder='Enter City'
                                    {...register("city", {
                                        required: "city is required",
                                    })}
                                />
                                {errors.city && <span className="error-text-mssg">{errors.city.message}</span>}
                                <input
                                    type="text"
                                    id="state"
                                    placeholder='Enter State'
                                    {...register("state", {
                                        required: "state is required",
                                    })}
                                />
                                {errors.state && <span className="error-text-mssg">{errors.state.message}</span>}
                            </div>
                        </div>

                        <div className="submit-btn-wrap">
                            <button>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            {successmodal && <Medicine_Success showModal={successmodal} message={successMessage} status={opd_response?.success} />}
        </div>
    )
}

export default OceanCommonForm;