import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'; // You can use this or any loading component
import '../../style/cancelorder.css';
import { useForm } from 'react-hook-form';
import { cancel_order_image } from '../../../Medicine/Images';
import { useDispatch, useSelector } from 'react-redux';
import { CancelDiagnosticOrder, CancelOrder, CancelRadiologyOrder } from '../../../../Redux/actions/PostApiCall';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';

const CancelOrderModal = ({ show, setShow, OrderId, labCancelOrderDetails, tabkey, setCancelReason, handleReorder }) => {
    const handleClose = () => setShow(false);
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [selectedType, setSelectedType] = useState('');
    const [customReason, setCustomReason] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orderSuccess, setOrderSuccess] = useState(false);
    const navigate = useNavigate();

    const cancel = "cancel"

    const orderCancelData = useSelector(state => state.cancel_order_medicine);
    const TrackData = useSelector(state => state.track_order.data);
    const TeleTrackData = useSelector(state => state.teleconsultation_list_details.data);
    const diagnosticOrderCancelData = useSelector(state => state.cancel_order_diagnotic);
    const radiologyOrderCancelData = useSelector(state => state.cancel_order_radiology);
    console.log(diagnosticOrderCancelData)
    const reorderTeleconsultation = useSelector(state => state.teleconsultation_rebook);
    const OpdCancelList = useSelector(state => state.doctor_order_detail);
    const dentalCancelList = useSelector(state => state.dental_order_detail);
    const eyeCancelList = useSelector(state => state.eye_order_detail);
    const reorderOpd = useSelector(state => state.opd_rebook);
    const reorderDental = useSelector(state => state.dental_resedule);
    const reorderEye = useSelector(state => state.eye_resedule);

    const OrderReasons = TrackData || TeleTrackData || labCancelOrderDetails?.data || OpdCancelList?.data || dentalCancelList?.data || eyeCancelList?.data;
    const errorMessage = orderCancelData || diagnosticOrderCancelData || reorderTeleconsultation || radiologyOrderCancelData || reorderOpd || reorderDental || reorderEye

    console.log(OrderReasons)

    useEffect(() => {
        // Check if the status is loading or success
        if (orderCancelData?.status === 'loading' || diagnosticOrderCancelData?.status === 'loading' || reorderTeleconsultation?.status === 'loading' || radiologyOrderCancelData?.status === "loading" || reorderOpd?.status === "loading" || reorderDental?.status === "loading" || reorderEye?.status === "loading") {
            setLoading(true);
        } else if (orderCancelData?.data?.success === true || diagnosticOrderCancelData?.data?.success === true || reorderTeleconsultation?.data?.success === true || radiologyOrderCancelData?.data?.success === true || reorderOpd?.data?.success === true || reorderDental?.data?.success === true || reorderEye?.data?.success === true) {
            setLoading(false);
            setOrderSuccess(true);
        }
        else if (orderCancelData?.status === 'failure' || diagnosticOrderCancelData?.status === 'failure' || reorderTeleconsultation?.status === 'failure' || radiologyOrderCancelData?.status === "failure" || reorderOpd?.status === "failure" || reorderDental?.status === "failure" || reorderEye?.status === "failure") {
            toast.error(errorMessage?.error)
            setShowConfirmation(false)
        }
        else {
            setLoading(false);
        }
    }, [orderCancelData, diagnosticOrderCancelData, reorderTeleconsultation, radiologyOrderCancelData, reorderOpd, reorderDental, reorderEye]);

    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
    };


    const handleCanelOrder = async () => {
        setLoading(true);
        const formData = new FormData();
        const reason = selectedType === '6' ? customReason : selectedType;

        const diagnosticData = {
            diagnostic_order_id: OrderId,
            cancel_reason: reason,
        }
        const RadioData = {
            diagnostic_order_id: OrderId,
            cancel_reason: reason,
        }
        const data = {
            medicine_order_id: OrderId,
            cancellation_reason: reason,
            source: 0
        };

        const opdData = {

        }


        if (tabkey === "second") {
            await dispatch(CancelDiagnosticOrder(diagnosticData))
        }
        else if (tabkey === "third") {
            handleReorder(cancel)
        }
        else if (tabkey === "forth") {
            await dispatch(CancelRadiologyOrder(RadioData))
        }
        else if (tabkey === "fifth") {
            handleReorder(cancel)
        }
        else if (tabkey === "Six") {
            handleReorder(cancel)
        }
        else if (tabkey === "Seven") {
            handleReorder(cancel)
        }
        else {
            await dispatch(CancelOrder(data));
        }
    };

    const handleCancelOrder = () => {
        if (tabkey === "third" || tabkey === "fifth" || tabkey === "Six" || tabkey === "Seven") {
            const reason = selectedType === '6' ? customReason : selectedType;
            setCancelReason(reason);
            setShowConfirmation(true);
        } else {
            setShowConfirmation(true);
        }
    };

    const handleCustomReasonChange = (e) => {
        setCustomReason(e.target.value);
    };

    const handleOk = () => {
        window.location.reload();
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                backdrop="static"
                keyboard={false}
            >
                {!showConfirmation ? (
                    <>
                        <Modal.Header closeButton className='head-canel-repo'>
                            <Modal.Title className='reason-title'>Reasons for cancellation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='repo-class-cancel'>
                            <div className="cancel-order-form-box">
                                {OrderReasons?.cancellation_reason_list?.map((item, index) => (
                                    <div>
                                        <input
                                            id={item?.value}
                                            type="radio"
                                            name="type"
                                            value={item?.value}
                                            onChange={handleTypeChange}
                                        />
                                        <label htmlFor={item?.value}>{item?.label}</label>
                                    </div>
                                ))}

                                <div>
                                    <input
                                        id="radio-6"
                                        type="radio"
                                        name="type"
                                        value="6"
                                        onChange={handleTypeChange}
                                    />
                                    <label htmlFor="radio-6">Other</label>
                                </div>

                                {selectedType === '6' && (
                                    <div className="other-input">
                                        <label htmlFor="otherReason">Please enter reason:</label>
                                        <input
                                            id="otherReason"
                                            type="text"
                                            value={customReason} // Bind value to customReason state
                                            onChange={handleCustomReasonChange}
                                        />
                                    </div>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='foot-cancel-repo'>
                            <Button variant="danger" onClick={handleCancelOrder} disabled={(selectedType === "") || (selectedType === '6' && customReason === '')}>
                                Cancel {tabkey === "third" || tabkey === "first" ? "Order" : "Appointment"}
                            </Button>
                        </Modal.Footer>
                    </>
                ) : (
                    <Modal.Body className='repo-class-cancel'>
                        {loading ? (
                            <div className="loading-spinner">
                                <Spinner animation="border" role="status">
                                </Spinner>
                                <span className="sr-only">Please Wait...</span>
                            </div>
                        ) : orderSuccess ? (
                            <div className="confirmation-message">
                                <div className="cancel-order-image">
                                    <img src={cancel_order_image} alt="Confirmation Icon" className="confirmation-image" />
                                </div>
                                <p>{orderCancelData?.data?.message || diagnosticOrderCancelData?.data?.message || reorderTeleconsultation?.data?.message || radiologyOrderCancelData?.data?.message || reorderOpd?.data?.message || reorderDental?.data?.message || reorderEye?.data?.message}</p>
                                <Button className='no-modal-data' onClick={handleOk}>OK</Button>
                            </div>
                        ) : (
                            <div className="confirmation-message">
                                <div className="cancel-order-image">
                                    <img src={cancel_order_image} alt="Confirmation Icon" className="confirmation-image" />
                                </div>
                                <p>
                                    Are you sure you want to cancel the {tabkey === "third" || tabkey === "first" ? "order" : "appointment"}?
                                </p>

                                <div className="confirmation-buttons">
                                    <Button className='yes-modal-data' onClick={handleCanelOrder}>Yes</Button>
                                    <Button className='no-modal-data' onClick={() => setShowConfirmation(false)}>No</Button>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                )}
            </Modal>
        </div>
    );
};

export default CancelOrderModal;
