import React, { useState } from 'react'
import { headerLogo } from '../../images'
import useLocationState from '../Medicine/hooks/useLocationState'
import { useNavigate } from 'react-router';

const DiabetiesSingleDetail = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const Data = useLocationState();
    console.log(Data)
    const navigate = useNavigate();

    const handleForm = () => {
        navigate("/diabeties-form", { state: Data })
    }
    return (
        <div className="home-review-banner">
            {/* {modalShow && <LoaderAnime showModal={modalShow} />} */}
            <div className="home-app-header-new sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <h6 style={{ marginLeft: '5px', textTransform: 'capitalize' }}>{Data?.package_name}</h6>
                    </div>
                </div>

            </div>
            <div className="wrapper-repo-buck">
                <div className="wrapper-wellness-diabetes-cover">
                    <div className="repo-image-diabetes">
                        <img src={Data?.package_banner} alt="" />
                    </div>
                </div>
                <div className="wrapper-wellness-loki-buck">
                    <p className={`description-text ${isExpanded ? "expanded" : "collapsed"}`}>
                        {Data?.package_description}
                    </p>
                    <button className="read-more-btn-buck" onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? "Read Less" : "Read More"}
                    </button>
                </div>

                <div className="repo-pack-title-buck">
                    <h6>{Data?.package_name}</h6>
                </div>

                <div className="package-ben-buck">
                    <div className="tit-repo-core">
                        <p>Package Benefits :</p>
                    </div>
                    <div className="data-buck-text-ben">
                        <p dangerouslySetInnerHTML={{ __html: Data?.product_benefits || "" }}></p>
                    </div>
                </div>
            </div>

            <div className="repo-buck-footer sticky-bottom">
                <div className="repo-price-buck">
                    <span>₹ {Data?.package_mrp_price}</span>
                    <p>₹ {Data?.package_medibhai_price}</p>
                </div>
                <button onClick={handleForm}>
                    AVAIL BENEFIT
                </button>
            </div>
        </div>
    )
}

export default DiabetiesSingleDetail