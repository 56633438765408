// SuccessFull.js

import React, { useState } from 'react';
import Lottie from "lottie-react";
import SuccessFully from "../../../AnimationJson/success.json";
import ErrorFull from "../../../AnimationJson/error-message.json";

import Modal from 'react-bootstrap/Modal';
import { MdOutlineContentCopy } from 'react-icons/md';
import toast from 'react-hot-toast';
import { IoClose } from 'react-icons/io5';

const Reedem = ({ showModal, message, status, Data, setShowModal }) => {
    // const { show, successMessage, animationtype } = props;
    console.log(Data)

    const [copied, setCopied] = useState(false);

    const couponCode = Data?.data?.data?.coupon_code || "N/A"; // Default value if no coupon

    const handleCopy = () => {
        navigator.clipboard.writeText(couponCode);
        toast.success("Copy to our clipborad")
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleReedem = () => {
        window.location.href = Data?.data?.data?.redirect_url
    }

    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}  // Close modal on button click
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* Modal Header with Close Button */}
            <Modal.Header>
                <button className="modal-close-btn" onClick={() => setShowModal(false)}>
                    <IoClose size={24} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <Lottie animationData={status === (false) ? ErrorFull : SuccessFully} className='success-data-animation' />
                <div className="coupon-container">
                    <p
                        className="success-msg"
                        style={{ color: status === false ? "red" : "green" }}
                        dangerouslySetInnerHTML={{ __html: Data?.data?.data?.show_message || "" }}
                    ></p>

                    {/* Coupon Code Section */}
                    <div className="coupon-box">
                        <span className="coupon-code">{couponCode}</span>
                        <button className="copy-button" onClick={handleCopy}>
                            <MdOutlineContentCopy />
                        </button>
                    </div>

                    {/* Redeem Button */}
                    <button className="Repo-redeem-code" onClick={handleReedem}>Redeem Code</button>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default Reedem;
