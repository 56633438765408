import { React, useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { headerLogo, physician_icon } from "../../../images";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { TbChevronRight } from "react-icons/tb";
import SpecialityProduct from "../Components/SpecialityProduct";
import NoDataFoundComp from "../../../components/NoDataFound";
import SymptomsCard from "../Components/SymptomsCard";
import TeleconsultationSection from "../../LocationSection/TeleconsultationSection";
import WaitLoader from "../../../Animation/WaitLoader";
import How_it_works from "../Components/How_it_works";
import LoaderAnime from "../../Medicine/animation/prescription_loader";
import {
  fetchDepartmentsList,
  fetchPackageList,
  fetchSymptomsList,
  fetchBannerList,
} from "../../../Redux/actions/GetApiCall";
import GeneralPhysicianComponent from "../Components/GeneralPhysician";
import usePackageData from "../../../utils/usePackageData";
import CommonPackage from "../../MyProfile/Components/CommonPackage";

const HomeTeleConsultation = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [packageData] = usePackageData();
  const NutritionID = localStorage.getItem("NutritionID");
  const [bannerData2, setBannerData2] = useState([]);
  const navigate = useNavigate();

  const V2HREF = localStorage.getItem("V2HREF");

  const packagesData = useSelector((state) => state.package_list);
  const departmentListData = useSelector(
    (state) => state.tele_department_list_response
  );
  const symptomsListData = useSelector(
    (state) => state.tele_symptoms_list_response
  );
  const bannersListData = useSelector((state) => state.tele_banners_response);

  // FOR AUTO LOGIN CLAIMS SITE

  const NitroPackage = packagesData?.data?.active_package;

  useEffect(() => {
    if (V2HREF && V2HREF !== null && NutritionID && NutritionID !== null) {
      if (packagesData?.status === "loading") {
        setShowModal(true);
      } else if (
        NutritionID &&
        NutritionID !== null &&
        V2HREF &&
        packagesData &&
        NitroPackage?.length === 0
      ) {
        navigate("/book-teleconsultation");
      } else {
        setShowModal(false);
      }
    }
  }, [V2HREF, packagesData?.status, showModal]);

  useEffect(() => {
    dispatch(fetchBannerList());
  }, [dispatch]);

  useEffect(() => {
    if (packageData) {
      dispatch(fetchDepartmentsList());
      dispatch(fetchSymptomsList());
    }
  }, [dispatch, packageData]);

  const handleBooking = (data) => {
    console.log(data);
    if (data == "/specialist-teleconsultation") {
      navigate(data);
    } else {
      console.log(data);
      navigate(`/book-teleconsultation`, { state: data });
    }
  };

  useEffect(() => {
    // Find the matching department for banner 1 (id: 1)
    if (bannersListData && departmentListData) {
      // Find the matching department for banner 2 based on general_physician_id
      const banner2Department =
        departmentListData?.data?.data?.department?.find(
          (dep) =>
            dep.id == bannersListData?.data?.banners[1]?.general_physician_id
        );
      setBannerData2(banner2Department);
    }
  }, [bannersListData, departmentListData]);

  return (
    <>
      <div className="home-review-banner mb-4">
        <div className="home-app-header d-flex justify-content-start align-items-center sticky-top">
          <div className="tele-data-wrapper">
            <div className="header-logo tele-logo mr-3">
              <img
                src={headerLogo}
                className="header-medibhai-logo"
                width="100%"
                alt="header-logo"
              />
            </div>
            <div
              className="home-header-teleconsultation d-flex justify-content-between align-items-center"
              style={{ gap: "75px" }}
            >
              <div className="header-navigation-icon head-repo-bor">
                <h6 className="mb-0">Teleconsultation</h6>
              </div>
              <CommonPackage />
            </div>
          </div>
        </div>
        {showModal && <LoaderAnime showModal={showModal} />}
        {departmentListData?.loader ? (
          <WaitLoader />
        ) : (
          <>
            <GeneralPhysicianComponent
              handleBooking={handleBooking}
              teleconsultationData={departmentListData}
            />
            <div className="home-teleconsultation-main-wrapper mt-4 px-2">
              <div className="specialist-doctor-home-wrapper">
                <div className="specialist-header d-flex justify-content-between align-items-center">
                  <h6 className="mb-0">Doctor By Specialist</h6>
                  <NavLink
                    className="view-all-specialist"
                    to="/specialist-teleconsultation"
                  >
                    <button>
                      View All{" "}
                      <span>
                        <TbChevronRight />
                      </span>
                    </button>
                  </NavLink>
                </div>
                <div className="specialist-product-main-wrapper mt-3 px-1">
                  <div className="specialist-product-box d-flex justify-content-between">
                    {departmentListData?.data?.data?.department?.length > 0 ? (
                      departmentListData?.data?.data?.department
                        .slice(0, 4)
                        .map((specialityData) => {
                          return (
                            <SpecialityProduct
                              data={specialityData}
                              handleBooking={handleBooking}
                            />
                          );
                        })
                    ) : (
                      <NoDataFoundComp />
                    )}
                  </div>
                </div>
              </div>
              <div className="home-teleconsultation-banner-wrapper">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1.2}
                  loop="true"
                  //   autoplay={{ delay: 1000, disableoninteraction: false }}
                  speed={2000}
                  effect="slide"
                  grabCursor={true}
                  slidesOffsetBefore={0}
                  slidesOffsetAfter={0}
                  //   modules={[Autoplay]}
                >
                  <div className="swiper-wrapper">
                    {bannersListData?.data?.banners?.map(
                      (tele_banners, ind) => {
                        return (
                          <SwiperSlide
                            className="applabtest-repo"
                            key={tele_banners.id}
                          >
                            <div
                              className="teleconsultation-banner-box"
                              onClick={() =>
                                handleBooking(
                                  ind == 1
                                    ? bannerData2
                                    : "/specialist-teleconsultation"
                                )
                              }
                            >
                              <img
                                src={tele_banners.image_url}
                                alt="Teleconsultation Banner"
                              />
                            </div>
                          </SwiperSlide>
                        );
                      }
                    )}
                  </div>
                </Swiper>
              </div>
            </div>
            <div className="symptoms-main-wrapper-cover px-3 mt-3">
              <div className="symptoms-header mb-2">
                <h6>Symptoms</h6>
              </div>
              <div className="symptoms-product-main-wrapper d-flex justify-content-between">
                <div className="specialist-product-box d-flex justify-content-between">
                  {symptomsListData?.data?.data?.symptoms?.length > 0 ? (
                    symptomsListData?.data?.data?.symptoms
                      .slice(0, 3)
                      .map((symptomsData) => {
                        return (
                          <SymptomsCard
                            data={symptomsData}
                            handleBooking={handleBooking}
                          />
                        );
                      })
                  ) : (
                    <NoDataFoundComp />
                  )}
                </div>
              </div>
              <div className="symptoms-footer d-flex justify-content-center mt-3">
                <NavLink
                  className="view-all-specialist"
                  to="/symptoms-teleconsultation"
                >
                  <button className="symptoms-view-button">
                    View All{" "}
                    <span>
                      <TbChevronRight />
                    </span>
                  </button>
                </NavLink>
              </div>
              <How_it_works />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HomeTeleConsultation;
