import { React, useEffect, useState, forwardRef, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Apploader from "../../Medicine/animation/prescription_loader";
import {
  fetchSymptomsList,
  fetchPackageList,
  fetchDepartmentsList,
} from "../../../Redux/actions/GetApiCall";
import {
  getDepartmentTimeSlot,
  bookTeleconsult,
} from "../../../Redux/actions/PostApiCall";
import { validateNumberInput } from "../../../utils/AllFunction";
import { Toaster, toast } from "react-hot-toast";
import { validateTextInput } from "../../../utils/AllFunction";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import { InputGroup } from "react-bootstrap";
import { format } from "date-fns";
import { FaCalendarAlt } from "react-icons/fa";
import useLocationState from "../../Medicine/hooks/useLocationState";
import { PiUploadBold } from "react-icons/pi";
import { FaRegCircleXmark } from "react-icons/fa6";
import LoaderAnime from "../../Medicine/animation/prescription_loader";
import { fetchFamilyData } from "../../../Redux/actions/FamilySlice";
import { IoMdAdd } from "react-icons/io";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="date-picker-input" onClick={onClick} ref={ref}>
    <input
      type="text"
      readOnly
      value={value}
      placeholder="Select a Booking Date"
      className="booking-date"
    />
    <FaCalendarAlt className="calendar-icon" />
  </div>
));

const BookTeleConsultation = () => {
  const navigate = useNavigate();
  const [BookingDate, setBookingDate] = useState(null);
  const dispatch = useDispatch();
  const ClaimPackId = useLocationState();
  const BookedTeleConsultDataRepo = useLocationState();
  const [modalShow, setModalShow] = useState(false);
  const packagesData = useSelector((state) => state.package_list);
  const { data, loading, error } = useSelector((state) => state?.family);
  const [userData, setUserData] = useState("");
  const department_time_slot_response = useSelector(
    (state) => state.department_time_slot_response
  );
  const storedPackageData = JSON.parse(localStorage.getItem("PakageData"));
  const V2HREF = localStorage.getItem("V2HREF");
  const NutritionID = localStorage.getItem("NutritionID");
  const profileDetails = packagesData?.data?.profile_details;
  const previousDepartmentId = useRef(null);
  const bookTeleconsult_response = useSelector(
    (state) => state.bookTeleconsult_response
  );

  const departmentListData = useSelector(
    (state) => state.tele_department_list_response.data
  );

  const NutroData = departmentListData?.data;
  const mappedNutritionID = NutritionID == 10 ? "67" : NutritionID; //for claim engine broker site nutrition

  // Convert the mapped NutritionID to a number
  const nutritionIdAsNumber =
    mappedNutritionID && mappedNutritionID !== "null"
      ? Number(mappedNutritionID)
      : null;

  const FindNutroData = NutroData?.department?.find(
    (item) => item.id === nutritionIdAsNumber
  );

  const BookedTeleConsultData = FindNutroData || BookedTeleConsultDataRepo;

  const {
    handleSubmit,
    control,
    register,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      age: userData?.age || "",
      age_type: userData?.age_type || "",
      gender: userData?.gender || "",
      relation: userData?.relation || "",
      department_id:
        BookedTeleConsultData?.type == "symptoms"
          ? ""
          : BookedTeleConsultData?.id,
      // Add default values for other fields as needed
    },
  });

  const teleformData = watch();

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 6);

  const formatDate = (date) => {
    if (!date) return "";
    const formattedDate = format(date, "dd-MM-yyyy");
    return formattedDate;
  };

  const [files, setFiles] = useState({
    Document: [],
  });

  const docTypes = [
    {
      Document: "Document",
    },
  ];

  const documentTypes = Object.keys(files);

  const handleFileChange = async (e, documentData) => {
    const newFiles = Array.from(e.target.files);
    const validFileTypes = ["image/jpeg", "image/png", "application/pdf"];

    // Check for valid file types
    const isValid = newFiles.every((file) =>
      validFileTypes.includes(file.type)
    );
    if (!isValid) {
      toast.error(
        "Error: Invalid file type! Please upload JPEG, PNG, or PDF files."
      );
      e.target.value = null; // Clear the input if file type is invalid
      return; // Exit the function to prevent further processing
    }

    // Filter image files for size validation
    const imageFiles = newFiles.filter((file) =>
      file.type.startsWith("image/")
    );

    // Validate image dimensions
    const checkPromises = imageFiles.map((file) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
          if (img.width < 300 || img.height < 400) {
            toast.error(
              `Image size must be at least 300x400 pixels. Invalid file: ${file.name}`
            );
            reject(file); // Reject invalid images
          } else {
            resolve(file); // Resolve valid images
          }
        };
        img.src = URL.createObjectURL(file);
      });
    });

    try {
      // Wait for all images to be checked for dimensions
      const validFiles = await Promise.all(checkPromises);

      // Proceed with adding valid files to state
      setFiles((prevFiles) => ({
        ...prevFiles,
        [documentData]: [
          ...prevFiles[documentData],
          ...validFiles,
          ...newFiles.filter((file) => !file.type.startsWith("image/")),
        ],
      }));
    } catch (error) {
      // At least one image failed validation
      console.error("One or more files were invalid");
    }
  };

  const handleFileDelete = (e, documentType, file, index) => {
    e.preventDefault();
    const updatedDoc = files[documentType].filter(
      (myfile) => myfile.name !== file.name
    );
    setFiles((prevFiles) => ({
      ...prevFiles,
      [documentType]: updatedDoc,
    }));
  };

  const handlePreview = handleSubmit((data) => {
    // Logic to handle preview with the submitted data
    const formData = new FormData();
    formData.append("patient_name", data.patient_name);
    formData.append("email", data.email);
    formData.append("contact", data.mobile);
    formData.append("appointment_date", data.appointment_date);
    formData.append("appointment_time", data?.appointment_time);
    formData.append("age", userData?.age || data.age);
    formData.append(
      "relation",
      userData?.relation.toLowerCase() || data.relation
    );
    formData.append("age_type", userData?.age_type || data?.age_type);
    formData.append("remark", data.remark);
    formData.append("gender", userData?.gender.toLowerCase() || data.gender);
    formData.append("department_id", data?.department_id);
    formData.append(
      "package_id",
      storedPackageData?.id
        ? storedPackageData?.id
        : ClaimPackId?.pack_id
        ? ClaimPackId?.pack_id
        : ""
    );
    // formData.append("package_id", 1);

    files?.Document.forEach((file, index) => {
      formData.append(`upload_reports[]`, file);
    });

    if (BookedTeleConsultData?.type == "symptoms") {
      formData.append("symptom_id", BookedTeleConsultData?.id);
    }
    dispatch(bookTeleconsult(formData));
  });

  useEffect(() => {
    const formData = new FormData();
    // Check if department_id has changed to prevent redundant API calls
    if (BookingDate !== null) {
      if (
        teleformData?.department_id &&
        teleformData?.department_id !== previousDepartmentId.current
      ) {
        if (BookedTeleConsultData?.type === "symptoms") {
          formData.append("department_id", teleformData.department_id);
          formData.append("date", formatDate(BookingDate));
          setValue("appointment_date", formatDate(BookingDate));
          setValue("appointment_time", null);
          // dispatch(getDepartmentTimeSlot(formData));
        } else if (BookedTeleConsultData?.type !== "symptoms") {
          formData.append("department_id", BookedTeleConsultData?.id);
          formData.append("date", formatDate(BookingDate));
          setValue("appointment_date", formatDate(BookingDate));
          setValue("appointment_time", null);
          // dispatch(getDepartmentTimeSlot(formData));
        }

        previousDepartmentId.current = teleformData?.department_id;
      }
    }
  }, [teleformData?.department_id, BookingDate]);

  useEffect(() => {
    dispatch(
      fetchSymptomsList(storedPackageData?.id ? storedPackageData?.id : "")
    );
    dispatch(fetchPackageList());
    dispatch(fetchFamilyData());
  }, []);

  useEffect(() => {
    if (storedPackageData?.id && V2HREF && V2HREF !== null) {
      dispatch(fetchDepartmentsList(storedPackageData?.id));
    } else {
      dispatch(fetchDepartmentsList());
    }
  }, []);

  useEffect(() => {
    if (profileDetails) {
      reset({
        // patient_name: profileDetails.name || "",
        email: profileDetails.email_id || "",
        mobile: profileDetails.mobile_number || "",
        appointment_date: formatDate(BookingDate) || "",
      });
    }
  }, [profileDetails, reset]);

  useEffect(() => {
    if (bookTeleconsult_response?.status === "success") {
      if (bookTeleconsult_response?.data?.data?.consultation?.id) {
        navigate("/order-consultation", {
          state: {
            order_id: bookTeleconsult_response?.data?.data?.consultation?.id,
            pack_id: ClaimPackId?.pack_id,
          },
        });
      }
    } else if (bookTeleconsult_response?.status === "failure") {
      toast.error(bookTeleconsult_response?.error);
    }
  }, [bookTeleconsult_response]);

  useEffect(() => {
    if (department_time_slot_response?.status === "loading") {
      // Show modal when status is 'loading'
      setModalShow(true);
    } else if (department_time_slot_response?.status === "success") {
      if (
        teleformData?.appointment_date !== "" &&
        teleformData.department_id &&
        department_time_slot_response?.data?.data?.time_slots?.length === 0
      ) {
        toast.error("No time slots available");
      }
      // Hide modal when status is 'success'
      setModalShow(false);
    }
  }, [department_time_slot_response]);

  useEffect(() => {
    if (BookingDate) {
      if (teleformData.department_id) {
        const formData = new FormData();
        formData.append("department_id", teleformData.department_id);
        formData.append("date", formatDate(BookingDate));
        setValue("appointment_date", formatDate(BookingDate));
        setValue("appointment_time", null);
        dispatch(getDepartmentTimeSlot(formData));
      }
    }
  }, [BookingDate]);

  const patientName = watch("patient_name");

  useEffect(() => {
    if (data?.data && patientName) {
      const user = data?.data?.family_member?.find(
        (item) => item?.name === patientName
      );
      console.log(user);
      setUserData(user);
    }
  }, [patientName]);

  const handleAdd = () => {
    navigate("/family-member");
  };

  return (
    <>
      <div className="home-review-banner mb-4">
        {modalShow && <LoaderAnime showModal={modalShow} />}
        <div className="home-app-header d-flex justify-content-start align-items-center sticky-top">
          <div className="home-header-teleconsultation d-flex align-items-center">
            <div className="teleconsult-icon-box">
              <img
                src={`${BookedTeleConsultData?.file_name}`}
                width="100%"
                alt="Cart-icon"
              />
            </div>
            <div className="header-navigation-icon">
              <h6 className="mb-0">{BookedTeleConsultData?.name}</h6>
            </div>
          </div>
        </div>
        <Apploader showModal={bookTeleconsult_response?.loader} />
        <div className="Booking-teleconsult-main-wrapper">
          <p className="book-teleconsult-inner-heading">
            Teleconsultation will be conducted for this person
          </p>
          <div className="book-now-form-main-cover">
            <div className="book-now-form">
              <h6 className="tele-form-heading">Enter Patient Details</h6>
              <form>
                <div className="cover-plus-cover-book" style={{alignItems:'center'}}>
                  <div
                    className="mb-2 time-slot-input-box"
                    style={{ width: "85%" }}
                  >
                    <label className="tele-labels">Patient Name</label>
                    <Controller
                      name="patient_name"
                      control={control}
                      rules={{ required: "Please select a patient name" }}
                      render={({ field }) => (
                        <select
                          {...field}
                          value={field.value || ""} // Forces empty string if value is undefined/null
                          onChange={(e) => field.onChange(e.target.value)} // Updates field value on change
                        >
                          <option value="">Select Patient Name</option>
                          {data?.data?.family_member?.map((item) => (
                            <option key={item?.id} value={item?.name}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    <span className="text-danger validation-error">
                      {errors.patient_name && errors.patient_name.message}
                    </span>
                  </div>

                  <div className="repo-plus-cover-close" onClick={handleAdd} style={{marginTop:'15px'}}>
                    <IoMdAdd />
                  </div>
                </div>
                <div className="age-ageType-group">
                  <div className="age-input-wrapper">
                    <label className="tele-labels">Age</label>
                    <input
                      type="text"
                      maxLength={3}
                      value={userData?.age || ""}
                      placeholder="Age"
                      disabled
                      // {...register("age", {
                      //   required: "Please enter patient age",
                      //   pattern: {
                      //     value: /^\d{1,3}$/,
                      //     message: "Please enter a valid age",
                      //   },
                      //   validate: (value) =>
                      //     value <= 101 || "Age cannot be more than 101",
                      // })}
                      // onInput={(e) => validateNumberInput(e)}
                    />
                    {/* <span className="text-danger validation-error">
                      {errors.age && errors.age.message}
                    </span> */}
                  </div>
                  <div className="ageType-input-wrapper">
                    <label className="tele-labels">Age Type</label>
                    <input
                      type="text"
                      value={userData?.age_type}
                      placeholder="Age Type (e.g., Days, Months, Years)"
                      disabled
                      // {...register("age_type", {
                      //   required: "Age type is required",
                      // })}
                    />
                    {/* <span className="text-danger validation-error">
                      {errors.age_type && errors.age_type.message}
                    </span> */}
                  </div>
                </div>
                <div className="gender-relation-group">
                  <div className="gender-input-wrapper">
                    <label className="tele-labels">Gender</label>
                    <input
                      type="text"
                      value={userData?.gender}
                      placeholder="Gender (e.g., Male, Female, Other)"
                      disabled
                      // {...register("gender", {
                      //   required: "Gender is required",
                      // })}
                    />
                    {/* <span className="text-danger validation-error">
                      {errors.gender && errors.gender.message}
                    </span> */}
                  </div>
                  <div className="relation-input-wrapper">
                    <label className="tele-labels">Relation</label>
                    <input
                      type="text"
                      value={userData?.relation}
                      placeholder="Relation (e.g., Self, Spouse, Child)"
                      disabled
                      // {...register("relation", {
                      //   required: "Relation is required",
                      // })}
                    />
                    {/* <span className="text-danger validation-error">
                      {errors.relation && errors.relation.message}
                    </span> */}
                  </div>
                </div>

                {BookedTeleConsultData?.type == "symptoms" && (
                  <Form.Group
                    className="mb-3 book-now-input-box"
                    controlId="exampleForm.ControlSelect1"
                  >
                    <label className="tele-labels">Select Department </label>
                    <Form.Select
                      {...register("department_id", {
                        required: "Please select department id",
                      })}
                      className="department_type_select"
                    >
                      <option value="">Select department</option>
                      {BookedTeleConsultData?.department &&
                        Object.entries(BookedTeleConsultData?.department).map(
                          ([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          )
                        )}
                    </Form.Select>
                    <span className="text-danger validation-error">
                      {errors.department_id && errors.department_id.message}
                    </span>
                  </Form.Group>
                )}

                <label className="tele-labels">Set Appointment Date</label>
                <div className="mb-3 date-prod-data-box">
                  <DatePicker
                    name="start_date"
                    selected={BookingDate}
                    {...register("start_date", {
                      required: "Please select an appointment date",
                    })}
                    onChange={(date) => {
                      setBookingDate(date);
                      setValue("start_date", date, { shouldValidate: true }); // Manually set value with validation
                      clearErrors("start_date"); // Clear any existing errors
                    }}
                    placeholderText="Select a Booking Date"
                    showYearDropdown={false}
                    showMonthDropdown={false}
                    autoComplete="off"
                    minDate={new Date()}
                    maxDate={maxDate}
                    dateFormat="dd-MM-yyyy"
                    customInput={<CustomInput />}
                  />
                  <span className="text-danger validation-error">
                    {errors.start_date && errors.start_date.message}
                  </span>
                </div>
                {teleformData?.appointment_date !== "" &&
                  teleformData.department_id &&
                  department_time_slot_response?.data?.data?.time_slots
                    ?.length > 0 && (
                    <div className="time-slot-input-box mb-3">
                      <label htmlFor="selectOption" className="tele-labels">
                        Select Appointment Time
                      </label>
                      <select
                        id="selectOption"
                        // required
                        {...register("appointment_time", {
                          required: "Please select appoinment time",
                        })}
                      >
                        <option value="">Select time slot</option>
                        {department_time_slot_response?.data?.data?.time_slots
                          ?.length > 0 &&
                          department_time_slot_response?.data?.data?.time_slots.map(
                            (timeslot) => (
                              <option key={timeslot} value={timeslot}>
                                {timeslot}
                              </option>
                            )
                          )}
                      </select>
                      <span className="text-danger validation-error">
                        {errors.appointment_time &&
                          errors.appointment_time.message}
                      </span>
                      {department_time_slot_response?.data?.data?.time_slots &&
                        department_time_slot_response?.data?.data?.time_slots
                          ?.length === 0 && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "13px",
                              marginLeft: "3px",
                            }}
                          >
                            No time slots available
                          </p>
                        )}
                    </div>
                  )}

                {BookedTeleConsultData?.type == "symptoms" && (
                  <input
                    type="hidden"
                    {...register("symptom_id")}
                    value={BookedTeleConsultData?.id}
                  ></input>
                )}
                {BookedTeleConsultData?.type != "symptoms" && (
                  <input
                    type="hidden"
                    {...register("department_id")}
                    value={BookedTeleConsultData?.id}
                  ></input>
                )}
                <div className="upload-form-data">
                  <label>
                    Upload <span>(Upload JPG,PNG,PDF File format)</span>
                  </label>
                  <div style={{ fontSize: "10px", marginBottom: "7px" }}>
                    <span style={{ color: "red" }}>
                      Note: Image size atleast 300*400 pixels
                    </span>
                  </div>
                  <div className="file-data-box-repo">
                    {documentTypes.map((documentData, index) => (
                      <div className="upload-document-boxlist-cover">
                        <div
                          className="upload-document-list-header"
                          key={index}
                        >
                          <div className="upload-data-box-repo-site tele-data-text">
                            <h6>{docTypes[0][documentData]}</h6>
                            <div className="upload-document-btn-box tele-form-data">
                              <button>
                                <span>
                                  <PiUploadBold />
                                </span>
                                <input
                                  type="file"
                                  name="claim_upload_file[]"
                                  onChange={(e) =>
                                    handleFileChange(e, documentData)
                                  }
                                  style={{
                                    opacity: 0,
                                    width: 100,
                                    position: "absolute",
                                    left: "-8px",
                                    top: 3,
                                  }}
                                  multiple
                                />
                                <p>Upload</p>
                              </button>
                            </div>
                          </div>

                          <div
                            className={
                              `${files[documentData]?.length > 2}`
                                ? "uploaded-documents-list-box height-style"
                                : "uploaded-documents-list-box"
                            }
                          >
                            {files[documentData].length > 0 ? (
                              files[documentData].map((file, index) => (
                                <div
                                  className="uploaded-documents-list"
                                  key={index}
                                >
                                  <h6>{file.name}</h6>
                                  <button
                                    onClick={(e) =>
                                      handleFileDelete(
                                        e,
                                        documentData,
                                        file,
                                        index
                                      )
                                    }
                                  >
                                    <FaRegCircleXmark />
                                  </button>
                                </div>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <Form.Group
                  className="mb-3 book-now-input-box"
                  controlId="exampleForm.ControlInput1"
                >
                  <label className="tele-labels">
                    Describe your symptoms{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    as="textarea"
                    placeholder="Describe your symptoms..."
                    rows={4}
                    cols={50}
                    {...register("remark", {
                      required: "This field is required",
                    })}
                  />
                  {errors.remark && (
                    <span className="text-danger">{errors.remark.message}</span>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 book-now-input-box"
                  controlId="exampleForm.ControlInput1"
                >
                  <label className="tele-labels">Phone Number</label>
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    {...register("mobile", {
                      required: "Please enter patient phone number",
                    })}
                    disabled={
                      profileDetails?.mobile_number !== "" &&
                      profileDetails?.mobile_number !== null &&
                      profileDetails?.mobile_number !== undefined
                    }
                    onInput={(e) => validateNumberInput(e)}
                    maxLength={10}
                  />
                  <span className="text-danger validation-error">
                    {errors.mobile && errors.mobile.message}
                  </span>
                </Form.Group>
                <Form.Group
                  className="mb-3 book-now-input-box"
                  controlId="exampleForm.ControlInput1"
                >
                  <label className="tele-labels">Email Address</label>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    disabled={
                      profileDetails?.email_id !== "" &&
                      profileDetails?.email_id !== null &&
                      profileDetails?.email_id !== undefined
                    }
                    {...register("email", {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Please enter a valid email address",
                      },
                    })}
                  />
                  <span className="text-danger validation-error">
                    {errors.email && errors.email.message}
                  </span>
                </Form.Group>
                <p className="wallet_summary-point"></p>
                <div className="submit-btn-box d-flex justify-content-center">
                  {/* <button type="submit" disabled={submitLoader}>{submitLoader ? "Submitting" : "Submit"}</button> */}
                  {/* <button type="submit">{"Submit"}</button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "custom-toast position-middle-center",
            duration: 2000,
            style: {
              background: "#fff",
              color: "#363636",
              fontSize: "14px",
              width: "300px",
              top: "55px !important",
            },
          }}
        />
        {/* <div className="middle_toaster">
          </div> */}
        <div className="go-to-cart-main-page-wrapper">
          <div className="cart-count-box-cover">
            <div className="cart-count-text">
              {BookedTeleConsultData?.price == 0 ? (
                <div className="free-card">
                  <h5>Free</h5>
                </div>
              ) : (
                <p>
                  Total
                  <span className="teleconsult-price">
                    ₹ {BookedTeleConsultData?.price}
                  </span>
                </p>
              )}
            </div>
            <div className="go-cart-btn">
              <button
                className="patient-btn"
                disabled={
                  department_time_slot_response?.data?.data?.time_slots
                    ?.length === 0
                }
                onClick={() => handlePreview()}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookTeleConsultation;
