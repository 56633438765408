import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import SuccessFull from "../../../Animation/SuccessFull";
import ErrorFull from "../../../Animation/ErrorFull";
import { GET_MEDICINE_PINCODE_API } from "../../../Constants";
import toast, { Toaster } from "react-hot-toast";
import { input_Error, input_Success } from "../Images";
import LoaderAnime from "../animation/loader_anime";
import { useDispatch, useSelector } from "react-redux";
import { fetchPackageList } from "../../../Redux/actions/GetApiCall";

const Medicine_Picode_Modal = (props) => {

  const [medicinePincode, setMedicinePincode] = useState("");
  const [pincode, setPincode] = useState("");
  const token = localStorage.getItem("ACCESS_TOKEN");
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const navigate = useNavigate();
  const [inputLoader, setInputLoader] = useState(false)
  const [isSuccess, setIsSuccess] = useState(null);
  const V2HREF = localStorage.getItem("V2HREF")
  const [selectedPackage, setSelectedPackage] = useState(null);
  const Package = JSON.parse(localStorage.getItem("PakageData"));
  const packagesData = useSelector((state) => state.package_list);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPackageList());
  }, [])

  const handleSearch = async () => {
    setInputLoader(true)
    try {
      const pincodeData = await fetch(`${GET_MEDICINE_PINCODE_API}${pincode}`, {
        headers: {
          Authorization: `${token}`,
        },
        method: "GET",
      });

      // if (!pincodeData.ok) {
      //   const errorData = await pincodeData.json().catch(() => null);
      //   console.error("Error details:", errorData);
      //   throw new Error("Network response was not ok");
      // }

      const CityData = await pincodeData.json();
      setMedicinePincode(CityData)

      if (CityData.success === true) {
        setIsSuccess(true);
        setSuccessMessage(CityData.message);
      } else {
        setIsSuccess(false);
        setSuccessMessage(CityData.message);
      }
    } catch (error) {
      setIsSuccess(false);
      console.error("Error fetching home data:", error);
    } finally {
      setInputLoader(false)
    }
  };

  const handleSubmit = () => {
    props.onHide();

    localStorage.setItem("cityData", medicinePincode?.city_name);
    if (medicinePincode.success === true) {
      localStorage.setItem("pincode", pincode);
      localStorage.setItem("pincodeData", JSON.stringify(medicinePincode, pincode));
      if (selectedPackage === null) {
        const PackData = { id: "", name: "Select Package" };
        localStorage.setItem("PakageData", JSON.stringify(PackData));
      }
      else if (selectedPackage) {
        const PackData={ id: selectedPackage?.id, name: selectedPackage?.name }
        localStorage.setItem("PakageData", JSON.stringify(PackData));
      }
    }

    setPincode('');
    setSuccessMessage('')
    setIsSuccess(null)
    window.location.reload();
    // navigate("/buymedicines");
  }

  useEffect(() => {
    if (pincode.length === 6) {
      handleSearch();
    }
  }, [pincode]);


  const handleClose = () => {
    const pincodeData = localStorage.getItem("pincodeData");
    if (V2HREF && V2HREF !== null) {
      window.location.href = V2HREF
    }
    else if (!pincodeData) {
      navigate('/home')
    }
  };

  const handlePackageChange = (e) => {
    const packageValue = e.target.value ? JSON.parse(e.target.value) : null;
    setSelectedPackage(packageValue);
  };


  return (
    <>
      <Modal {...props} centered backdrop="static" keyboard={false}>
        {inputLoader ? (
          <div style={{ width: '100%', height: '100%', zIndex: 99999 }}>
            <LoaderAnime />
          </div>
        ) : (
          <>
            <Modal.Header closeButton onClick={handleClose}>
              <Modal.Title id="contained-modal-title-vcenter" className="modal-heading-text">
                Set Delivery Location
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="input-box-content">
                <input
                  type="text"
                  maxLength={6}
                  placeholder="Eg. 401107"
                  value={pincode}
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                    setPincode(sanitizedValue);
                  }}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  style={{
                    borderColor: isSuccess === null ? 'initial' : isSuccess ? 'green' : 'red',
                    outline: isSuccess === null ? 'initial' : isSuccess ? '1px solid green' : '1px solid red',
                    paddingRight: '30px' // add padding to the right to make space for the icon
                  }}
                />
                {isSuccess !== null && (
                  <div className="input-image-data">
                    <img
                      src={isSuccess ? input_Success : input_Error}
                      alt=""
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)'
                      }}
                    />
                  </div>
                )}
                <p style={{ color: isSuccess === null ? '' : isSuccess ? 'green' : 'red', marginTop: '3px' }}>{successMessage}</p>

                {Package?.id === "" && (
                  <div className="select-box-repo-coer">
                    <h6>Select Package Name</h6>
                    {packagesData?.data?.active_package?.length > 0 && (
                      <div className="packages-cover-wrapper-bk">
                        <div className="options-wrapper-lok">
                          <select
                            value={selectedPackage ? JSON.stringify(selectedPackage) : ""}
                            onChange={handlePackageChange}
                          >
                            <option value="" disabled selected>Select Package</option>
                            {packagesData?.data?.active_package?.map((item) => (
                              <option key={item.id} value={JSON.stringify(item)}>
                                {item.name}
                              </option>
                            ))}
                          </select>

                        </div>
                      </div>
                    )}
                  </div>
                )}

                <button onClick={() => {
                  if (isSuccess === true) {
                    handleSubmit();
                  }
                }} disabled={pincode.length !== 6 || isSuccess !== true}>Submit</button>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>

      {successModal && (
        <SuccessFull show={modalShow} successMessage={successMessage} animationtype={successModal} />
      )}
      {errorModal && (
        <ErrorFull show={modalShow} successMessage={successMessage} />
      )}

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "custom-toast",
          duration: 3000,
          style: {
            background: "#fff",
            color: "#363636",
            fontSize: "14px",
            width: "300px",
            top: "55px !important",
          },
        }}
      />
    </>
  );
};

export default Medicine_Picode_Modal;
