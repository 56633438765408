export const getToken = () => {
  return localStorage.getItem("ACCESS_TOKEN");
};

export const removeToken = () => {
  localStorage.removeItem("ACCESS_TOKEN");
};

export const getAuthHeader = () => {
  const AppToken = localStorage.getItem("APP_TOKEN");
  const Package = JSON.parse(localStorage.getItem("PakageData"));

  return {
    "Content-Type": "application/json",
    Authorization: AppToken,
    PackageId: Package?.id,
  };
};
export const getFormAuthHeader = () => {
  const AppToken = localStorage.getItem("APP_TOKEN");
  const Package = JSON.parse(localStorage.getItem("PakageData"));

  return {
    Authorization: AppToken,
    PackageId: Package?.id,
  };
};
