import React, { useEffect, useState } from 'react';
import useLocationState from '../Medicine/hooks/useLocationState';
import { headerLogo } from '../../images';
import { hospitalIcon, hospitalIconbhai } from '../Medicine/Images';
import { FiSearch } from 'react-icons/fi';
import StarRatings from 'react-star-ratings';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHospitalList } from '../../Redux/actions/GetApiCall';

const HospitalCard = () => {
    const Data = useLocationState();
    const hospitalData = useSelector(state => state.hospital_list)
    const hospitalloader = useSelector(state => state.hospital_list.loader)
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchHospitalList())
    }, [])

    // Extract unique departments
    // Exclude hospitals with empty speciality_type
    const validHospitals = hospitalData?.data?.data?.filter(hospital => hospital.speciality_type && hospital.speciality_type.trim() !== '');

    // Create a list of unique departments
    const uniqueDepartments = Array.from(
        new Set(validHospitals?.flatMap(hospital => hospital.speciality_type))
    );

    // Filter hospitals based on search term and selected category
    const filteredHospitalBySearch = validHospitals?.filter(hospital => {
        const matchesSearchTerm =
            hospital.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            hospital.city.toLowerCase().includes(searchTerm.toLowerCase());

        const matchesDepartment =
            selectedCategory === 'All' ||
            hospital.speciality_type.includes(selectedCategory);

        return matchesSearchTerm && matchesDepartment;
    });



    const handleHospitalDetails = (item) => {
        navigate('/hospital-details', { state: item })
    }
    const handleSearch = () => {
        navigate("/search-hospital")
    }

    return (
        <div className="home-review-banner">
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Hospitals</p>
                    </div>
                </div>
            </div>

            {/* Search bar */}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search hospital..."
                    onClick={handleSearch}
                    onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s]*$/;
                        if (!regex.test(e.key)) {
                            e.preventDefault();
                        }
                    }}
                />
                <FiSearch />
            </div>

            <div className="hospital-cover-repo-box" style={{ padding: '8px', paddingRight: '0px' }}>
                {filteredHospitalBySearch && filteredHospitalBySearch.length > 0 ? (
                    filteredHospitalBySearch.map((item, index) => (
                        <div className="hospital-card-repo" key={index} onClick={() => handleHospitalDetails(item)}>
                            <div className="hospital-card-image-loki" style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '10px' }}>
                                <img src={item?.icon ? `${item?.icon}` : hospitalIconbhai}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = hospitalIconbhai;
                                    }} alt="hospital-image" />
                                {item?.name && (
                                    <p>
                                        <span>{item?.name}</span>
                                    </p>
                                )}
                            </div>
                            <div className="hospital-data-wrap" style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: '2px' }}>
                                {item?.city && (
                                    <p style={{ color: 'black', fontSize: '12px' }}>
                                        City: <span>{item?.city}</span>
                                    </p>
                                )}
                                {item?.doctors?.length > 0 && (
                                    <p style={{ color: 'black', fontSize: '12px' }}>
                                        Doctors: <span>{item?.doctors.length}</span>
                                    </p>
                                )}
                            </div>

                            <div className="hospital-data-wrap-loki" style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                {item?.speciality_type && (
                                    <p style={{ color: 'black', fontSize: '12px', marginBottom: '0px' }}>
                                        Speciality: <span>{item?.speciality_type}</span>
                                    </p>
                                )}
                                {item?.number_of_beds && (
                                    <p style={{ color: 'black', fontSize: '12px', marginBottom: '0px' }}>
                                        No.of beds: <span>{item?.number_of_beds}</span>
                                    </p>
                                )}
                            </div>

                            {/* <div className="ratings-hospital">
                                                      <p><FaStar /> <span>{item?.ratings}</span></p>
                                                  </div> */}
                        </div>
                    ))
                ) : (
                    <div style={{ textAlign: 'center', color: 'GrayText', marginTop: '20px' }} className='repo-text-wrapper-cover-not'>
                        <p>No hospital found</p>
                    </div>
                )}
            </div>

        </div>
    );
};

export default HospitalCard;
