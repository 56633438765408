import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { appAmbulanceImgage, headerLogo } from '../../images';
import { FaLocationDot } from "react-icons/fa6";
import '../../Home/styles/homeStyle.css';
import GoogleLocation from '../GoogleLocation/GoogleLocation';
import { validateNumberInput } from '../../utils/AllFunction';
import { useDispatch, useSelector } from 'react-redux';
import { ambulanceService } from '../../Redux/actions/PostApiCall';
import LoaderAnime from "../Medicine/animation/prescription_loader";
import { useNavigate } from 'react-router';
import Medicine_Success from '../Medicine/animation/Medicine_Success';

const AmbulanceAssistance = () => {
  const [selectedLocation, setSelectedLocation] = useState('1');
  const [successmodal, setSuccessModal] = useState(false);
  const { location, error, getCurrentLocation } = GoogleLocation();
  const [showModal, setShowModal] = useState(false)
  const { register, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const naviagte=useNavigate();
  const { data, loading, error: ambulanceError } = useSelector(state => state.ambulance_service)
  console.log(data?.success)

  const [locationCurrent, setLocationCurrent] = useState(null);
  const [errorCurrent, setErrorCurrent] = useState(null);

  // Fetch the user's current location
  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocationCurrent({ latitude, longitude });
        },
        (err) => setErrorCurrent(`Failed to get location: ${err.message}`),
        { enableHighAccuracy: true }
      );
    } else {
      setErrorCurrent('Geolocation is not supported by your browser.');
    }
  };

  useEffect(() => {
    if (selectedLocation === "1") {
      fetchCurrentLocation();
      if (location) {
        setValue("address1", location?.shortAddress || "");
        setValue("pincode", location?.pincode || "");
        setValue("state", location?.state || "");
        setValue("city", location?.city || "");
      }
    } else {
      reset({
        address1: "",
        address2: "",
        landmark: "",
        pincode: "",
        state: "",
        city: "",
        patient_name: "",
        mobile_number: ""
      });
    }
  }, [selectedLocation, location, reset, setValue]);

  const handleRadioChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const onSubmit = (data) => {
    setShowModal(true)
    const body = {
      form_type: "ambulance",
      patient_name: data.patient_name || "",
      mobile_number: data.mobile_number || "",
      lat: locationCurrent.latitude || "",
      lng: locationCurrent.longitude || "",
      location_type: selectedLocation,
      address: data.address1 || "",
      address_1: data.address1 || "",
      address_2: data.address2 || "",
      city: data.city || "",
      pincode: data.pincode || "",
      state: data.state || "",
      patient_age: "",
      gender: "",
    };
    const formData = new FormData();
    Object.keys(body).forEach((key) => formData.append(key, body[key]));
    dispatch(ambulanceService(formData))
  };

  useEffect(() => {
    if (data?.success === true) {
      setShowModal(false)
      setSuccessModal(true)

      setTimeout(() => {
        setSuccessModal(false)
        naviagte("/home")
      }, 2500);
    }
  }, [data])

  const iframeSrc = locationCurrent
    ? `https://www.google.com/maps/embed/v1/place?key=AIzaSyBz8qUh5g91GyggiZf6eNqOC6jKMErllxA&q=${locationCurrent.latitude},${locationCurrent.longitude}`
    : null;

  return (
    <div className="home-review-banner">
      <div className="home-app-header nutrition-repo-box sticky-top">
        <div className="header-logo">
          <img src={headerLogo} width="100%" alt="header-logo" />
          <div className="user-name">
            <p>Ambulance Service</p>
          </div>
        </div>
      </div>
      {showModal && <LoaderAnime showModal={showModal} />}
      <div className="ambulance-cover-section">
        <div className="ambulance-header-image">
          <img src={appAmbulanceImgage} width="100%" alt="review" />
          <p><FaLocationDot /> Select location for Pick Up</p>
        </div>
      </div>

      {/* Radio Buttons */}
      <div className="custom-radio-group">
        <label className="custom-radio-container">
          <input
            type="radio"
            name="custom-radio"
            value="1"
            defaultChecked
            onChange={handleRadioChange}
          />
          <span className="custom-radio-checkmark"></span>
          <p>Current Location</p>
        </label>
        <label className="custom-radio-container">
          <input
            type="radio"
            name="custom-radio"
            value="2"
            onChange={handleRadioChange}
          />
          <span className="custom-radio-checkmark"></span>
          <p>Alternate Location</p>
        </label>
      </div>

      {selectedLocation === "1" && (
        <div style={{ marginTop: '10px' }} className='iframe-map-container unique-map-container'>
          {locationCurrent ? (
            <iframe
              title="Google Map"
              className="unique-map-iframe-new"
              src={iframeSrc}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
            ></iframe>
          ) : (
            <p>{errorCurrent || 'Loading map...'}</p>
          )}
          {errorCurrent && <p style={{ color: 'red' }}>Error: {errorCurrent}</p>}
        </div>
      )}

      {/* Form */}
      <form onSubmit={handleSubmit(onSubmit)} className="ambulance-form-box">
        <label>Address Line 1</label>
        <input
          type="text"
          {...register("address1", { required: "Address Line 1 is required" })}
          placeholder="Enter Your Address Line 1"
        />
        {errors.address1 && <p className="error-new">{errors.address1.message}</p>}
        <label>Address Line 2 (Optional)</label>
        <input
          type="text"
          {...register("address2")}
          placeholder="Enter Your Address Line 2"
        />
        <label>Landmark (Optional)</label>
        <input
          type="text"
          {...register("landmark")}
          placeholder="Enter Landmark"
        />
        <label>Pincode</label>
        <input
          type="text"
          {...register("pincode", {
            required: "Pincode is required",
            pattern: {
              value: /^[0-9]{6}$/,
              message: "Pincode must be a 6-digit number",
            },
          })}
          placeholder="Enter Pincode"
          maxLength={6}
          onInput={validateNumberInput}
        />
        {errors.pincode && <p className="error-new">{errors.pincode.message}</p>}
        <label>State</label>
        <input
          type="text"
          {...register("state", { required: "State is required" })}
          placeholder="Enter State"
        />
        {errors.state && <p className="error-new">{errors.state.message}</p>}

        {/* City */}
        <label>City</label>
        <input
          type="text"
          {...register("city", { required: "City is required" })}
          placeholder="Enter City"
        />
        {errors.city && <p className="error-new">{errors.city.message}</p>}
        <label>Patient Name</label>
        <input
          type="text"
          {...register("patient_name", { required: "Patient Name required" })}
          placeholder="Enter patient name"
        />
        {errors.patient_name && <p className="error-new">{errors.patient_name.message}</p>}
        <label>Mobile Number</label>
        <input
          type="text"
          {...register("mobile_number", { required: 'Mobile Number required' })}
          placeholder="Enter mobile number"
          onInput={validateNumberInput}
          maxLength={10}
        />
        {errors.mobile_number && <p className="error-new">{errors.mobile_number.message}</p>}
        <button type="submit" id="submitButton">
          Submit
        </button>
      </form>
      {data?.success && <Medicine_Success showModal={successmodal} message={data?.message} status={data?.success} />}
    </div>
  );
};

export default AmbulanceAssistance;
