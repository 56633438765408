import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useLocationState from '../Medicine/hooks/useLocationState';
import { fetchDentalOrderDetail, fetchPackageList } from '../../Redux/actions/GetApiCall';
import { DoctorEyeImage, medi_wallet_image, packAnime_icon, savings_image } from '../Medicine/Images';
import { headerLogo } from '../../images';
import MediLoader from '../Medicine/animation/MediLoader';
import { dentalCapturePayment, dentalUtilization } from '../../Redux/actions/PostApiCall';
import LoaderAnime from '../Medicine/animation/prescription_loader'
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import ToastBox from '../Medicine/animation/ToastBox';
import useRazorpay from 'react-razorpay';

const EyeDentalSummary = () => {
    const dispatch = useDispatch()
    const Data = useLocationState();
    const DentalFormRepo = useSelector((state) => state?.dental_order_detail);
    const DentalData = DentalFormRepo?.data?.data
    const DentalPayment = DentalFormRepo?.data
    const [selectedData, setSelectedData] = useState('');
    const packagesData = useSelector(state => state.package_list);
    const DentalAmountUtilization = useSelector(state => state.dental_utilization)
    const DentalCapturePayment = useSelector(state => state.dental_capture_payment)
    const [walletId, setWalletId] = useState("");
    const [modalShow, setModalShow] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const [isPackageSelected, setIsPackageSelected] = useState(false);
    const navigate = useNavigate();
    const [Razorpay] = useRazorpay();
    const Package = JSON.parse(localStorage.getItem("PakageData"));

    const handleConsultOrder = async () => {
        setModalShow(true)
        const data = {
            booked_id: Data,
            package_id: selectedData ? selectedData?.id : "",
            wallet_id: walletId ? walletId : ""
        }
        await dispatch(dentalUtilization(data))
    }

    const handleWalletChange = (e) => {
        setModalShow(true)
        const isCheckedNow = e.target.checked;
        setIsChecked(isCheckedNow);

        if (isCheckedNow && selectedData) {
            dispatch(fetchDentalOrderDetail(Data, selectedData.wallet_id)); // Call API with selected package ID and wallet ID
            setWalletId(selectedData.wallet_id);
        } else {
            dispatch(fetchDentalOrderDetail(Data)); // Call API without wallet ID
            setWalletId('');
        }
    };

    const handleSelectPackage = (e) => {
        setModalShow(true)
        const selectedValue = e.target.value;
        if (selectedValue) {
            try {
                const selectedItem = JSON.parse(selectedValue);
                setSelectedData(selectedItem);
                setIsPackageSelected(true)
                dispatch(fetchDentalOrderDetail(Data));
            } catch (error) {
                console.error('Invalid JSON input:', selectedValue);
            }
        } else {
            setSelectedData('');
            setIsPackageSelected(false)
        }
    };

    useEffect(() => {
        if (DentalFormRepo?.loader === false) {
            setModalShow(false)
        }
    }, [DentalFormRepo])

    useEffect(() => {
        dispatch(fetchPackageList());
        if (Data) {
            dispatch(fetchDentalOrderDetail(Data))
        }
    }, [dispatch])


    useEffect(() => {
        if (DentalAmountUtilization?.status === "success" && DentalAmountUtilization?.data?.is_payable === 0) {
            setModalShow(false);
            const data = {
                orderId: Data,
                tabKey: "Six",
            };
            setTimeout(() => {
                navigate("/thank-you", { state: data });
            }, 100);
        }
    }, [DentalAmountUtilization])


    useEffect(() => {
        if (DentalAmountUtilization?.loader) {
            setModalShow(true);
        } else if (DentalAmountUtilization?.status === "failure") {
            setModalShow(false);
            toast.error(DentalAmountUtilization?.error);
        }
        else if (
            DentalAmountUtilization?.data?.razorpay_details &&
            DentalAmountUtilization?.status === "success" &&
            DentalAmountUtilization?.data?.is_payable === 1
        ) {
            const options = {
                ...DentalAmountUtilization.data.razorpay_details,
                order_id:
                    DentalAmountUtilization.data.razorpay_details?.razorpay_order_id,
                handler: function async(response) {
                    dispatch(
                        dentalCapturePayment({
                            transaction_id: response.razorpay_payment_id,
                            booked_id: Data,
                            package_id: selectedData ? selectedData?.id : "",
                            wallet_id: walletId ? walletId : ""
                        })
                    );
                },
                modal: {
                    ondismiss: function () {
                        setModalShow(false);
                        toast.error("Payment was cancelled.");
                    },
                },
            };
            const rzp1 = new Razorpay(options);
            rzp1.open();
        }
    }, [DentalAmountUtilization]);

    useEffect(() => {
        if (DentalCapturePayment?.status === "success") {
            setModalShow(false);
            const data = {
                orderId: Data,
                tabKey: "Six",
            };
            setTimeout(() => {
                navigate("/thank-you", { state: data });
            }, 100);
        }
    }, [DentalCapturePayment]);

    const repoprice = DentalFormRepo?.data?.payment_summary
        .filter(
            (lab_cal_Data) =>
                lab_cal_Data.key === "Total Payable"
        )?.[0]?.value

    useEffect(() => {
        if (packagesData?.data) {
            const data = packagesData?.data?.active_package.find(item => item?.id === Package?.id);
            setSelectedData(data)
        }
    }, [packagesData])


    const height = "75vh"
    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Patient Details</p>
                    </div>
                </div>
            </div>

            {modalShow && <LoaderAnime showModal={modalShow} />}

            {DentalFormRepo?.loader && !isPackageSelected && !isChecked ? (
                <MediLoader height={height} />
            ) : (
                <>
                    <div className="card-wrapper-bankai-rpeo mt-3" style={{ border: 'none' }}>
                        <div className="image-repo-doc-banaki" style={{ boxShadow: 'none' }}>
                            <img src={DoctorEyeImage} alt="" />
                        </div>
                        <div className="content-repo-doc-ank-ai">
                            <h6>{DentalData?.doctor?.name}</h6>
                            <p>{DentalData?.doctor?.designation}</p>
                            <div className="repo-proicce-bankai">
                                <div className="crop-pricerepo">

                                    <span>Consultation Fees: ₹ {Math.round(DentalData?.hospital?.medibhai_price)}</span>
                                    {/* <p>₹{Math.round(DentalData?.hospital?.mrp)}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="repo-card-bankai-one">
                        <span className='repo-span-ban-cvd'>{DentalData?.hospital?.name}</span>
                        <p>Address: <span>{DentalData?.hospital?.address}{DentalData?.hospital?.district},{DentalData?.hospital?.city}-{DentalData?.hospital?.pincode}</span></p>
                    </div>

                    <div className="consultation-preview-card-wrapper mb-4">
                        <div className="consultation-card">
                            <h6 className="consultation-card-title" style={{ borderBottom: '1px solid #c7c8c9', paddingBottom: '6px' }}>Patient Details</h6>
                            <p className="consultation-patient-name">
                                Patient Name :{" "}
                                <span>
                                    {
                                        DentalData
                                            ?.patient_name
                                    }
                                </span>
                            </p>
                            <div className="row">
                                <div className="col-6">
                                    <div className="preview-card-detail">
                                        <p>
                                            Age :{" "}
                                            <span>
                                                {DentalData?.age}{" "}
                                                {DentalData?.age_type}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="preview-card-detail">
                                        <p>
                                            Gender :{" "}
                                            <span>
                                                {
                                                    DentalData
                                                        ?.gender
                                                }
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="preview-card-detail">
                                        <p>
                                            Relation :{" "}
                                            <span>
                                                {
                                                    DentalData
                                                        ?.relation
                                                }
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <hr className="consult-review"></hr>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="preview-card-detail">
                                        <h6>Appointment Date</h6>
                                        <p>
                                            {
                                                DentalData
                                                    ?.appointment_date
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="preview-card-detail">
                                        <h6>Appointment Time</h6>
                                        <p>
                                            {
                                                DentalData
                                                    ?.appointment_time
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {Package?.id !== "" && (
                            packagesData?.data?.active_package?.length > 0 && (
                                <div className="packages-cover-wrapper mt-4">
                                    <div className="options-wrapper">
                                        <img src={packAnime_icon} alt="" />
                                        <select value={selectedData ? JSON.stringify(selectedData) : ''} disabled>
                                            <option value="">{Package?.name}</option> {/* Default selected and disabled */}
                                        </select>

                                    </div>
                                </div>
                            )
                        )}

                        {selectedData !== "" && selectedData !== undefined && (
                            <>
                                <div className="wallet-repo-wrapper">
                                    <div className="check-data-wrapper">
                                        <div className="checkbox-wrapper">
                                            <input
                                                id="terms-checkbox-37"
                                                name="checkbox"
                                                type="checkbox"
                                                checked={isChecked} // Bind to the checkbox state
                                                onChange={handleWalletChange} // Handle change event directly
                                            />
                                            <label className="terms-label" htmlFor="terms-checkbox-37">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                                                    <mask fill="white" id="path-1-inside-1_476_5-37">
                                                        <rect height="200" width="200"></rect>
                                                    </mask>
                                                    <rect mask="url(#path-1-inside-1_476_5-37)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                                                    <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                                                </svg>
                                            </label>
                                        </div>
                                        <div className="wrapper-text-content-box">
                                            <p>Medibhai Wallet</p>
                                            <span>Remaining Balance : ₹ {selectedData?.wallet_details?.dental?.remaining}</span>
                                        </div>
                                    </div>
                                    <div className="used-data-wrapper">
                                        <img src={medi_wallet_image} alt="" />
                                    </div>

                                </div>
                                {isChecked && repoprice > 0 && modalShow === false && (
                                    <div className="wallet-heading-insu" style={{ background: 'lightgreen' }}>
                                        <p>
                                            Your wallet balance is insufficient ₹{repoprice}  payment is required online
                                        </p>
                                    </div>
                                )}
                            </>
                        )}



                        <div className="payment-repo-cover-box mx-2">
                            <div className="payment-text-repo">
                                <p>Payment Summary</p>
                            </div>
                            <div className="payment-summary-box-repo">
                                {DentalPayment?.payment_summary &&
                                    DentalPayment?.payment_summary
                                        .filter(
                                            (lab_cal_Data) =>
                                                lab_cal_Data.key !== "Total Savings"
                                        )
                                        .map((filteredData) => (
                                            <div
                                                className="price-payment-sum-repo"
                                                key={filteredData.key}
                                            >
                                                <p
                                                    style={{ marginBottom: '8px' }}
                                                >{filteredData.key}</p>
                                                <p
                                                    style={{ marginBottom: '8px' }}
                                                >
                                                    {new Intl.NumberFormat("en-IN", {
                                                        style: "currency",
                                                        currency: "INR",
                                                        minimumFractionDigits: 0,
                                                    }).format(filteredData.value)}
                                                </p>
                                            </div>
                                        ))}
                            </div>
                            <div className="fin-text-repo">
                                {DentalPayment?.payment_summary &&
                                    DentalPayment?.payment_summary
                                        .filter(
                                            (lab_cal_Data) =>
                                                lab_cal_Data.key === "Total Savings"
                                        )
                                        .map((filteredData) => (
                                            <div
                                                className={`price-payment-sum-repo ${filteredData.key === "Total Savings"
                                                    ? "text-success price-payment-sum-repo saving-data-p"
                                                    : ""
                                                    }`}
                                                key={filteredData.key}

                                            >
                                                <p
                                                    style={{ marginBottom: '8px' }}
                                                >
                                                    <img
                                                        src={savings_image}
                                                        alt=""
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    {filteredData.key}
                                                </p>
                                                <p
                                                    style={{ marginBottom: '8px' }}
                                                >
                                                    {new Intl.NumberFormat("en-IN", {
                                                        style: "currency",
                                                        currency: "INR",
                                                        minimumFractionDigits: 0,
                                                    }).format(filteredData.value)}
                                                </p>
                                            </div>
                                        ))}
                            </div>
                        </div>
                    </div>

                    <div className="footer-repo-head-boxs sticky-lg-bottom sticky-md-bottom sticky-bottom pb-2" style={{ justifyContent: "center" }}>
                        {/* <div className="product-price-repo-store">
                            {DentalData?.order_details
                                ?.net_amount <= 0.0 ? (
                                <div class="free-card">
                                    <h5>₹ {DentalData?.order_details?.net_amount}</h5>
                                </div>
                            ) : (
                            <p>
                                Total{" "}
                                <span>
                                    ₹{" "}
                                    {
                                        DentalFormRepo?.data?.payment_summary?.find(item => item.key === "Total Payable")?.value
                                    }
                                </span>
                            </p>

                            )}
                        </div> */}
                        <div className="product-btn-box-store-repo">
                            <button
                                className="repo-cont-btn"
                                onClick={handleConsultOrder}
                            >
                                BOOK APPOINTMENT
                            </button>
                        </div>
                    </div>
                </>
            )

            }
            <ToastBox />

        </div>
    )
}

export default EyeDentalSummary