import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDoctorList } from '../../Redux/actions/GetApiCall';
import useLocationState from '../Medicine/hooks/useLocationState';
import DoctorCard from './DoctorCard';
import { FiSearch } from "react-icons/fi";
import { headerLogo } from '../../images';
import './doctorStyle.css';
import MediLoader from '../Medicine/animation/MediLoader';
import useInfiniteScroll from '../../utils/useInfiniteScroll';
import { useNavigate } from 'react-router';

const Doctor = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const DoctorId = useLocationState();
  const [pageNo, setPageNo] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [doctorData, setDoctorData] = useState([]);
  const navigate=useNavigate()

  // Fetch doctor list from Redux store
  const DoctorListData = useSelector(state => state.doctor_list);
  const DoctorListLoader = useSelector(state => state.doctor_list.loader);

  // ✅ Fetch doctors on component mount & when page changes
  useEffect(() => {
    dispatch(fetchDoctorList(DoctorId, searchTerm, pageNo));
  }, [dispatch, DoctorId, searchTerm, pageNo]);

  // ✅ Update doctor list when new data arrives
  useEffect(() => {
    if (DoctorListData?.data?.data) {
      setDoctorData(prev => (pageNo === 1 ? DoctorListData.data.data : [...prev, ...DoctorListData.data.data]));
      setHasMore(pageNo < DoctorListData?.data?.total_pages);
    }
  }, [DoctorListData, pageNo]);

  // ✅ Use infinite scroll to load more doctors
  const containerRef = useInfiniteScroll(() => {
    if (hasMore) {
      setPageNo(prev => prev + 1);
    }
  }, hasMore, DoctorListLoader);

  const handleSearch = () => {
    navigate("/search-doctor")
  }

  return (
    <div className='home-review-banner'>
      {/* Header */}
      <div className="home-app-header sticky-top">
        <div className="header-logo">
          <img src={headerLogo} width="100%" alt="header-logo" />
          <div className="user-name-text-repo">
            <p style={{ fontSize: '16px', marginLeft: '10px' }}>Doctor</p>
          </div>
        </div>
      </div>

      {/* Search bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search doctor..."
          value={searchTerm}
          onClick={handleSearch}
          onKeyPress={(e) => {
            const regex = /^[a-zA-Z\s]*$/; // Allow only letters and spaces
            if (!regex.test(e.key)) {
              e.preventDefault(); // Prevent invalid input
            }
          }}
        />
        <FiSearch />
      </div>

      {/* Doctor List */}
      {DoctorListLoader && pageNo === 1 ? (
        <MediLoader height="75vh" />
      ) : (
        <div className="doctor-grid" ref={containerRef} style={{ height: '680px', overflowY: 'auto' }}>
          {doctorData.length === 0 ? (
            <div className="no-doctor-found" style={{ height: '60vh', display: 'flex', alignItems: 'center' }}>
              <p style={{ fontSize: '18px', fontWeight: '500' }}>No Doctor Found</p>
            </div>
          ) : (
            doctorData.map((doctor, index) => (
              <DoctorCard key={index} doctor={doctor} />
            ))
          )}
          {DoctorListLoader && pageNo > 1 && <MediLoader height="150px" />}
        </div>
      )}
    </div>
  );
};

export default Doctor;
