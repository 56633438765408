import { React, useEffect, useState, useContext } from "react";
import {
  ArrowBack,
  appAvatarDefault,
  headerLogo,
} from "../../images";
import { NavLink, useNavigate } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import Loader from "../../Animation/Loader";
import { AppContext } from "../../ContextApi";
import ConfirmModal from "./Components/ConfirmModal";
import { fetchPackageList } from "../../Redux/actions/GetApiCall";
import { useDispatch, useSelector } from "react-redux";
import NavigationList from "./Components/ProfileList";

const Profile = (props) => {

  const { handleClose, formShow, handleShow } = useContext(AppContext);
  const userData = JSON.parse(localStorage.getItem("UserData"))
  const navigate = useNavigate()

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    const newToken = localStorage.getItem("APP_TOKEN");;
    if (!newToken) {
      navigate("/");
    }
  };


  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header-new sticky-top">
          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name-text-repo">
              <p> Menu</p>
            </div>
          </div>
        </div>

        <div className="user-main-cover">
          <div className="user-cover-banner">
            <div className="user-banner-cont-box">
              <div className="user-banner-conts-box">
                <div className="user-image-section">
                  <img src={appAvatarDefault} width="100%" alt="Avatar" />
                </div>
                <div className="user-detail-box-sec">
                  <h4>{userData.name}</h4>
                  <p>{userData.mobile_number}</p>
                  <p>{userData.email_id}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="navigation-list-cover-wrapper">
            <NavigationList handleShow={handleShow} />
          </div>
        </div>
      </div>
      <ConfirmModal handleClose={handleClose} formShow={formShow} onSubmit={handleLogout} heading={"Are you sure you want to logout ?"} />
    </>
  );
};

export default Profile;
