import React, { useEffect, useState } from "react";
import { headerLogo } from "../../../images";
import { useDispatch, useSelector } from "react-redux";
import { fetchPackageList } from "../../../Redux/actions/GetApiCall";
import { packAnime_icon } from "../../Medicine/Images";
import { fetchFamilyData } from "../../../Redux/actions/FamilySlice";
import { Accordion } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import usePackageData from "../../../utils/usePackageData";
import FamilyRepo from "./FamilyRepo";
import { fetchFamilyFormData, resetFamilyFormState } from "../../../Redux/actions/FamilyFormSlice";
import LoaderAnime from '../../../Pages/Medicine/animation/prescription_loader'
import toast from "react-hot-toast";
import ToastBox from '../../Medicine/animation/ToastBox'
import { FaCircleCheck } from "react-icons/fa6";

const FamilyMember = () => {
    const dispatch = useDispatch();
    const [selectedData, setSelectedData] = useState(null);
    const packagesData = useSelector((state) => state?.package_list);
    const { data, loading, error } = useSelector((state) => state?.family);
    const { data: formData, loading: formLoading, error: formError } = useSelector((state) => state?.familyform);
    const Package = JSON.parse(localStorage.getItem("PakageData"));
    const [packageData] = usePackageData();
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        dispatch(fetchPackageList());
    }, [dispatch]);

    useEffect(() => {
        if (packageData) {
            dispatch(fetchFamilyData());
        }
    }, [packageData, dispatch]);

    const handleSelectPackage = (e) => {
        setShowModal(true)
        const selectedValue = e.target.value;
        if (selectedValue === "") {
            const PackData = { id: "", name: "Select Package" };
            localStorage.setItem("PakageData", JSON.stringify(PackData));
            setSelectedData(null);
            return;
        }

        try {
            const selectedItem = JSON.parse(selectedValue);
            const PackData = { id: selectedItem?.id, name: selectedItem?.name };
            localStorage.setItem("PakageData", JSON.stringify(PackData));
            setSelectedData(selectedItem);
        } catch (error) {
            console.error("Invalid JSON input:", selectedValue);
        }
    };

    const handleFormSubmission = (data) => {
        setShowModal(true); // Show modal before API calls

        const formData = new FormData();
        formData.append("relation", data.relation);
        formData.append("name", data.name);
        formData.append("gender", data.gender);
        formData.append("date_of_birth", data.date_of_birth);
        formData.append("assign_package_id", Package?.id);

        dispatch(fetchFamilyFormData({ formData }))
            .then((result) => {
                dispatch(resetFamilyFormState())
                return dispatch(fetchFamilyData());
            })
            .then(() => {
                setShowModal(false); // Hide modal after both calls succeed
            })
            .catch((error) => {
                console.error("Error in form submission:", error);
                setShowModal(false); // Hide modal on error
            });
    };

    useEffect(() => {
        if (formData?.success === true) {
            setShowModal(false)
            toast.success(formData?.message)
        }
        else if (formData?.success === false) {
            setShowModal(false)
            toast.error(formData?.message)
        }
    }, [formData])
    useEffect(() => {
        if (data?.success === true) {
            setShowModal(false)
        }
        else if (data?.success === false) {
            setShowModal(false)
        }
    }, [data])

    return (
        <div className="home-review-banner">
            {showModal && <LoaderAnime showModal={showModal} />}
            <div className="home-app-header">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name">
                        <p>Family Member</p>
                    </div>
                </div>
            </div>

            {packagesData?.data?.active_package?.length > 0 && (
                <div className="packages-cover-wrapper">
                    <div className="options-wrapper">
                        <img src={packAnime_icon} alt="" />
                        <select onChange={handleSelectPackage}>
                            <option value="">
                                {Package?.id !== "" ? Package?.name : "Select Package"}
                            </option>
                            {packagesData?.data?.active_package?.map((item) => (
                                <option key={item.id} value={JSON.stringify(item)}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}

            <div className="family-button-repo">
                <Accordion>
                    {data?.data?.all_relation?.map((item, index) => (
                        <Accordion.Item eventKey={item?.name} key={index}>
                            <Accordion.Header>
                                {item?.name}

                                {item?.data !== null && (
                                    <FaCircleCheck />
                                )}
                            </Accordion.Header>
                            <Accordion.Body>
                                {item?.data !== null ? (
                                    <div className="form-page" style={{ width: '94%', margin: '0 auto' }}>
                                        <input type="text" value={item?.data?.name} disabled placeholder='Enter Patient Name' />
                                        <div className="con-family-repo-clone">
                                            <div className="gender-select-family">
                                                <select value={item?.data?.gender} disabled>
                                                    <option value={item?.data?.gender}>{item?.data?.gender}</option>
                                                </select>
                                            </div>
                                            <div className="gender-select-family bro-class">
                                                <DatePicker value={item?.data?.date_of_birth} disabled />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <FamilyRepo
                                        item={item}
                                        index={index}
                                        onSubmit={handleFormSubmission}
                                    />
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
            <ToastBox />
        </div>
    );
};

export default FamilyMember;