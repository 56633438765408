import React, { useEffect, useState } from 'react'
import { headerLogo } from '../../../images'
import '../Medicine_Style/eye.css'
import { DentalBanner, DentalBanner2, DentalBanner3, DentalBanner4, DentalBanner5, DentalBannerImage1, DentalBannerImage2, DentalOtherBanner, EyeBanner, EyeImage, EyeImage2, EyeImage3 } from '../Images'
import { useNavigate } from 'react-router'
import useLocationState from '../hooks/useLocationState'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDentalPartners } from '../../../Redux/actions/GetApiCall'
import MediLoader from '../animation/MediLoader'
import { fetchvendor } from '../../../Redux/actions/PostApiCall'
import LoaderAnime from '../animation/prescription_loader'
import { fetchVendorData, resetVendorState } from '../../../Redux/actions/VendorSlice'
import CommonPackage from '../../MyProfile/Components/CommonPackage'

const Dental = () => {
    const naviagte = useNavigate();
    const PackData = useLocationState();
    const [modalShow, setModalShow] = useState(false);
    const dispatch = useDispatch();
    const { data, loader } = useSelector(state => state.vendor_api)
    const { data: DenatlData, loader: DentalLoader } = useSelector(state => state.dental_partners)
    const { data: PackageData, loader: PackLoader, error } = useSelector(state => state.vendorpackage)

    useEffect(() => {
        const formData = new FormData();
        formData.append("type", "ocean_dental")
        dispatch(fetchDentalPartners())
        dispatch(fetchvendor(formData))
    }, [])

    const handleEyeForm = (item) => {
        naviagte("/dental-detail", { state: item })
    }

    const handleOtherPartner = (id) => {
        console.log("hii")
        naviagte('/doctor', { state: id })
    }

    const handleVendorPack = async (id) => {
        setModalShow(true);

        const postData = {
            type: "ocean_dental",
            package_id: "",
            vendor_id: id?.id,
            assign_package_id: "",
        };

        try {
            await dispatch(fetchVendorData({ postData }));
            setModalShow(false);
        } catch (error) {
            console.error("Error fetching vendor package:", error);
            setModalShow(false); // Ensure modal is closed even if API fails
        }
    };

    useEffect(() => {
        if (PackageData) {
            if (PackageData?.data?.package_list.length > 0) {
                naviagte("/diabeties-detail?dental=1", { state: PackageData })
            }
            else {
                const OceanData = {
                    data: PackageData?.data,
                    title: "Diabeties Care",
                    Type: "ocean_dental"
                }
                naviagte("/ocean-form", { state: OceanData })
            }
        }
        dispatch(resetVendorState())
    }, [PackageData])

    const height = "70vh"
    return (
        <div className='home-review-banner'>
            {modalShow && <LoaderAnime showModal={modalShow} />}
            <div className="home-app-header sticky-top">
                <div className="tele-data-wrapper">
                    <div className="header-logo tele-logo mr-3">
                        <img
                            src={headerLogo}
                            className="header-medibhai-logo"
                            width="100%"
                            alt="header-logo"
                        />
                    </div>
                    <h6 className="mb-0">Dental</h6>
                </div>
                <div className="home-header-teleconsultation d-flex justify-content-end align-items-center" style={{ gap: '75px' }}>
                    <div className="header-navigation-icon head-repo-bor">
                        <CommonPackage />
                    </div>
                </div>
            </div>

            {DentalLoader ? (
                <MediLoader height={height} />
            ) : (
                <>
                    <div className="banner">
                        <Swiper
                            spaceBetween={15}
                            slidesPerView={1}
                            loop={true}
                            centeredSlides={true}
                            autoplay={{ delay: 2000, disableOnInteraction: false }}
                            modules={[Autoplay]}
                        >
                            {data?.banners?.top_banner?.map((item, index) => (
                                <SwiperSlide key={index} className="bannersliderimg">
                                    <div className="denatl-box">
                                        <img
                                            src={item?.banner}
                                            width="100%"
                                            alt="app-banner-img"
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div class="partners-section">
                        <div className="part-repo-text-dot">
                            <div className="dot-line"></div>
                            <h3>Our Partners</h3>
                            <div className="dot-line"></div>
                        </div>
                        <div class="partners-logos">
                            {DenatlData?.data?.partners?.map((item) => (
                                <div class="partner-card-dental" onClick={() => handleEyeForm(item.partner_id)}>
                                    <img src={item?.icon} alt={item.name} />
                                    <p>{item?.partner_name}</p>
                                </div>
                            ))}
                        </div>
                        {data?.data?.length > 0 && (
                            <>

                                <div className="part-repo-text-dot mt-3 mb-1">
                                    <div className="dot-line" style={{ width: '50px' }}></div>
                                    <h3 style={{ fontSize: '15px' }}>Specialized Package For You</h3>
                                    <div className="dot-line" style={{ width: '50px' }}></div>
                                </div>
                                <div className="repo-bottom-banner-repo">
                                    <Swiper
                                        spaceBetween={15}
                                        slidesPerView={1}
                                        loop={true}
                                        centeredSlides={true}
                                        autoplay={{ delay: 2000, disableOnInteraction: false }}
                                        modules={[Autoplay]}
                                    >
                                        {data?.data?.map((item, index) => (
                                            <SwiperSlide key={index} className="bannersliderimg">
                                                <div className="cover-boxes-partner-solo" style={{ width: '97%' }} onClick={() => handleVendorPack(item)}>
                                                    <div className="solo-image-box">
                                                        <img src={item?.logo} alt="" />
                                                    </div>
                                                    <div className="solo-content-repo" style={{ textAlign: 'start' }}>
                                                        <h6>{item?.name}</h6>
                                                        <p className='ellipsis-text-eye' style={{ fontSize: '12px', fontWeight: '400' }}>{item?.description}</p>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </>
                        )}

                        <div className="other-partner-repo-clover" onClick={() => handleOtherPartner(data?.banners?.bottom_banner?.[0]?.id)}>
                            <img src={data?.banners?.bottom_banner?.[0]?.bottom_banner} alt="" />
                        </div>
                    </div>
                </>
            )}




        </div>
    )
}

export default Dental