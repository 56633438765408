import { useForm } from "react-hook-form";
import { genderList } from "../../../data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FamilyRepo = ({ item, index, onSubmit }) => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        clearErrors,
        formState: { errors }
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit"
    });

    const handleFormSubmit = (data) => {
        console.log(`Submitting form for ${item.name || index}:`, data);
        const formattedDate = new Date(data.date_of_birth)
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-");
        onSubmit({
            relation: item.value, // Changed to item.name; use item.value if that's correct
            name: data.name,
            gender: data.gender,
            date_of_birth: formattedDate // Use the formatted date
        });
    };

    const handleSubmitError = (errors) => {
        console.log(`Validation errors for ${item.name || index}:`, errors);
    };

    return (
        <form name={`family_${item.name || index}`} onSubmit={handleSubmit(handleFormSubmit, handleSubmitError)}>
            <div className="form-page" style={{ width: '94%', margin: '0 auto' }}>
                <input
                    type="text"
                    placeholder="Enter Patient Name"
                    {...register(`name`, {
                        required: "Patient name is required"
                    })}
                />
                {errors.name && (
                    <span className="error-text-mssg">
                        {errors.name.message}
                    </span>
                )}

                <div className="con-family-repo-clone">
                    <div className="gender-select-family">
                        <select
                            {...register(`gender`, {
                                required: "Gender is required"
                            })}
                        >
                            <option value="">Select Gender</option>
                            {genderList.map((data, idx) => (
                                <option key={idx} value={data.text}>
                                    {data.text}
                                </option>
                            ))}
                        </select>
                        {errors.gender && (
                            <span className="error-text-mssg" style={{ top: '-10px' }}>
                                {errors.gender.message}
                            </span>
                        )}
                    </div>

                    <div className="gender-select-family bro-class">
                        <DatePicker
                            selected={watch(`date_of_birth`)}
                            onChange={(date) => {
                                setValue(`date_of_birth`, date, { shouldValidate: true });
                                clearErrors(`date_of_birth`);
                            }}
                            placeholderText="Select a DOB"
                            autoComplete="off"
                            maxDate={new Date()}
                            dateFormat="dd-MM-yyyy"
                        />
                        {errors.date_of_birth && (
                            <span className="error-text-mssg" style={{ top: '-10px' }}>
                                {errors.date_of_birth?.message}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div className="submit-btn-wrap" style={{ marginBottom: '15px' }}>
                <button type="submit">Submit</button>
            </div>
        </form>
    );
};

export default FamilyRepo