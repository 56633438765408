import React, { useEffect, useState, useRef } from 'react';
import '../../style/shipment.css'
import { useDispatch, useSelector } from 'react-redux';
import useLocationState from '../../../Medicine/hooks/useLocationState';
import { fetchTrackOrder } from '../../../../Redux/actions/GetApiCall';
import Shipment_Card from './shipment_Card';
import Need_Help from '../../../Medicine/Components/Need_Help';
import CancelOrderModal from './CancelOrderModal';
import toast from 'react-hot-toast';
import { ReorderApi } from '../../../../Redux/actions/PostApiCall';
import { NavLink, useNavigate } from 'react-router-dom';
import LoaderAnime from '../../../Medicine/animation/prescription_loader';
import ToastBox from '../../../Medicine/animation/ToastBox';
import PaymentSummaryDetails from './PaymentSummaryDetails';
import { refund_image } from '../../../Medicine/Images';
import useRazorpay from 'react-razorpay';
import MediLoader from '../../../Medicine/animation/MediLoader';
import { IoHome } from 'react-icons/io5';

const ShipmentOrder = () => {
    const dispatch = useDispatch();
    const data = useLocationState();
    console.log(data)
    const TrackData = useSelector(state => state.track_order);
    const reorderData = useSelector(state => state.reorder_medicine)
    const TrackSummaryData = TrackData?.data?.data?.[0]
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const AppToken = localStorage.getItem("APP_TOKEN");
    const [Razorpay] = useRazorpay();

    // Ref for the refund-box-process div
    const refundBoxRef = useRef(null);

    const handleShow = () => setShow(true);

    useEffect(() => {
        if (data?.medicine_order_id !== undefined && data?.onemg_order_id !== undefined) {
            dispatch(fetchTrackOrder(data?.medicine_order_id, data?.onemg_order_id))
        }
    }, []);

    const handleReorder = async (item) => {
        setShowModal(true); // Show the modal initially

        const data = {
            medicine_order_id: item
        };

        try {
            await dispatch(ReorderApi(data));

            setShowModal(false);

        } catch (error) {
            toast.error('An error occurred while processing the reorder.');
        } finally {
            setShowModal(false)
        }
    };

    useEffect(() => {
        if (reorderData?.data?.success === true) {
            setTimeout(() => {
                const tabkeyUrl = "medicines";
                const tabkeyData = { tabkey: tabkeyUrl };
                navigate('/cart', { state: tabkeyData });
            }, 300); // Adjust the delay if necessary

        } else if (reorderData?.data?.success === false) {
            toast.error(reorderData?.data?.message);
        }
    }, [reorderData])

    // Scroll handler to scroll to refund-box-process
    const handleScrollToRefundBox = () => {
        if (refundBoxRef.current) {
            refundBoxRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleRepayment = () => {
        const optionsData = TrackSummaryData?.razorpay_details;

        const options = {
            ...optionsData,
            handler: async (response) => {
                console.log("Payment successful", response);
                if (response) {
                    const data = {
                        medicine_order_id: TrackSummaryData?.medicine_order_id,
                        transaction_id: response?.razorpay_payment_id
                    }
                    setShowModal(true)
                    try {
                        const cartUpdateData = await fetch(`${process.env.REACT_APP_NEW_APP_API_URL}/medicine/capture-payment-details`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: AppToken,
                            },
                            body: JSON.stringify(data),
                        });


                        const CartpaymentData = await cartUpdateData.json();
                        if (CartpaymentData.success === true) {
                            setTimeout(() => {
                                navigate('/order-details');
                                window.location.reload();
                            }, 1000);
                        }
                        else if (cartUpdateData.ok === false) {
                            setTimeout(() => {
                                navigate('/order-details');
                                window.location.reload();
                            }, 1000);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                    // await dispatch(captureOrderMedicine(data))
                }
            },
        }

        const rzp1 = new Razorpay(options);
        rzp1.open();
        console.log(options)
    }

    const height = "75vh";

    console.log(TrackSummaryData)
    const V2HREF = localStorage.getItem("V2HREF")

    return (
        <div className="home-review-banner">
            {showModal && <LoaderAnime showModal={showModal} />}

            <div className="home-app-header ship-data">
                <div className="header-logo ">
                    <div className="user-name text-center">
                        <p>Order Details</p>
                    </div>
                    {!V2HREF && V2HREF === null && (
                        <div className="order-home-repo">
                            <NavLink to={'/buyMedicines'}>
                                <IoHome />
                            </NavLink>
                        </div>
                    )}
                </div>
            </div>

            {TrackData?.status === "loading" ? (
                <MediLoader height={height} />
            ) : (
                <>
                    {TrackSummaryData?.refund_initiated?.length > 0 && (
                        <div className="refund-data-wrapper">
                            <div className="redund-content-box">
                                <img src={refund_image} alt="refund-icon" />
                                <p>View your refund status</p>
                            </div>
                            <div className="refund-btn">
                                <button onClick={handleScrollToRefundBox}>Click Here</button>
                            </div>
                        </div>
                    )}

                    <div className="shipment-order-wrapper">
                        {TrackSummaryData?.is_order_splitted !== 1 && (
                            <div className="order-shipment-data">
                                <h6>Order Details</h6>
                                <p>Ordered on: {TrackSummaryData?.ordered_at}</p>
                                <p>Order ID: {TrackSummaryData?.onemg_order_id}</p>
                                <p>Order Total: {TrackSummaryData?.amount_paid?.[0]?.amount}</p>
                                {TrackSummaryData?.delivered_date !== "" && (
                                    <p>Delivery Date: {TrackSummaryData?.delivered_date}</p>
                                )}
                            </div>
                        )}


                        {((TrackSummaryData?.re_payment === 0 && TrackSummaryData?.order_status !== "Order Cancelled")) && (
                            <div className="order-shipment-data">
                                <h6>Shipment Details</h6>

                                <div className="tracking-container">
                                    {TrackData?.data?.data?.[0]?.track_journey?.map((item, index) => (
                                        <div className="tracking-step" key={index}>
                                            <div className={`step-icon ${item.isCompleted ? 'completed' : ''}`}>
                                                <img src={item?.logo} alt={item?.name} />
                                            </div>
                                            <p className={`step-name ${item.active === 1 ? 'completed-text' : ''}`}>{item?.name}</p>
                                            {index < TrackData?.data?.data?.[0]?.track_journey?.length - 1 && <div className="step-connector"></div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {(TrackSummaryData?.cancellation_reason !== "") && (
                            <div className="order-shipment-data">
                                <h6>Cancellation Reason</h6>

                                <div className="reason-container">
                                    <p>{TrackSummaryData?.cancellation_reason}</p>
                                </div>
                            </div>
                        )}

                        {(TrackSummaryData?.re_payment === 1) && (
                            <div className="order-shipment-data">
                                <h6>Pending Payment</h6>

                                <div className="reason-container">
                                    <p>Payment pending of ₹ {TrackSummaryData?.payment_amount?.toLocaleString('en-IN')} please retry payment within 3 hours</p>
                                    <span>Note:We are currently verifying your payment status. If you have already completed the payment, please allow up to 30 minutes for your order status to be updated.</span>
                                </div>
                            </div>
                        )}

                        {(TrackSummaryData?.re_payment === 0 && TrackSummaryData.order_status === "Payment Pending") && (
                            <div className="order-shipment-data">
                                <div className="reason-container">
                                    <span>Note:We are currently verifying your payment status. If you have already completed the payment, please allow up to 30 minutes for your order status to be updated.</span>
                                </div>
                            </div>
                        )}

                        <div className="item-summary-data-box-repo">
                            <h6>Product Summary</h6>
                            <Shipment_Card MedicineData={TrackData?.data?.data?.[0]?.medicines} />

                            {TrackSummaryData?.is_order_splitted !== 1 && (
                                <PaymentSummaryDetails TrackSummaryData={TrackSummaryData} />
                            )}

                            {/* Refund Box Process */}
                            {TrackSummaryData?.refund_initiated?.length > 0 && TrackSummaryData?.refund_initiated?.map((item) => (
                                <div className="refund-box-process" ref={refundBoxRef}> {/* Assigning ref here */}
                                    <div className="fund-process-box">
                                        <img src={refund_image} alt="refund-icon" />
                                        <p>Refund Initiated</p>
                                    </div>

                                    <div className="fund-total-box">
                                        <p>Refund Total</p>
                                        <p>₹ {item?.refund_total?.toLocaleString('en-IN')}</p>
                                    </div>

                                    <div className="fund-source">
                                        <span>Refund Source</span>
                                    </div>

                                    {item?.wallet > 0 && (
                                        <div className="md-func-source-box">
                                            <p>MB Wallet</p>
                                            <p>₹ {item?.wallet?.toLocaleString('en-IN')}</p>
                                        </div>
                                    )}
                                    {item?.upi > 0 && (
                                        <div className="md-func-source-box">
                                            <p>Online Payment</p>
                                            <p>₹ {item?.upi?.toLocaleString('en-IN')}</p>
                                        </div>
                                    )}
                                </div>
                            ))}

                            <Need_Help type="medicine" />
                        </div>
                    </div>

                    {TrackSummaryData?.is_cancelable === 1 && (
                        <div className="cancel-order-repo-wrap">
                            <button onClick={handleShow}>CANCEL ORDER</button>
                        </div>
                    )}

                    {TrackSummaryData?.invoice_url !== "" && (
                        <div className="cancel-order-repo-wrap download-invoice">
                            <a href={TrackSummaryData?.invoice_url} target='blank'>
                                <button>DOWNLOAD INVOICE</button>
                            </a>
                        </div>
                    )}

                    {TrackSummaryData?.is_reorderable === 1 && (
                        <div className="reorder-btn-data-repo sticky-bottom">
                            <button onClick={() => handleReorder(TrackSummaryData?.medicine_order_id)}>REORDER</button>
                        </div>
                    )}

                    {TrackSummaryData?.re_payment === 1 && (
                        <div className="reorder-btn-data-repo paynow-box-btn sticky-bottom">
                            <p>₹ {TrackSummaryData?.payment_amount?.toLocaleString('en-IN')}</p>
                            <button className='btn-pay-now' onClick={handleRepayment}>
                                PAY NOW
                            </button>
                        </div>
                    )}
                </>
            )}




            <CancelOrderModal show={show} setShow={setShow} OrderId={TrackSummaryData?.medicine_order_id} />
            <ToastBox />
        </div>
    );
};

export default ShipmentOrder;
