import React, { useEffect, useState } from 'react'
import useLocationState from '../Medicine/hooks/useLocationState'
import { useDispatch, useSelector } from 'react-redux';
import { diabetesApi, fetchVendorPackageForm } from '../../Redux/actions/PostApiCall';
import { headerLogo } from '../../images';
import './styles/common.css';
import LoaderAnime from '../Medicine/animation/prescription_loader'
import { useNavigate } from 'react-router';
import { fetchVendorData, resetVendorState } from '../../Redux/actions/VendorSlice';
import useQuery from '../../CustomHooks/useQuery';

const Diabeties = () => {
    const data = useLocationState();
    console.log(data)
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const { data: PackageData, loader, error } = useSelector(state => state.vendorpackage)
    console.log(PackageData)
    const navigate = useNavigate();
    const name = useQuery("nutrition")
    const name2 = useQuery("diabetes")
    const handleVendorPack = async (id) => {
        setModalShow(true);

        const postData = {
            type: data?.Type,
            package_id: "",
            vendor_id: id?.id,
            assign_package_id: "",
        };

        try {
            await dispatch(fetchVendorData({ postData }));
            setModalShow(false);
        } catch (error) {
            console.error("Error fetching vendor package:", error);
            setModalShow(false); // Ensure modal is closed even if API fails
        }
    };

    useEffect(() => {
        if (PackageData) {
            if (PackageData?.data?.package_list.length > 0) {
                navigate(`/diabeties-detail?${name ? "nutrition=1" : "diabetes=1"}`, { state: PackageData })
            } else {
                const OceanData = {
                    data: PackageData?.data,
                    title: "Diabeties Care",
                    Type: data?.Type
                }
                navigate("/ocean-form", { state: OceanData })
            }
        }
        dispatch(resetVendorState())
    }, [PackageData])

    return (
        <div className="home-review-banner">
            {modalShow && <LoaderAnime showModal={modalShow} />}
            <div className="home-app-header-new sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <h6 style={{ marginLeft: '5px', textTransform: 'capitalize' }}>{data?.title}</h6>
                    </div>
                </div>
            </div>
            <div className="wrapper-wellness-diabetes-cover">
                <div className="repo-image-diabetes">
                    <img src={data?.data?.banners?.top_banner?.[0]?.banner} alt="" />
                </div>
            </div>
            <div className="our-partner-repo-common">
                <div className='line-repo-common'></div>
                <div className='our-partner-text'>
                    <h6>Our Partner</h6>
                </div>
                <div className='line-repo-common'></div>
            </div>

            <div className="repo-partner-cover-boxes-bankai">
                {data?.data?.data?.map((item, index) => (
                    <div className="cover-boxes-partner-solo" onClick={() => handleVendorPack(item)}>
                        <div className="solo-image-box">
                            <img src={item?.banner} alt="" />
                        </div>
                        <div className="solo-content-repo">
                            <h6>{item?.name}</h6>
                            <p>{item?.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Diabeties