import React, { useEffect, useState } from 'react';
import useLocationState from '../Medicine/hooks/useLocationState';
import { headerLogo } from '../../images';
import { DentalBanner, hospitalIcon, hospitalIconbhai, HospitalServicesIcon } from '../Medicine/Images';
import { FaBuilding } from 'react-icons/fa';
import { IoLocationSharp } from "react-icons/io5";
import { Swiper, SwiperSlide } from 'swiper/react';
import { DoctorData } from '../../data';
import DoctorCard from './DoctorCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHospitalDetails } from '../../Redux/actions/GetApiCall';
import { FaTelegramPlane } from "react-icons/fa";
import LoaderAnime from '../Medicine/animation/prescription_loader'
import { IoBed } from "react-icons/io5";
import { Autoplay } from 'swiper/modules';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router';

const HospitalDetails = () => {
    const Data = useLocationState();
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState("Overview");
    const [selectedCategory, setSelectedCategory] = useState('All');
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false)
    const navigate = useNavigate();
    const HospitalDetailData = useSelector(state => state.hospital_details)

    useEffect(() => {
        if (Data) {
            dispatch(fetchHospitalDetails(Data?.id))
        }
    }, [Data])



    const directionsUrl = HospitalDetailData?.data?.data?.google_map_link;



    // Function to switch tabs
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    // Extract unique department names for the category filter
    // Create categories, excluding null or undefined departments



    // Filter doctors by both category and search term
    const filteredDoctors = HospitalDetailData?.data?.data?.doctors?.filter(doctor => {
        if (!doctor || !doctor.departments) return false; // Ensure doctor and departments exist

        // Convert doctor name and city to lowercase safely
        const doctorName = doctor.doctor_name ? doctor.doctor_name.toLowerCase() : "";
        const doctorCity = doctor.location ? doctor.location.toLowerCase() : "";

        // Extract department names safely
        const departmentNames = doctor.departments
            .map(department => department.department_name?.toLowerCase() || "")
            .join(" ");

        // Check if doctor matches the selected category
        const matchesCategory =
            selectedCategory === 'All' ||
            doctor.departments.some(department => department.department_name === selectedCategory);

        // Check if the doctor matches the search term
        const matchesSearch =
            searchTerm.trim() === "" || // If search term is empty, show all
            doctorName.includes(searchTerm.toLowerCase()) ||
            doctorCity.includes(searchTerm.toLowerCase()) ||
            departmentNames.includes(searchTerm.toLowerCase());

        return matchesCategory && matchesSearch;


    });



    const categories = filteredDoctors?.length > 0
        ? ['All', ...new Set(filteredDoctors.flatMap(doctor =>
            doctor.departments.map(department => department.department_name)
        ))]
        : [...new Set(filteredDoctors?.flatMap(doctor =>
            doctor.departments.map(department => department.department_name)
        ))];



    useEffect(() => {
        if (HospitalDetailData?.status === "loading") {
            setModalShow(true)
        }
        else {
            setModalShow(false)
        }
    }, [HospitalDetailData])

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const handleFormRepo = () => {
        navigate("/opd-form", { state: "Hospital" })
    }

    return (
        <div className='home-review-banner'>
            {modalShow && <LoaderAnime showModal={modalShow} />}
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Hospital Details</p>
                    </div>
                </div>
            </div>

            {/* Hospital Basic Info */}
            <div className="doctor-data-repo" style={{ paddingTop: '0px' }}>
                <div className="hospital-tepo-image">
                    <img src={HospitalDetailData?.data?.data?.icon ? `${HospitalDetailData?.data?.data?.icon}` : hospitalIconbhai}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = hospitalIconbhai;
                        }} alt="hospital-image" />
                </div>
                <div className="doctor-data-box-tepo">
                    <h5 style={{ marginBottom: '0px' }}>{HospitalDetailData?.data?.data?.name}</h5>
                    {HospitalDetailData?.data?.data?.speciality_type && (
                        <p style={{ fontSize: "12px", color: "black", marginBottom: '0px' }}>{HospitalDetailData?.data?.data?.speciality_type}</p>
                    )}
                    {HospitalDetailData?.data?.data?.area && (
                        <p style={{ fontSize: "12px", color: "black", marginBottom: '0px' }}>
                            {HospitalDetailData?.data?.data?.area}
                        </p>
                    )}
                </div>
            </div>

            {/* Timing Data */}
            <div className="consultation-timing-wrapper-box">

            </div>

            {/* Tab Navigation */}
            <div className="tab-navigation" style={{ marginTop: '5px' }}>
                <button onClick={() => handleTabClick("Overview")} className={activeTab === "Overview" ? "active-tab" : ""}>Overview</button>
                <button
                    onClick={() => handleTabClick("Doctors")}
                    className={activeTab === "Doctors" ? "active-tab" : ""}
                >
                    Doctors{HospitalDetailData?.data?.data?.doctors?.length > 0 && ` (${HospitalDetailData?.data?.data?.doctors?.length})`}
                </button>

                <button onClick={() => handleTabClick("Services")} className={activeTab === "Services" ? "active-tab" : ""}>Services</button>
            </div>

            {/* Tab Content */}
            <div className="tab-content">
                {activeTab === "Overview" && (
                    <div className='about-repo-control'>
                        <Swiper
                            loop={true}
                            autoplay={{ delay: 2000, disableOnInteraction: false }}
                            modules={[Autoplay]}
                        >
                            {HospitalDetailData?.data?.data?.hospital_images?.map((item, index) => {
                                console.log(item); // This should be outside the return

                                return (
                                    <SwiperSlide key={index}>
                                        <div className="repo-swiper-click-loki">
                                            <img src={item} alt="hospital-image" />
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        {HospitalDetailData?.data?.data?.about_hospital !== null && (
                            <>
                                <h5>About</h5>
                                <p className={`about-text ${isExpanded ? "expanded" : "collapsed"}`}>
                                    {HospitalDetailData?.data?.data?.about_hospital}
                                </p>
                                <button className="read-more-btn" onClick={toggleReadMore}>
                                    {isExpanded ? "Read Less" : "Read More"}
                                </button>
                            </>
                        )}

                        {HospitalDetailData?.data?.data?.number_of_beds !== null && (
                            <div className="timings unique-timings mt-2">
                                <h5 className="unique-timings-heading">No. of Beds</h5>
                                <p className="unique-timings-days">{HospitalDetailData?.data?.data?.number_of_beds}</p>
                            </div>
                        )}

                        <div className="clinic-info unique-clinic-info">
                            <h4 className="unique-clinic-name">Address</h4>
                            <p className="unique-clinic-address">{HospitalDetailData?.data?.data?.address}</p>
                            <button
                                className="direction-icon unique-direction-icon"
                                onClick={() => window.open(directionsUrl, "_blank")}
                                title="Click for Directions"
                            >
                                <IoLocationSharp /> View on map
                            </button>
                            {HospitalDetailData?.data?.data?.achievement !== null && (
                                <div className="timings unique-timings mt-2">
                                    <h5 className="unique-timings-heading">Achievement</h5>
                                    <p className="unique-timings-days">{HospitalDetailData?.data?.data?.achievement}</p>
                                </div>
                            )}
                            <div className="timings unique-timings">
                                <h5 className="unique-timings-heading">Timings</h5>
                                <p className="unique-timings-days">{HospitalDetailData?.data?.data?.operational_hours}</p>
                            </div>
                        </div>
                    </div>
                )}

                {activeTab === "Doctors" && (
                    <div>
                        <div className="filter-buttons">
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={2}
                                effect="slide"
                                grabCursor={true}

                            >
                                <div className="swiper-wrapper">
                                    {categories.map((category, index) => (
                                        <SwiperSlide
                                            key={index}
                                            className={`repo-cate-doc ${selectedCategory === category ? 'btn-active' : ''}`}
                                            onClick={() => setSelectedCategory(category)}
                                        >
                                            {category}
                                        </SwiperSlide>
                                    ))}
                                </div>
                            </Swiper>
                        </div>

                        <div className="search-bar">
                            <input
                                type="text"
                                placeholder="Search doctor..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyPress={(e) => {
                                    const regex = /^[a-zA-Z\s]*$/; // Allow only letters and spaces
                                    if (!regex.test(e.key)) {
                                        e.preventDefault(); // Prevent invalid input
                                    }
                                }}
                            />
                            <FiSearch />
                        </div>

                        <div className="doctor-grid">
                            {(filteredDoctors === undefined || filteredDoctors?.length === 0) && (
                                <div className="no-doctor-found" style={{ height: '60vh', display: 'flex', alignItems: 'center' }}>
                                    <p className='no-medicine-found-bankai' style={{ height: '65vh' }}>
                                        <span style={{ fontSize: '15px' }}>OOPS</span>
                                        <span className='repo-bank-ichigo' style={{ fontSize: '15px' }}>No such request found</span>
                                        <span className='click-bankai-span' style={{ fontSize: '16px' }} onClick={handleFormRepo}>Please click below to schedule the appointment</span>
                                    </p>
                                </div>
                            )}
                            {filteredDoctors?.map((doctor, index) => (
                                <DoctorCard key={index} doctor={doctor} hospitalId={HospitalDetailData?.data?.data?.id} hospitalAddress={HospitalDetailData?.data?.data?.address} />
                            ))}
                        </div>

                    </div>
                )}

                {activeTab === "Services" && (
                    <div className='hospital-services-repo-wrapper-clover'>
                        <h5>Services</h5>
                        <ul>
                            {HospitalDetailData?.data?.data?.services?.map((item, index) => (
                                <li>{item?.services_name}</li>
                            ))}
                        </ul>
                        {/* Add more specific services here if available */}
                    </div>
                )}
            </div>
        </div>
    );
};

export default HospitalDetails;
