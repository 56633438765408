import React, { useEffect } from 'react'
import TeleconsultationSection from '../../LocationSection/TeleconsultationSection'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPackageList } from '../../../Redux/actions/GetApiCall';

const CommonPackage = () => {
    const dispatch = useDispatch();
    const packagesData = useSelector((state) => state.package_list);

    useEffect(() => {
        dispatch(fetchPackageList());
    }, [])

    return (
        <div className="package-loki">
            {packagesData?.data?.active_package?.length > 0 && (
                <TeleconsultationSection
                    packageList={packagesData?.data?.active_package}
                />
            )}
        </div>
    )
}

export default CommonPackage