import React from 'react'
import SuccessPack from '../../Animation/SuccessPack'
import { DoctorEyeImage } from '../Medicine/Images'

const ThankDentalOrder = ({ ThankData }) => {
    console.log(ThankData)

    return (
        <div>
            <div className='thank-rep-wrapper'>
                {ThankData?.order_status === "New Consultation" && (
                    <div className="order-status-data-repo-wrapper">
                        <SuccessPack />
                        <p>{ThankData?.order_message}</p>
                        <span className='book-time-date-te'>Booked on {ThankData?.booked_on}</span>
                    </div>
                )}

                <div className="card-wrapper-bankai-rpeo mt-3" style={{ border: 'none' }}>
                    <div className="image-repo-doc-banaki" style={{ boxShadow: 'none' }}>
                        <img src={DoctorEyeImage} alt="" />
                    </div>
                    <div className="content-repo-doc-ank-ai">
                        <h6>{ThankData?.doctor?.name}</h6>
                        <p>{ThankData?.doctor?.designation}</p>
                        <div className="repo-proicce-bankai">
                            <div className="crop-pricerepo">
                                {Math.round(ThankData?.hospital?.medibhai_price) === Math.round(ThankData?.hospital?.mrp) ? (
                                    <span>Consultation Fees: ₹ {Math.round(ThankData?.hospital?.medibhai_price)}</span>
                                ) : (
                                    <>
                                        <span>Consultation Fees: ₹ {Math.round(ThankData?.hospital?.medibhai_price)}</span>
                                        <p>₹ {Math.round(ThankData?.hospital?.mrp)}</p>
                                    </>
                                )}
                            </div>
                        </div>

                    </div>
                </div>

                <div className="repo-card-bankai-one">
                    <span className='repo-span-ban-cvd'>{ThankData?.hospital?.name}</span>
                    <p>Address: <span>{ThankData?.hospital?.address}{ThankData?.hospital?.district},{ThankData?.hospital?.city}-{ThankData?.hospital?.pincode}</span></p>
                </div>

                <div className="telewrapper-cont-text-cover" style={{ marginTop: '0px' }}>
                    <div className="patient-details-wrapper-oreder">
                        <p className='patien-text-de'>Patient Details</p>
                        <hr className='patient-lab-data-hr' />
                        <p>Patient Name : {ThankData?.patient_name}</p>
                        <div className="patient-details-age-wrapper">
                            <p>Age : {ThankData?.age}</p>
                            <p>Gender : {ThankData?.gender}</p>
                            <p>Relation : {ThankData?.relation}</p>
                        </div>
                    </div>
                    <hr className='patient-lab-data-hr' />

                    <div className="appoint-date-repo-wrapper">
                        <div className="appointment-date-repo">
                            <p>Appointment Date</p>
                            <p className='bond-text-repo'>{ThankData?.appointment_date}</p>
                        </div>
                        <div className="appointment-date-repo">
                            <p>Appointment Time</p>
                            <p className='bond-text-repo'>{ThankData?.appointment_time}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThankDentalOrder