import React, { useEffect, useState } from 'react';
import { appOPDIcon, headerLogo } from '../../images';
import useLocationState from '../Medicine/hooks/useLocationState';
import { IoLocationSharp } from 'react-icons/io5';
import { MdSchool } from "react-icons/md";
import { AiOutlineMessage } from "react-icons/ai";
import { MdPersonAddAlt1 } from "react-icons/md";
import { FaBuilding, FaCalendarPlus, FaHospital } from "react-icons/fa";
import { IoIosVideocam, IoMdArrowRoundUp } from "react-icons/io";
import { Tab, Tabs } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from 'react-router';
import { IoMdArrowRoundDown } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { fetchDoctorDetails } from '../../Redux/actions/GetApiCall';
import LoaderAnime from '../Medicine/animation/prescription_loader';
import { doctorTimeSlot, opd_book } from '../../Redux/actions/PostApiCall';
import { filter } from 'lodash';
import { DoctorEyeImage } from '../Medicine/Images';
import Medicine_Success from '../Medicine/animation/Medicine_Success';
import toast from 'react-hot-toast';
import ToastBox from '../Medicine/animation/ToastBox';

const DoctorDetails = () => {
    const Data = useLocationState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const DoctorDetailsData = useSelector(state => state.doctor_details);
    const DoctorTimeSlot = useSelector(state => state.doctor_time_slot);
    const DoctorData = DoctorDetailsData?.data?.data;
    const opd_response = useSelector((state) => state?.opd_book_response);
    const userData = JSON.parse(localStorage.getItem("UserData"));

    useEffect(() => {
        if (Data?.departments?.length === 1) {
            dispatch(fetchDoctorDetails(Data?.doctor_id, Data?.departments?.[0]?.department_id, Data?.hospitalID));
            const DataRepo = {
                doctor_id: Data?.doctor_id,
                hospital_id: Data?.hospitalID,
                date: selectedDate
            }
            dispatch(doctorTimeSlot(DataRepo))
        }
        else {
            dispatch(fetchDoctorDetails(Data?.doctor_id, "", Data?.hospitalID));
            const DataRepo = {
                doctor_id: Data?.doctor_id,
                hospital_id: Data?.hospitalID,
                date: selectedDate
            }
            dispatch(doctorTimeSlot(DataRepo))
        }
    }, []);

    useEffect(() => {
        if (DoctorDetailsData?.status === "loading" || DoctorTimeSlot?.status === "loading") {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [DoctorDetailsData, DoctorTimeSlot]);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const [onlineSelectedTime, setOnlineSelectedTime] = useState(null);
    const [inPersonSelectedTime, setInPersonSelectedTime] = useState(null);
    const [consultationType, setConsultationType] = useState("");
    const [successmodal, setSuccessModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [activeTab, setActiveTab] = useState("tab1"); // Tracks "Today" or "Tomorrow"
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));
    const [selectedHospitalID, setSelectedHospitalID] = useState(
        DoctorData?.hospital_details?.[0]?.hospital_id // Default to the first hospital
    );

    const handleCheckboxChange = (hospitalID) => {
        setIsExpanded(!isExpanded)
        setSelectedHospitalID(hospitalID); // Update selected hospital ID
        handleHospitalID(hospitalID); // Call the provided handler
        const DataRepo = {
            doctor_id: Data?.doctor_id,
            hospital_id: Data?.hospitalID,
            date: selectedDate // Use the local formattedDate variable here
        };

        dispatch(doctorTimeSlot(DataRepo));
    };

    const handleViewAll = (type) => {
        const PanelTye = type === 1 ? "Online Consultation" : "In-Person Consultation"
        const DataRepo = {
            panelType: type,
            data: DoctorData,
            panelName: PanelTye,
            hospitalId: Data && Data?.hospitalID,
            departmentType: DoctorData?.departments,
            selectedDateRepo: selectedDate
        }
        navigate("/doctor-slots", { state: DataRepo });
    };


    const handleOnlineTimeSlotClick = (time) => {
        const selectedDate = activeTab === "tab1" ? new Date() : new Date(new Date().setDate(new Date().getDate() + 1));
        const formattedDate = formatDate(selectedDate);
        setOnlineSelectedTime({ time, date: formattedDate });
        setInPersonSelectedTime(null);
        setConsultationType("Online Consultation");
        console.log({ time, date: formattedDate });
    };

    const handleTabOnlineSelect = (tab) => {
        let formattedDate;

        if (tab === 'tab1') {
            // Today
            formattedDate = formatDate(new Date());
            setSelectedDate(formattedDate);
        } else if (tab === 'tab2') {
            // Tomorrow
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            formattedDate = formatDate(tomorrow);
            setSelectedDate(formattedDate);
        }

        const DataRepo = {
            doctor_id: Data?.doctor_id,
            hospital_id: Data?.hospitalID,
            date: formattedDate // Use the local formattedDate variable here
        };

        dispatch(doctorTimeSlot(DataRepo));
        setActiveTab(tab);
        setOnlineSelectedTime(null); // Reset online selected time
    };
    const handleTabInpersonSelect = (tab) => {
        let formattedDate;

        if (tab === 'tab1') {
            // Today
            formattedDate = formatDate(new Date());
            setSelectedDate(formattedDate);
        } else if (tab === 'tab2') {
            // Tomorrow
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            formattedDate = formatDate(tomorrow);
            setSelectedDate(formattedDate);
        }

        const DataRepo = {
            doctor_id: Data?.doctor_id,
            hospital_id: Data?.hospitalID,
            date: formattedDate // Use the local formattedDate variable here
        };

        dispatch(doctorTimeSlot(DataRepo));
        setActiveTab(tab);
        setInPersonSelectedTime(null); // Reset online selected time
    };




    const handleInPersonTimeSlotClick = (time) => {
        const selectedDate = activeTab === "tab1" ? new Date() : new Date(new Date().setDate(new Date().getDate() + 1));
        const formattedDate = formatDate(selectedDate);
        setInPersonSelectedTime({ time, date: formattedDate });
        setOnlineSelectedTime(null);
        setConsultationType("In-Person Consultation");
        console.log(inPersonSelectedTime);
    };


    const handleDoctorOrder = () => {
        const orderData = {
            DoctorData: DoctorData,
            Online_time: onlineSelectedTime?.time,
            online_date: onlineSelectedTime?.date,
            inperson_time: inPersonSelectedTime?.time,
            inperson_date: inPersonSelectedTime?.date,
            doctor_id: DoctorData?.doctor_id,
            panelType: consultationType === "Online Consultation" ? 1 : 2,
            panelName: consultationType,
            hospitalId: Data?.hospitalID && Data?.hospitalID,
            departmentType: DoctorData?.departments,
        };
        navigate("/doctor-form", { state: orderData });
    };



    const handleHospitalID = (id) => {
        dispatch(fetchDoctorDetails(Data?.doctor_id, Data?.departments?.[0]?.department_id, id));
    }

    const handleEnquiry = async () => {
        setShowModal(true)
        const Data = {
            user_name: userData?.name,
            user_mobile_number: userData?.mobile_number,
            user_email: userData?.email_id,
            hospital_name: DoctorData?.hospital_details?.[0]?.hospital_name,
            doctor_name: DoctorData?.doctor_name,
        }
        await dispatch(opd_book(Data))
    }

    useEffect(() => {
        if (opd_response?.status === "success") {
            setModalShow(false)
            setSuccessModal(true)

            setTimeout(() => {
                setSuccessModal(false)
                navigate("/doctor-consultation")
            }, 2500);
        }
        else if (opd_response?.status === "failure") {
            setShowModal(false)
            toast.error(opd_response?.error)
        }
    }, [opd_response])


    return (
        <div className='home-review-banner'>
            {showModal && <LoaderAnime showModal={showModal} />}
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Book Consultation</p>
                    </div>
                </div>
            </div>
            <div className="doctor-data-repo">
                <div className="doctor-tepo-image">
                    <img src={DoctorEyeImage} alt="" />
                </div>
                <div className="doctor-data-box-tepo">
                    <h5 style={{ marginBottom: '0px' }}>{DoctorData?.doctor_name}</h5>
                    <p style={{ fontSize: "11px", color: "#757575", marginBottom: '5px' }}>{DoctorData?.departments?.[0]?.department_name}</p>
                    <p className="doctor-category"><MdSchool /> {DoctorData?.education}</p>
                    <p className="doctor-category"><FaBuilding /> {DoctorData?.address}</p>
                </div>
            </div>

            <div className="hospital-card-container">
                {/* Main hospital (first) */}
                <div className="txt-repo-data-txt">
                    <h6>Select another hospital</h6>
                </div>
                <div className="inperson-address-repo-clinic repo-ex-repo-clover">
                    <div className="repo-docote-data repo-bro-clover">
                        <div className="repo-txt-txt-repo">
                            <div className="checkbox-wrapper">
                                <input
                                    id="terms-checkbox-0"
                                    name="checkbox"
                                    type="checkbox"
                                    defaultChecked
                                    onChange={() =>
                                        handleCheckboxChange(
                                            DoctorData?.hospital_details?.[0]?.hospital_id
                                        )
                                    }
                                />
                                <label className="terms-label" htmlFor="terms-checkbox-0">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 200 200"
                                        className="checkbox-svg repo-text-repo-check-box"
                                    >
                                        <mask
                                            fill="white"
                                            id="path-1-inside-1_476_5-37"
                                        >
                                            <rect height="200" width="200"></rect>
                                        </mask>
                                        <rect
                                            mask="url(#path-1-inside-1_476_5-37)"
                                            strokeWidth="40"
                                            className="checkbox-box"
                                            height="200"
                                            width="200"
                                        ></rect>
                                        <path
                                            strokeWidth="15"
                                            d="M52 111.018L76.9867 136L149 64"
                                            className="checkbox-tick"
                                        ></path>
                                    </svg>
                                </label>
                            </div>
                            <h6>{DoctorData?.hospital_details?.[0]?.hospital_name}</h6>
                        </div>
                        {DoctorData?.hospital_details?.length > 1 && (
                            <button
                                className="dropdown-toggle-button"
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                <span
                                    className={`arrow ${isExpanded ? "arrow-rotated" : ""
                                        }`}
                                >
                                    ▼
                                </span>
                            </button>
                        )}
                    </div>
                    <div>
                        <p>{DoctorData?.hospital_details?.[0]?.hospital_address}</p>
                    </div>

                    {DoctorData?.hospital_details?.length > 1 && (
                        <div
                            className={`hospital-card-container__additional-cards ${isExpanded
                                ? "hospital-card-container__additional-cards--expanded"
                                : ""
                                }`}
                        >
                            {DoctorData.hospital_details
                                .slice(1)
                                .map((clinic, index) => (
                                    <div key={index}>
                                        <div className="repo-docote-data repo-bro-clover">
                                            <div className="repo-txt-txt-repo">
                                                <div className="checkbox-wrapper">
                                                    <input
                                                        id={`terms-checkbox-${index + 1}`}
                                                        name="checkbox"
                                                        type="checkbox"
                                                        checked={
                                                            selectedHospitalID ===
                                                            clinic?.hospital_id
                                                        }
                                                        onChange={() =>
                                                            handleCheckboxChange(
                                                                clinic?.hospital_id
                                                            )
                                                        }
                                                    />
                                                    <label
                                                        className="terms-label"
                                                        htmlFor={`terms-checkbox-${index + 1}`}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 200 200"
                                                            className="checkbox-svg repo-text-repo-check-box"
                                                        >
                                                            <mask
                                                                fill="white"
                                                                id="path-1-inside-1_476_5-37"
                                                            >
                                                                <rect
                                                                    height="200"
                                                                    width="200"
                                                                ></rect>
                                                            </mask>
                                                            <rect
                                                                mask="url(#path-1-inside-1_476_5-37)"
                                                                strokeWidth="40"
                                                                className="checkbox-box"
                                                                height="200"
                                                                width="200"
                                                            ></rect>
                                                            <path
                                                                strokeWidth="15"
                                                                d="M52 111.018L76.9867 136L149 64"
                                                                className="checkbox-tick"
                                                            ></path>
                                                        </svg>
                                                    </label>
                                                </div>
                                                <h6>{clinic?.hospital_name}</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <p>{clinic?.hospital_address}</p>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>

                {/* Additional hospitals */}
            </div>


            <div className="consultation-wrapper-clover">

                {DoctorData?.in_person_price !== null && (
                    <div className="online-consultation-wrapper-clover mb-3" onClick={() => setConsultationType("In-Person Consultation")}>
                        <div className="online-text-repo inperson-repo">
                            <div className="icons-box-online inpersone-repo">
                                <FaHospital />
                                In-Person Consultation
                            </div>
                            <div className="onlinr-price inpersone-pri">
                                <p>₹ {DoctorData?.in_person_price}</p>
                            </div>
                        </div>


                        <div className="tabs-days-repo-clover">
                            <Tabs
                                defaultActiveKey="tab1"
                                id="inperson-tab-example"
                                className="mb-1 repo-clover-tabs-repo"
                                onSelect={handleTabInpersonSelect}
                                style={{ justifyContent: 'center' }}
                            >
                                <Tab eventKey="tab1" title="Today" className='tab-box-repo-clover'>
                                    <div className='time-slot-clover-repo'>
                                        {DoctorTimeSlot?.data?.available_times?.length > 0 ? (
                                            <Swiper spaceBetween={8} slidesPerView={3.5}>
                                                {DoctorTimeSlot?.data?.available_times?.filter(item => item?.available)?.map((item, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div
                                                            className={`slot-repo-clover ${inPersonSelectedTime?.time === item?.time ? 'active-slot' : ''}`}
                                                            onClick={() => handleInPersonTimeSlotClick(item?.time)}
                                                        >
                                                            <p>{item?.time}</p>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}

                                            </Swiper>
                                        ) : (
                                            <p className='repo-not-slot-aval'>No slots are available for the selected date.</p>
                                        )}
                                    </div>
                                    <div className="view-slots-repo-lover">
                                        <button onClick={() => handleViewAll(2)}>Select Another Date <FaAngleRight /></button>
                                    </div>
                                </Tab>
                                <Tab eventKey="tab2" title="Tomorrow">
                                    <div className='time-slot-clover-repo'>
                                        {DoctorTimeSlot?.data?.available_times?.length > 0 ? (
                                            <Swiper spaceBetween={8} slidesPerView={3.5}>
                                                {DoctorTimeSlot?.data?.available_times?.filter(item => item?.available)?.map((item, index) => (
                                                    <SwiperSlide key={index}>
                                                        {console.log(item?.time)}
                                                        <div
                                                            className={`slot-repo-clover ${inPersonSelectedTime?.time === item?.time ? 'active-slot' : ''}`}
                                                            onClick={() => handleInPersonTimeSlotClick(item?.time)}
                                                        >
                                                            <p>{item?.time}</p>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}

                                            </Swiper>
                                        ) : (
                                            <p className='repo-not-slot-aval'>No slots are available for the selected date.</p>
                                        )}
                                    </div>
                                    <div className="view-slots-repo-lover">
                                        <button onClick={() => handleViewAll(2)}>Select Another Date <FaAngleRight /></button>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                )}

                {DoctorData?.online_price !== null && (
                    <div className="online-consultation-wrapper-clover" onClick={() => setConsultationType("Online Consultation")}>
                        <div className="online-text-repo">
                            <div className="icons-box-online">
                                <IoIosVideocam />
                                Online Consultation
                            </div>
                            <div className="onlinr-price">
                                <p>₹ {DoctorData?.online_price}</p>
                            </div>
                        </div>
                        <div className="tabs-days-repo-clover">
                            <Tabs
                                defaultActiveKey="tab1"
                                id="uncontrolled-tab-example"
                                className="mb-1 repo-clover-tabs-repo"
                                onSelect={handleTabOnlineSelect}
                                style={{ justifyContent: 'center' }}
                            >
                                <Tab eventKey="tab1" title="Today" className='tab-box-repo-clover'>
                                    <div className='time-slot-clover-repo'>
                                        {DoctorTimeSlot?.data?.available_times?.length > 0 ? (
                                            <Swiper spaceBetween={8} slidesPerView={3.5}>
                                                {DoctorTimeSlot?.data?.available_times?.filter(item => item?.available)?.map((item, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div
                                                            className={`slot-repo-clover ${onlineSelectedTime?.time === item?.time ? 'active-slot' : ''}`}
                                                            onClick={() => handleOnlineTimeSlotClick(item?.time)}
                                                        >
                                                            <p>{item?.time}</p>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        ) : (
                                            <p className='repo-not-slot-aval'>No slots are available for the selected date.</p>
                                        )}
                                    </div>
                                    <div className="view-slots-repo-lover">
                                        <button onClick={() => handleViewAll(1)}>Select Another Date <FaAngleRight /></button>
                                    </div>
                                </Tab>
                                <Tab eventKey="tab2" title="Tomorrow">
                                    <div className='time-slot-clover-repo'>
                                        {DoctorTimeSlot?.data?.available_times?.length > 0 ? (
                                            <Swiper spaceBetween={8} slidesPerView={3.5}>
                                                {DoctorTimeSlot?.data?.available_times?.filter(item => item?.available)?.map((item, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div
                                                            className={`slot-repo-clover ${onlineSelectedTime?.time === item?.time ? 'active-slot' : ''}`}
                                                            onClick={() => handleOnlineTimeSlotClick(item?.time)}
                                                        >
                                                            <p>{item?.time}</p>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        ) : (
                                            <p className='repo-not-slot-aval'>No slots are available for the selected date.</p>
                                        )}
                                    </div>
                                    <div className="view-slots-repo-lover">
                                        <button onClick={() => handleViewAll(1)}>Select Another Date <FaAngleRight /></button>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                )}

            </div>

            {/* <div className="About-doctor-repo">
                <h6 style={{ marginTop: '20px', fontSize: '17px', marginBottom: '10px' }}>About the doctor</h6>
                <div className="about-user-data">

                    <div className="experience-repo-text mb-2">
                        <div className="user-icon">
                            <FaCalendarPlus />
                        </div>
                        <div className="expo-text-tepo">
                            <p>10 years of experience</p>
                            <span>Specializing in comprehensive healthcare and preventive care</span>
                        </div>
                    </div>
                    <div className="experience-repo-text mb-2">
                        <div className="user-icon">
                            <AiOutlineMessage />
                        </div>
                        <div className="expo-text-tepo">
                            <p>85% Recommend</p>
                            <span>358 patients would Recommend this doctor to their friends and family.</span>
                        </div>
                    </div>
                    <div className="experience-repo-text">
                        <div className="user-icon">
                            <MdPersonAddAlt1 />
                        </div>
                        <div className="expo-text-tepo">
                            <p>Online Consultation</p>
                            <span>The consultation is possible on site and online</span>
                        </div>
                    </div>
                </div>


                <div className="bio-doctor-repo">
                    <h6 style={{ marginTop: '20px', fontSize: '17px', marginBottom: '10px' }}>Short Bio</h6>
                    <div className="desc-text-repo">
                        <p>{Data?.description}</p>
                    </div>
                </div>
            </div> */}

            {
                (onlineSelectedTime || inPersonSelectedTime) && (
                    <div className="doctor-buttons-repo-detail sticky-bottom repo-buttons-tepo" style={{marginTop:'127px'}}>
                        <button className="online-btn" onClick={handleDoctorOrder}>
                            Book Consultation
                        </button>
                    </div>
                )
            }
            {
                (DoctorData?.online_price === null && DoctorData?.in_person_price === null) && (
                    <div className="doctor-buttons-repo-detail repo-buttons-tepo" style={{ position: 'absolute', bottom: '0px', width: '100%', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
                        <button className="online-btn" onClick={handleEnquiry} style={{ backgroundColor: '#007e4d' }}>
                            Enquire Now
                        </button>
                    </div>
                )
            }
            {opd_response && <Medicine_Success showModal={successmodal} message={opd_response?.message} status={opd_response?.success} />}
            <ToastBox />
        </div >
    );
};

export default DoctorDetails;
