import React, { useEffect, useRef, useState } from 'react';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { appCommonlyBookTest } from '../../../../../images';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLabOrderDetails, fetchTeleconsultationListDetails } from '../../../../../Redux/actions/GetApiCall';
import useLocationState from '../../../../Medicine/hooks/useLocationState';
import PaymentSummaryDetails from '../../OrderMedicinePages/PaymentSummaryDetails';
import Need_Help from '../../../../Medicine/Components/Need_Help';
import { ReorderApi, ReorderDiagnosticApi, TeleconsultationRebook } from '../../../../../Redux/actions/PostApiCall';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import ToastBox from '../../../../Medicine/animation/ToastBox';
import CancelOrderModal from '../../OrderMedicinePages/CancelOrderModal';
import LoaderAnime from '../../../../Medicine/animation/prescription_loader';
import MediLoader from '../../../../Medicine/animation/MediLoader';
import { pdf_icon, refund_image, reports_found } from '../../../../Medicine/Images';
import TeleReorder from '../components/TeleReorder';
import '../style/Telestyle.css'
import SuccessFull from '../../../../../Animation/SuccessFull';
import { MdCancel } from "react-icons/md";
import { FaExclamationCircle } from "react-icons/fa";
import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { IoHome } from 'react-icons/io5';

const TeleconsultationDetailPage = () => {
    const dispatch = useDispatch();
    const OrderId = useLocationState();
    const OrderDetailsData = useSelector(state => state.teleconsultation_list_details);
    const OrderSummaryData = OrderDetailsData?.data;
    const [showModal, setShowModal] = useState(false);
    const reorderData = useSelector(state => state.reorder_medicine)
    const [show, setShow] = useState(false);
    const [teleshow, setTeleShow] = useState(false);
    const [successmodal, setSuccessModal] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const navigate = useNavigate();
    const reorderTeleconsultation = useSelector(state => state.teleconsultation_rebook);


    const [selectedDate, setSelectedDate] = useState('');
    const [selectedReason, setSelectedReason] = useState('');
    const [cancelReason, setCancelReason] = useState('');


    console.log(OrderId)
    useEffect(() => {
        if (OrderId && OrderId !== "")
            dispatch(fetchTeleconsultationListDetails(OrderId?.orderId));
    }, []);

    const refundBoxRef = useRef(null);

    const handleShow = () => setShow(true);
    const handleTeleShow = () => setTeleShow(true);
    const handleClose = () => setTeleShow(false);


    const handleReorder = async (item) => {
        console.log(item)
        const formData = new FormData();
        // Append data to FormData
        if (item === "cancel") {
            formData.append('is_cancelled', OrderSummaryData?.data?.is_cancelled !== 0 ? OrderSummaryData?.data?.is_cancelled : "");
            formData.append('is_reshduled', "");
            formData.append('appointment_date', "");
            formData.append('appointment_time', "");
            formData.append("cancel_reason", OrderSummaryData?.data?.is_cancelled === 1 ? cancelReason : '');
        } else {
            const formattedDate = format(selectedDate, 'dd-MM-yyyy');
            formData.append("cancel_reason", '');
            formData.append('is_reshduled', OrderSummaryData?.data?.is_reshduled !== 0 ? OrderSummaryData?.data?.is_reshduled : "");
            formData.append('appointment_date', OrderSummaryData?.data?.is_reshduled === 1 ? formattedDate : "");
            formData.append('appointment_time', OrderSummaryData?.data?.is_reshduled === 1 ? selectedReason : "");
            formData.append("cancel_reason", '');

        }

        try {
            // Dispatch with FormData
            await dispatch(TeleconsultationRebook(OrderSummaryData?.data?.id, formData));
            // setShowModal(false);


        } catch (error) {
            toast.error('An error occurred while processing the reorder.');
        } finally {
            setShowModal(false);
        }
    };


    useEffect(() => {
        if (reorderTeleconsultation?.data?.success === true) {
            setTeleShow(false)
            setSuccessModal(true);
            setModalShow(true);
            setTimeout(() => {
                setSuccessModal(false);
                setModalShow(false);
                setSelectedDate('');
                setSelectedReason('')
                // navigate('/order-details');
                window.location.reload();
            }, 2500);
        } else if (reorderTeleconsultation?.data?.success === false) {
            toast.error(reorderTeleconsultation?.data?.message);
        }
    }, [reorderTeleconsultation])


    // Scroll handler to scroll to refund-box-process
    const handleScrollToRefundBox = () => {
        if (refundBoxRef.current) {
            refundBoxRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const height = "75vh";

    const TeleKey = 1;

    console.log(OrderSummaryData?.data?.user_uploaded_reports)
    const V2HREF = localStorage.getItem("V2HREF")

    return (
        <div className="home-review-banner">
            <div className="home-app-header order-details-repo-data">
                <div className="header-logo ">
                    <div className="user-name text-center text-repoo">
                        <p>Order Details</p>
                    </div>
                    {!V2HREF && V2HREF === null && (
                        <div className="order-home-repo">
                            <NavLink to={'/teleconsultation'}>
                                <IoHome />
                            </NavLink>
                        </div>
                    )}
                </div>
            </div>
            {showModal && <LoaderAnime showModal={showModal} />}


            {OrderDetailsData?.status === "loading" ? (
                <MediLoader height={height} />
            ) : (
                <>
                    <div className="lab-order-details-wrapper">

                        {OrderSummaryData?.refund_initiated?.length > 0 && OrderSummaryData?.refund_initiated?.[0]?.refund_total !== 0 && (
                            <div className="refund-data-wrapper">
                                <div className="redund-content-box">
                                    <img src={refund_image} alt="refund-icon" />
                                    <p>View your refund status</p>
                                </div>
                                <div className="refund-btn">
                                    <button onClick={handleScrollToRefundBox}>Click Here</button>
                                </div>
                            </div>
                        )}

                        <div className="lab-booked-order-repo-text">
                            <h6>Booked on {OrderSummaryData?.data?.booked_on}</h6>
                            <p>Order ID : {OrderSummaryData?.data?.order_details?.order_id}</p>
                            <p className='booked-text' style={{ color: OrderSummaryData?.data?.order_status_color_code }}>
                                {(OrderSummaryData?.data?.order_status === "Cancelled" || OrderSummaryData?.data?.order_status === "Payment Failed") && (
                                    <MdCancel style={{ color: OrderSummaryData?.data?.order_status_color_code, marginRight: '5px' }} />
                                )}
                                {OrderSummaryData?.data?.order_status === "New Consultation" && (
                                    <IoIosCheckmarkCircle style={{ color: OrderSummaryData?.data?.order_status_color_code, marginRight: '5px' }} />
                                )}
                                {OrderSummaryData?.data?.order_status === "Payment Pending" && (
                                    <FaExclamationCircle style={{ color: OrderSummaryData?.data?.order_status_color_code, marginRight: '10px', fontSize: "20px" }} />
                                )}

                                {OrderSummaryData?.data?.order_message}
                            </p>
                        </div>


                        <hr className='lab-data-hr' />

                        <div className="booked-test-wrapper">
                            <p className='test-book-test-p'>Booked Consultation Details</p>
                            <div className="booked-wrapper-repo-cover-box">
                                <div className="test-data-content-box" style={{ marginTop: '0px', marginBottom: '10px' }}>
                                    <img src={OrderSummaryData?.data?.departments?.file_name} alt="" />
                                    <div className="test-name-data-content">
                                        <span style={{ fontSize: '14px' }}>{OrderSummaryData?.data?.departments?.name}</span>
                                        {/* <p>Test Includes(20)</p> */}
                                    </div>
                                </div>
                                <hr className='patient-lab-data-hr' />
                                {OrderSummaryData?.report_upload && OrderSummaryData?.report_upload?.length > 0 && (
                                    <>
                                        <div className='report-repo-wrapper-cover'>
                                            <div className="report-data-repo-class">
                                                <div className="report-data-content-box">
                                                    <img src={reports_found} alt="report-found" />
                                                </div>
                                                <p>Generated on <br /> {OrderSummaryData?.report_upload?.[0]?.Generated_on}</p>
                                            </div>
                                            <div className="report-btn-cover-repo-btuton">
                                                <a href={OrderSummaryData?.report_upload?.[0]?.view_report} target='blank'>
                                                    VIEW PRESCRIPTION
                                                </a>
                                            </div>
                                        </div>
                                        <hr className='patient-lab-data-hr' />
                                    </>
                                )}
                                <div className="patient-details-wrapper-oreder">
                                    <p className='patien-text-de'>Patient Details</p>
                                    <p>Patient Name : {OrderSummaryData?.data?.patient_name}</p>
                                    <div className="patient-details-age-wrapper">
                                        <p>Age : {OrderSummaryData?.data?.age} years</p>
                                        <p>Gender : {OrderSummaryData?.data?.gender}</p>
                                    </div>
                                </div>
                                <hr className='patient-lab-data-hr' />

                                <div className="appoint-date-repo-wrapper">
                                    <div className="appointment-date-repo">
                                        <p>Appointment Date</p>
                                        <p className='bond-text-repo'>{OrderSummaryData?.data?.appointment_date}</p>
                                    </div>
                                    <div className="appointment-date-repo">
                                        <p>Appointment Time</p>
                                        <p className='bond-text-repo'>{OrderSummaryData?.data?.appointment_time}</p>
                                    </div>
                                </div>
                                {OrderSummaryData?.data?.user_uploaded_reports?.length > 0 && (
                                    <div className="repor-head-text">
                                        <p>Uploaded Documents</p>
                                    </div>
                                )}
                                {OrderSummaryData?.data?.user_uploaded_reports?.map((item) => (
                                    <div className="view-upload-data-file-repo">
                                        <div className='report-repo-wrapper-cover'>
                                            <div className="report-data-repo-class">
                                                <div className="report-data-content-box open-box-repo-box">
                                                    {item?.file_name?.endsWith('.pdf') ? (
                                                        <img src={pdf_icon} alt="pdf-icon" />
                                                    ) : (
                                                        <img src={item?.file_name} alt="report-found" />
                                                    )}
                                                </div>
                                                <p className='ellipsis-text repo-text-repo-elli'>{item?.og_file_name}</p>
                                            </div>
                                            <div className="report-btn-cover-repo-btuton">
                                                <a href={item?.file_name} target='blank'>
                                                    VIEW
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {OrderSummaryData?.data?.user_uploaded_reports?.length > 0 && (
                                    <hr className='patient-lab-data-hr' />
                                )}

                            </div>
                        </div>

                        <div className="paymentSummary-data">
                            <PaymentSummaryDetails TrackSummaryData={OrderSummaryData} TeleKey={TeleKey} />

                            {OrderSummaryData?.refund_initiated?.length > 0 && OrderSummaryData?.refund_initiated?.[0]?.refund_total !== 0 && OrderSummaryData?.refund_initiated?.map((item) => (
                                <div className="refund-box-process mt-3" ref={refundBoxRef}> {/* Assigning ref here */}
                                    <div className="fund-process-box">
                                        <img src={refund_image} alt="refund-icon" />
                                        <p>Refund Initiated</p>
                                    </div>

                                    <div className="fund-total-box">
                                        <p>Refund Total</p>
                                        <p>₹ {item?.refund_total?.toLocaleString('en-IN')}</p>
                                    </div>

                                    <div className="fund-source">
                                        <span>Refund Source</span>
                                    </div>

                                    {item?.Wallet > 0 && (
                                        <div className="md-func-source-box">
                                            <p>MB Wallet</p>
                                            <p>₹ {item?.Wallet?.toLocaleString('en-IN')}</p>
                                        </div>
                                    )}
                                    {item?.UPI > 0 && (
                                        <div className="md-func-source-box">
                                            <p>Online Payment</p>
                                            <p>₹ {item?.UPI?.toLocaleString('en-IN')}</p>
                                        </div>
                                    )}
                                </div>
                            ))}

                        </div>

                        {/* <Need_Help type="teleconsultation" /> */}
                    </div>
                    {OrderSummaryData?.data?.is_cancelled === 1 && (
                        <div className="cancel-order-repo-wrap">
                            <button onClick={handleShow}>CANCEL ORDER</button>
                        </div>
                    )}

                    {OrderSummaryData?.data?.invoice_url && OrderSummaryData?.invoice_url !== "" && (
                        <div className="cancel-order-repo-wrap download-invoice">
                            <button>DOWNLOAD INVOICE</button>
                        </div>
                    )}

                    {OrderSummaryData?.data?.is_reshduled === 1 && OrderSummaryData?.meet_details?.is_meet === 0 && (
                        <div className="reorder-btn-data-repo sticky-bottom">
                            <button onClick={handleTeleShow}>RESCHEDULE APPOINTMENT</button>
                        </div>
                    )}

                    {OrderSummaryData?.data?.is_reshduled === 0 && OrderSummaryData?.meet_details?.is_meet === 1 && (
                        <div className="reorder-btn-data-repo sticky-bottom">
                            <a href={OrderSummaryData?.meet_details?.meet_link}>
                                <button>JOIN MEETING</button>
                            </a>
                        </div>
                    )}
                </>
            )
            }

            <CancelOrderModal show={show} setShow={setShow} OrderId={OrderSummaryData?.order_id} tabkey={OrderId?.tabKey} setCancelReason={setCancelReason} handleReorder={handleReorder} />
            <TeleReorder show={teleshow} setShow={setShow} handleClose={handleClose} departId={OrderSummaryData?.data?.department_id} setSelectedReason={setSelectedReason} selectedReason={selectedReason} selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleReorder={handleReorder} />
            {successmodal && (
                <SuccessFull status={reorderTeleconsultation?.status} show={modalShow} successMessage={reorderTeleconsultation?.data?.message} animationtype={successmodal} />
            )}
            <ToastBox />

        </div >
    )
}

export default TeleconsultationDetailPage