import React, { useState, useEffect } from 'react';
import useLocationState from '../Medicine/hooks/useLocationState';
import { headerLogo } from '../../images';
import { useNavigate } from 'react-router';
import { packAnime_icon } from '../Medicine/Images';
import { useDispatch, useSelector } from 'react-redux';
import LoaderAnime from '../Medicine/animation/prescription_loader';
import { fetchVendorData, resetVendorState } from '../../Redux/actions/VendorSlice';
import useQuery from '../../CustomHooks/useQuery';

const DiabetiesPackageDetail = () => {
    const initialData = useLocationState();
    const navigate = useNavigate();
    const [selectedData, setSelectedData] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');
    const dispatch = useDispatch();
    const { data: PackageData, loader, error } = useSelector(state => state.vendorpackage);
    const [modalShow, setModalShow] = useState(false);
    const [Data, setData] = useState(initialData);

    const name = useQuery("dental")
    const name2 = useQuery("diabetes")
    const name3 = useQuery("nutrition")

    const handleSelectPackage = async (e) => {
        setModalShow(true);
        const selectedValue = e.target.value;

        if (!selectedValue) {
            setSelectedPackage('');
            setSelectedData('');
            return;
        }

        let selectedItem;
        try {
            selectedItem = JSON.parse(selectedValue);
            setSelectedPackage(selectedItem?.id);
            setSelectedData(selectedItem);
        } catch (error) {
            console.error('Invalid JSON input:', selectedValue);
            setModalShow(false);
            return;
        }

        const postData = {
            type: name3 ? "ocean_nutrition" : name2 ? "ocean_diabetes" : name ? "ocean_dental" : "ocean_eye",
            package_id: selectedItem?.package_id,
            vendor_id: Data?.data?.vendor_id,
            assign_package_id: selectedItem?.assign_package_id,
        };

        try {
            await dispatch(fetchVendorData({ postData }));
        } catch (error) {
            console.error('Error fetching vendor package:', error);
        } finally {
            setModalShow(false);
            dispatch(resetVendorState())
        }
    };

    // Effect to update Data when PackageData changes
    useEffect(() => {
        if (PackageData) {
            setData(prevData => ({
                ...prevData,
                packages_details: PackageData.packages_details || prevData.packages_details,
                data: {
                    ...prevData.data,
                    ...PackageData.data,
                },
            }));
        }
    }, [PackageData]);

    const handleSingle = (Data) => {
        dispatch(resetVendorState())
        navigate('/diabeties-single-detail', {
            state: {
                ...Data,
                package_id: selectedData?.package_id || "",
                assign_package_id: selectedData?.assign_package_id || ""
            }
        });
    };

    return (
        <div className="home-review-banner">
            {modalShow && <LoaderAnime showModal={modalShow} />}
            <div className="home-app-header-new sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <h6 style={{ marginLeft: '5px', textTransform: 'capitalize' }}>
                            {Data?.data?.vendor_name}
                        </h6>
                    </div>
                </div>
            </div>
            <div className="wrapper-clover-repo-task">
                <div className="wrapper-wellness-diabetes-cover">
                    <div className="repo-image-diabetes">
                        <img src={Data?.data?.vendor_banner} alt="" />
                    </div>
                </div>
                <div className="wrapper-wellness-loki-para">
                    <p>{Data?.data?.vendor_description}</p>
                </div>

                {Data?.packages_details?.length > 0 && (
                    <div className="packages-cover-wrapper">
                        <div className="options-wrapper">
                            <img src={packAnime_icon} alt="" />
                            <select
                                value={selectedData ? JSON.stringify(selectedData) : ''}
                                onChange={handleSelectPackage}
                            >
                                <option value="" disabled>Select Package</option>
                                {Data?.packages_details?.map((item) => (
                                    <option key={item?.id} value={JSON.stringify(item)}>
                                        {item?.package_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}

                <div className="repo-title-loki-book">
                    <h6>Select Your Plan</h6>
                </div>

                {Data?.data?.package_list?.map((item, index) => (
                    <div key={index} className="wrapper-package-vendor-loki" onClick={() => handleSingle(item)}>
                        <div className="wrapper-cover-ven-title">
                            <h5>{item?.package_name}</h5>
                        </div>
                        <div className="content-repo-ven-para ellipsis-text-eye">
                            <p>{item?.package_description}</p>
                        </div>
                        <div className="price-ven-repo">
                            <div className="price-coll">
                                <span>₹ {item?.package_mrp_price}</span>
                                <p>₹ {item?.package_medibhai_price}</p>
                            </div>
                            <button>VIEW DETAILS</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DiabetiesPackageDetail;
