import React, { useEffect, useState } from 'react'
import { headerLogo } from '../../images'
import { genderList, relationshipOPDList } from '../../data';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { validateNumberInput } from '../../utils/AllFunction';
import LoaderAnime from "../Medicine/animation/prescription_loader";
import Medicine_Success from '../Medicine/animation/Medicine_Success';
import useLocationState from '../Medicine/hooks/useLocationState';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';
import useRazorpay from 'react-razorpay';
import { fetchVendorPaymentData, resetVendorPaymentState } from '../../Redux/actions/VendorPaymentSlice';
import ToastBox from '../Medicine/animation/ToastBox';
import { fetchVendorCaptureData, resetVendorCaptureState } from '../../Redux/actions/VendorCaptureSlice';
import Reedem from '../Medicine/animation/Reedem';



const DiabetiesForm = () => {
    const [successmodal, setSuccessModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const [Razorpay] = useRazorpay();
    const dispatch = useDispatch();
    const Data = useLocationState();
    const opd_response = useSelector((state) => state?.vendorpayment);
    const capture_response = useSelector((state) => state?.vendorcapture);
    const UserData = JSON.parse(localStorage.getItem("UserData"))
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [successMessage, setSuccessMessage] = useState("")

    console.log(opd_response)

    function generateRandomString({ length, type, prefix = '', suffix = '' }) {
        const numbers = '0123456789';
        const alphabets = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const alphanumerics = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

        let characterPool;
        switch (type.toLowerCase()) {
            case 'number':
                characterPool = numbers;
                break;
            case 'alphabet':
                characterPool = alphabets;
                break;
            case 'alphanumeric':
                characterPool = alphanumerics;
                break;
            default:
                throw new Error('Invalid type. Use "number", "alphabet", or "alphanumeric".');
        }

        let randomString = Array.from({ length }, () =>
            characterPool.charAt(Math.floor(Math.random() * characterPool.length))
        ).join('');

        return `${prefix}${randomString}${suffix}`;
    }

    // Example usage
    const randomStr = generateRandomString({ length: 15, type: 'alphabet', prefix: 'ven_' });

    const onSubmit = async (data) => {
        setModalShow(true);

        // Create FormData instance
        const formData = new FormData();

        // Define your data object
        const onDataSubmit = {
            package_id: Data?.package_id,
            assign_package_id: Data?.assign_package_id,
            vendor_id: Data?.vendor_id,
            vendor_package_id: Data?.vendor_package_id,
            patient_name: data?.patient_name,
            patient_age: data?.patient_age,
            patient_age_type: data?.age_type,
            patient_gender: data?.gender,
            patient_relation: data?.relation,
            pincode: data?.pincode,
            city: data?.city,
        };

        // Append each key-value pair from onDataSubmit to the FormData instance
        Object.keys(onDataSubmit).forEach((key) => {
            formData.append(key, onDataSubmit[key]);
        });
        await dispatch(fetchVendorPaymentData({ formData }))

    };

    useEffect(() => {
        if (opd_response?.data?.success === true && opd_response?.data?.data?.has_payment === true) {
            setModalShow(false)
            const optionsData = opd_response?.data?.payment_data;

            const options = {
                key: optionsData?.key,
                amount: optionsData?.amount,
                currency: optionsData?.currency,
                name: optionsData?.name,
                description: optionsData?.description,
                order_id: optionsData?.razorpay_order_id,
                handler: async (response) => {
                    console.log("Payment successful", response);

                    if (response) {
                        const formData = new FormData();
                        formData.append("lenskart_order_id", optionsData?.lenskart_order_id);
                        formData.append("vendor_package_id", opd_response?.data?.data?.vendor_package_id);
                        formData.append("transaction_id", randomStr);
                        formData.append("has_payment", opd_response?.data?.data?.has_payment);

                        setModalShow(true);

                        try {
                            await dispatch(fetchVendorCaptureData({ formData }));
                            dispatch(resetVendorPaymentState())
                        } catch (error) {
                            console.log("Error in capturing order:", error);
                        } finally {
                            setModalShow(false);
                        }
                    }
                },

                prefill: {
                    name: optionsData?.prefill?.name,
                    email: optionsData?.prefill?.email,
                    contact: optionsData?.prefill?.contact,
                },
                theme: {
                    color: "#3399cc",
                },
                modal: {
                    ondismiss: function () {
                        dispatch(resetVendorPaymentState())
                    }
                }
                // callback_url:optionsData?.callback_url
            };

            const rzp1 = new Razorpay(options);
            rzp1.open();
        }
        else if (opd_response?.data?.success === true && opd_response?.data?.data?.has_payment === false) {
            setModalShow(true);
            const formData = new FormData();
            formData.append("lenskart_order_id", opd_response?.data?.data?.id);
            formData.append("vendor_package_id", opd_response?.data?.data?.vendor_package_id);
            formData.append("transaction_id", randomStr);
            formData.append("has_payment", opd_response?.data?.data?.has_payment);

            try {
                dispatch(fetchVendorCaptureData({ formData }));
                dispatch(resetVendorPaymentState())
            } catch (error) {
                console.log("Error in capturing order:", error);
            } finally {
                setModalShow(false);
            }
        }
        else if (opd_response?.data?.success === false || opd_response?.error?.status === false) {
            dispatch(resetVendorPaymentState())
            setModalShow(false)
            toast.error(opd_response?.data?.message || opd_response?.error?.message)
        }
    }, [opd_response])

    useEffect(() => {
        if (capture_response?.data?.success === true) {
            setSuccessModal(true)
            setSuccessMessage(capture_response?.data?.message)
            // dispatch(resetVendorCaptureState())
            // setTimeout(() => {
            //     setSuccessModal(false)
            //     navigate("/home")
            // }, 2500);
        }
    }, [capture_response])

    useEffect(() => {
        if (opd_response?.error !== null) {
            setModalShow(false)
            toast.error(opd_response?.error?.message)
        }
    }, [opd_response])


    return (
        <div className="home-review-banner">
            {modalShow && <LoaderAnime showModal={modalShow} />}
            <div className="home-app-header d-flex justify-content-start align-items-center sticky-top">

                <div className="header-logo mx-3">
                    <img
                        src={headerLogo}
                        className="header-medibhai-logo"
                        width="100%"
                        alt="header-logo"
                    />
                    <p className='opd-text'>{Data?.package_name}</p>
                </div>
            </div>
            <div className="opd-wrapper-cover-data mt-3">
                <div className="container">
                    {/* <div className="image-form-opd">
                        <img src={Data?.package_banner} alt="" />
                    </div> */}

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-data-repo-box">
                            <input type="text" value={UserData?.mobile_number} disabled />
                            <input type="text" value={UserData?.email_id} disabled />
                        </div>
                        <div className="form-page-data">
                            <div className="page-heading-text">
                                <p style={{ textAlign: 'start', fontSize: '17px' }}>Other Details</p>
                            </div>

                            <div className="form-page">
                                <input type="text" placeholder='Enter Patient Name' {...register('patient_name', { required: true })} />
                                {errors.patient_name && <span className='error-text-mssg'>This field is Required</span>}
                                <div className="option-data-page">
                                    <div className="gender-select-page">
                                        <select
                                            name="gender"
                                            {...register('gender', { required: true })}>
                                            <option value="" selected disabled>Select Gender</option>
                                            {genderList.map((data, index) => (
                                                <option key={index} value={data?.text}>{data?.text}</option>

                                            ))}
                                        </select>
                                        {errors.gender && <span className='error-text-mssg gen-text'>Gender is required</span>}
                                    </div>
                                    <div className="gender-select-page">
                                        <select
                                            name="relation"
                                            {...register('relation', { required: true })}>
                                            <option value="">Select Relation</option>
                                            {relationshipOPDList.map((data, index) => (
                                                <option key={index} value={data?.text}>{data?.text}</option>
                                            ))}
                                        </select>
                                        {errors.relation && <span className='error-text-mssg gen-text'>Relation is required</span>}
                                    </div>
                                </div>
                                <div className="option-data-page mb-0">
                                    <div className="gender-select-page">
                                        <input type="text" placeholder='Enter Patient Age' onInput={validateNumberInput} maxLength={3} {...register('patient_age', { required: true })} />
                                        {errors.patient_age && <span className='error-text-mssg'>age is required</span>}
                                    </div>
                                    <div className="gender-select-page">
                                        <select
                                            {...register("age_type", {
                                                required: "Age type is required",
                                            })}
                                            defaultValue="" // sets default selected value
                                        >
                                            <option value="" disabled>
                                                Select Age Type
                                            </option>
                                            <option value="days">Days</option>
                                            <option value="months">Month</option>
                                            <option value="years">Years</option>
                                        </select>

                                        <span className="error-text-mssg gen-text">
                                            {errors.age_type && errors.age_type.message}
                                        </span>
                                    </div>
                                </div>
                                <input
                                    placeholder="Enter Pincode"
                                    {...register("pincode", {
                                        required: "Pincode is required",
                                        pattern: {
                                            value: /^[0-9]{6}$/,
                                            message: "Invalid pincode"
                                        },
                                    })}
                                    maxLength={6}
                                    onInput={validateNumberInput}
                                />
                                {errors.pincode && <span className="error-text-mssg">{errors.pincode.message}</span>}
                                <input
                                    type="text"
                                    id="city"
                                    placeholder='Enter City'
                                    {...register("city", {
                                        required: "city is required",
                                    })}
                                />
                                {errors.city && <span className="error-text-mssg">{errors.city.message}</span>}
                            </div>
                        </div>

                        <div className="submit-btn-wrap mt-3">
                            <button>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastBox />
            {successmodal && <Reedem showModal={successmodal} setShowModal={setSuccessModal} message={successMessage} status={capture_response?.success} Data={capture_response} />}
        </div>
    )
}

export default DiabetiesForm;