import React, { useState } from 'react'
import { IoLocationSharp } from "react-icons/io5";
import { appOPDIcon } from '../../images';
import { DoctorEyeImage, inpersonsvg, online_consultation, onlinepersonsvg, person_consultation } from '../Medicine/Images';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

const DoctorCard = ({ doctor, hospitalId, hospitalAddress }) => {
    const navigate = useNavigate();
    const handleDetails = (Data) => {
        console.log(Data)
        const DataRepo = {
            doctor_id: Data?.doctor_id,
            departments: Data?.departments,
            hospitalID: Data?.hospitalDetails?.[0]?.hospital_id || hospitalId,
        }
        navigate("/doctor-detail", { state: DataRepo })
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const handleOpenModal = (departments) => {
        setModalData(departments);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setModalData(null);
    };

    return (
        <div className="doctor-card">
            <div className="doctor-info" onClick={() => handleDetails(doctor)}>
                <div className="doctor-rpo-cover">
                    <div className="doctor-repo-cover">
                        <div className="doctor-image">
                            <img src={DoctorEyeImage} alt="" />
                        </div>
                        <div className="doctor-header">
                            <h3 className="doctor-name">{doctor?.name || doctor?.doctor_name}</h3>
                            <p className="doc-qualification-repo" style={{ textAlign: 'start' }}>{doctor.education}</p>
                            <span className="admin-tag-loki"
                            >
                                {doctor?.departments?.[0]?.department_name}
                            </span>
                        </div>
                    </div>
                </div>
                <p className='repo-loki-address'>Address: <span>{doctor?.address ?? hospitalAddress}</span></p>
                <div className="doctor-buttons-repo">
                    {(doctor?.opd_price != null || doctor?.online_price != null) && (
                        <div className="repo-clover-price-data">
                            <div className="online">
                                <img src={onlinepersonsvg} alt="online-consultation" />
                            </div>
                            <div className="price-tag-doc">
                                Online
                                <p>₹ {doctor?.online_price || doctor?.opd_price}</p>
                            </div>
                        </div>
                    )}
                    {(doctor?.price != null || doctor?.in_person_price != null) && (
                        <div className="repo-clover-price-data">
                            <div className="online">
                                <img src={inpersonsvg} alt="in-person-consultation" />
                            </div>
                            <div className="price-tag-doc">
                                In-person
                                <p>₹ {doctor?.price || doctor?.in_person_price}</p>
                            </div>
                        </div>
                    )}
                </div>

                {doctor?.in_person_price === null && doctor?.online_price === null ? (
                    <div className="book-apointment-btn">
                        <button>
                            Enquiry Now
                        </button>
                    </div>
                ) : (
                    <div className="book-apointment-btn">
                        <button>
                            BOOK APPOINTMENT
                        </button>
                    </div>

                )}


            </div>
            {isModalOpen && (
                <Modal show={isModalOpen} onHide={handleCloseModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Departments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul>
                            {modalData?.map((department, index) => (
                                <li key={index}>{department.department_name}</li>
                            ))}
                        </ul>
                    </Modal.Body>
                </Modal>
            )}
        </div>
    )
}

export default DoctorCard