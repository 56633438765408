import React, { useEffect, useState } from "react";
import AppHeader from "../../Medicine/Components/Medicine_Header";
import useLocationState from "../../Medicine/hooks/useLocationState";
import { useDispatch, useSelector } from "react-redux";
import { getConsultationDetails } from "../../../Redux/actions/GetApiCall";
import {
  utilizeTeleconsultAmount,
  capture_teleconsult_payment_details,
} from "../../../Redux/actions/PostApiCall";
import MedicinePopup from "../../Medicine/animation/Medicine_Success";
import AppLoader from "../../Medicine/animation/prescription_loader";
import { useLocation, useNavigate } from "react-router";
import useRazorpay from "react-razorpay";
import WaitLoader from "../../../Animation/WaitLoader";
import { savings_image } from "../../Medicine/Images";
import toast from "react-hot-toast";
import ToastBox from "../../Medicine/animation/ToastBox";
import SharModal from "../../Medicine/Components/SharModal";

const OrderConsultation = () => {
  const dispatch = useDispatch();
  const order_id = useLocationState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();

  const [showShare, setShowShare] = useState(false);
  const handleShareShow = () => setShowShare(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const autoParamId = queryParams.get("id");
  const storedPackageData = JSON.parse(
    localStorage.getItem("PackageSelectData")
  );
  const AutoToken = localStorage.getItem("AUTO_TOKEN");
  const AppSimulated = localStorage.getItem("is_simulated");

  const consultation_details_response = useSelector(
    (state) => state.get_consultation_details_response
  );
  const utilize_amount_response = useSelector(
    (state) => state.utilize_amount_response
  );

  const capture_payment_response = useSelector(
    (state) => state.capture_teleconsult_payment_details_response
  );

  useEffect(() => {
    if (order_id || autoParamId) {
      dispatch(getConsultationDetails(order_id?.order_id || autoParamId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      capture_payment_response?.error !== null &&
      capture_payment_response?.status === "failure"
    ) {
      setShowModal(false);
      toast.error(capture_payment_response?.error);
    }
  }, [capture_payment_response]);

  const handleConsultOrder = () => {
    const data = {
      booked_id: consultation_details_response?.data?.data?.id,
      package_id: storedPackageData?.id
        ? storedPackageData.id
        : order_id?.pack_id
        ? order_id?.pack_id
        : "",
    };
    dispatch(utilizeTeleconsultAmount(data));
  };

  useEffect(() => {
    if (utilize_amount_response?.loader) {
      setShowModal(true);
    } else if (utilize_amount_response?.status === "failure") {
      setShowModal(false);
      toast.error(utilize_amount_response?.error);
    }
    if (
      utilize_amount_response?.data?.razorpay_details?.length === 0 &&
      utilize_amount_response?.status === "success"
    ) {
      setShowModal(false);
      setShowSuccessModal(true);
      localStorage.setItem(
        "TEL_ORDER_ID",
        consultation_details_response?.data?.data?.id
      );
      const data = {
        orderId: order_id?.order_id || autoParamId,
        tabKey: "third",
        AutoData: autoParamId && autoParamId,
      };
      setTimeout(() => {
        if (AutoToken) {
          navigate(
            `/thank-you?id=${
              autoParamId || order_id?.order_id
            }&auto=${AutoToken}`,
            { state: data }
          );
        } else {
          navigate("/thank-you", { state: data });
        }
      }, 100);
    } else if (
      utilize_amount_response?.data?.razorpay_details &&
      utilize_amount_response?.status === "success" &&
      utilize_amount_response?.data?.is_payable === 1
    ) {
      const options = {
        ...utilize_amount_response.data.razorpay_details,
        order_id:
          utilize_amount_response.data.razorpay_details?.razorpay_order_id,
        handler: function async(response) {
          dispatch(
            capture_teleconsult_payment_details({
              transaction_id: response.razorpay_payment_id,
              teleconsult_order_id:
                consultation_details_response?.data?.data?.order_details?.id,
            })
          );
        },
        modal: {
          ondismiss: function () {
            setShowModal(false);
            toast.error("Payment was cancelled.");
          },
        },
      };
      const rzp1 = new Razorpay(options);
      rzp1.open();
    }
  }, [utilize_amount_response]);

  useEffect(() => {
    if (capture_payment_response?.status === "success") {
      setShowModal(false);
      setShowSuccessModal(true);
      localStorage.setItem(
        "TEL_ORDER_ID",
        consultation_details_response?.data?.data?.id
      );
      const data = {
        orderId: order_id?.order_id || autoParamId,
        tabKey: "third",
        AutoData: autoParamId && autoParamId,
      };
      setTimeout(() => {
        if (AutoToken) {
          navigate(
            `/thank-you?id=${
              autoParamId || order_id?.order_id
            }&auto=${AutoToken}`,
            { state: data }
          );
        } else {
          navigate("/thank-you", { state: data });
        }
      }, 100);
    }
  }, [capture_payment_response]);

  const autoParam = queryParams.get("auto");

  useEffect(() => {
    const storedToken = localStorage.getItem("AUTO_TOKEN");
    const tokenTimestamp = localStorage.getItem("AUTO_TOKEN_TIMESTAMP");

    const THREE_HOURS = 3 * 60 * 60 * 1000;
    const currentTime = new Date().getTime();

    // Handle reload logic
    // if (localStorage.getItem("reload_triggered") === "true") {
    //     window.history.pushState({}, "", window.location.pathname);
    //     localStorage.removeItem("reload_triggered");
    //     return;
    // }

    // Set token and pincode if conditions are met, and store the current timestamp
    if (autoParam && storedToken !== autoParam) {
      localStorage.setItem("AUTO_TOKEN", autoParam);
      localStorage.setItem("AUTO_TOKEN_TIMESTAMP", currentTime.toString()); // Save timestamp
      window.location.reload();
    }
    // If there's an AUTO_TOKEN and timestamp, check if it has expired (3 hours passed)
    if (
      storedToken &&
      tokenTimestamp &&
      currentTime - tokenTimestamp > THREE_HOURS
    ) {
      // Clear specific token-related items instead of clearing the whole localStorage
      localStorage.removeItem("AUTO_TOKEN");
      localStorage.removeItem("AUTO_TOKEN_TIMESTAMP");
    }
  }, [autoParam, location.search]);

  useEffect(() => {
    if (
      AutoToken &&
      AutoToken !== null &&
      consultation_details_response?.data?.data?.order_status ===
        "New Consultation"
    ) {
      navigate(
        `/thank-you?id=${autoParamId || order_id?.order_id}&auto=${AutoToken}`
      );
    } else if (
      consultation_details_response?.data?.data?.order_status ===
        "New Consultation" &&
      consultation_details_response?.data?.data?.order_status !==
        "Payment Pending"
    ) {
      navigate("/teleconsultation");
      window.location.reload();
    }
  }, [AutoToken, consultation_details_response]);

  // Check for failure status and return early
  if (consultation_details_response?.error !== null) {
    return (
      <div className="home-review-banner">
        <AppHeader Title="Book Appointment" />
        <div className="error-message repo-cont-repo-con">
          <h2>Error</h2>
          <p>
            {consultation_details_response?.error ||
              "An unexpected error occurred."}
          </p>
        </div>
      </div>
    );
  }


  return (
    <div className="home-main-cover-wrapper">
      <div className="home-app-wrapper">
        <div className="home-app-main-cover">
          <div className="home-review-banner mb-4">
            {showModal && <AppLoader showModal={showModal} />}
            <AppHeader Title="Book Appointment" />
            {consultation_details_response?.loader ? (
              <WaitLoader />
            ) : (
              <>
                <div className="consultation-preview-card-wrapper mb-4">
                  <div className="consultation-card">
                    {(consultation_details_response?.data?.data?.symptom ||
                      consultation_details_response?.data?.data
                        ?.departments) && (
                      <div className="symptoms-preview-card-box d-flex align-items-center">
                        <div className="symptoms-preview-icon-box">
                          <img
                            src={
                              consultation_details_response?.data?.data?.symptom
                                ?.file_name ||
                              consultation_details_response?.data?.data
                                ?.departments?.file_name
                            }
                          ></img>
                        </div>
                        <h6>
                          {consultation_details_response?.data?.data?.symptom
                            ?.name ||
                            consultation_details_response?.data?.data
                              ?.departments?.name}
                        </h6>
                      </div>
                    )}

                    <h6 className="consultation-card-title">Patient Details</h6>
                    <p className="consultation-patient-name">
                      Patient Name :{" "}
                      <span>
                        {
                          consultation_details_response?.data?.data
                            ?.patient_name
                        }
                      </span>
                    </p>
                    <div className="row">
                      <div className="col-6">
                        <div className="preview-card-detail">
                          <p>
                            Age :{" "}
                            <span>
                              {consultation_details_response?.data?.data?.age}{" "}
                              Years
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="preview-card-detail">
                          <p>
                            Gender :{" "}
                            <span>
                              {
                                consultation_details_response?.data?.data
                                  ?.gender
                              }
                            </span>
                          </p>
                        </div>
                      </div>
                      <hr className="consult-review"></hr>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="preview-card-detail">
                          <h6>Appointment Date</h6>
                          <p>
                            {
                              consultation_details_response?.data?.data
                                ?.appointment_date
                            }
                          </p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="preview-card-detail">
                          <h6>Appointment Time</h6>
                          <p>
                            {
                              consultation_details_response?.data?.data
                                ?.appointment_time
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {autoParamId === null &&
                    AppSimulated !== null &&
                    AppSimulated === "1" && (
                      <div className="share-box" onClick={handleShareShow}>
                        <div className="content-share-repo">
                          <h6>Share Your Appointment</h6>
                          <p>Let employee know about this appointment!</p>
                        </div>
                        <button aria-label="Share Your Order">
                          Click here
                        </button>
                      </div>
                    )}

                  {showShare && (
                    <SharModal
                      setShowShare={setShowShare}
                      showShare={showShare}
                      Data={consultation_details_response?.data}
                    />
                  )}

                  <div className="payment-repo-cover-box mx-2">
                    <div className="payment-text-repo">
                      <p>Payment Summary</p>
                    </div>
                    <div className="payment-summary-box-repo">
                      {consultation_details_response?.data?.payment_summary &&
                        consultation_details_response?.data?.payment_summary
                          .filter(
                            (lab_cal_Data) =>
                              lab_cal_Data.key !== "Total Payable Price" &&
                              lab_cal_Data.key !== "Total Savings"
                          )
                          .map((filteredData) => (
                            <div
                              className="price-payment-sum-repo"
                              key={filteredData.key}
                            >
                              <p>{filteredData.key}</p>
                              <p>
                                {new Intl.NumberFormat("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                }).format(filteredData.value)}
                              </p>
                            </div>
                          ))}
                    </div>
                    <div className="fin-text-repo">
                      {consultation_details_response?.data?.payment_summary &&
                        consultation_details_response?.data?.payment_summary
                          .filter(
                            (lab_cal_Data) =>
                              lab_cal_Data.key === "Total Payable Price" ||
                              lab_cal_Data.key === "Total Savings"
                          )
                          .map((filteredData) => (
                            <div
                              className={`price-payment-sum-repo ${
                                filteredData.key === "Total Savings"
                                  ? "text-success price-payment-sum-repo saving-data-p"
                                  : ""
                              }`}
                              key={filteredData.key}
                            >
                              <p>
                                <img
                                  src={savings_image}
                                  alt=""
                                  style={{ marginRight: "5px" }}
                                />
                                {filteredData.key}
                              </p>
                              <p>
                                {new Intl.NumberFormat("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                }).format(filteredData.value)}
                              </p>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
                {AppSimulated && AppSimulated !== "null" ? (
                  <></>
                ) : (
                  <div className="footer-repo-head-boxs sticky-lg-bottom sticky-md-bottom sticky-bottom pb-2">
                    <div className="product-price-repo-store">
                      {consultation_details_response?.data?.data?.order_details
                        ?.net_amount <= 0.0 ? (
                        <div class="free-card">
                          <h5>Free</h5>
                        </div>
                      ) : (
                        <p>
                          Total{" "}
                          <span>
                            ₹{" "}
                            {
                              consultation_details_response?.data?.data
                                ?.order_details?.net_amount
                            }
                          </span>
                        </p>
                      )}
                    </div>
                    <div className="product-btn-box-store">
                      <button
                        className="repo-cont-btn"
                        onClick={handleConsultOrder}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showSuccessModal && (
        <MedicinePopup
          showSuccessModal={showSuccessModal}
          setShowSuccessModal={setShowSuccessModal}
          message="Consultation booked successfully!"
          image={savings_image}
        />
      )}
      <ToastBox />
    </div>
  );
};

export default OrderConsultation;
