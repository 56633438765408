import { useState, useEffect } from "react";

const originalSetItem = localStorage.setItem;
localStorage.setItem = function (key, value) {
    originalSetItem.apply(this, arguments); // Call original function
    window.dispatchEvent(new Event("localStorageUpdated")); // Dispatch event
};

// Custom hook to manage `PakageData`
const usePackageData = () => {
    const [packageData, setPackageData] = useState(() => {
        return JSON.parse(localStorage.getItem("PakageData")) || null;
    });

    // Listen for `localStorageUpdated` event
    useEffect(() => {
        const handleStorageChange = () => {
            const newPackageData = JSON.parse(localStorage.getItem("PakageData")) || null;
            setPackageData(newPackageData);
        };

        window.addEventListener("localStorageUpdated", handleStorageChange);

        return () => {
            window.removeEventListener("localStorageUpdated", handleStorageChange);
        };
    }, []);

    return [packageData, setPackageData];
};

export default usePackageData;
