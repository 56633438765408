import React, { useContext, useState, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { patientsUser } from "../../../images";
import Loader from "../../../Animation/Loader";
import AppHeader from "../../Medicine/Components/Medicine_Header";
import PatientCardDetails from "../components/PatientCard";
import PatientDetailsModal from "../components/PatientModal";
import { useNavigate } from "react-router";
import {
  fetchPatients,
  fetchDiagnosticCartDetails,
} from "../../../Redux/actions/GetApiCall";
import { savePatientMembers } from "../../../Redux/actions/PostApiCall";
import { useDispatch, useSelector } from "react-redux";
import AppModalLoader from "../../Medicine/animation/prescription_loader";
import ToastBox from "../../Medicine/animation/ToastBox";


const Patients = () => {
  const savePatientResponse = useSelector(
    (state) => state?.save_patient_members
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientCheckedData, setPatientCheckedData] = useState({
    data: [],
  });
  const [modalMode, setModalMode] = useState("add");
  const Title = "Select Patients";
  const dispatch = useDispatch();
  const cartDetailsResponse = useSelector(
    (state) => state.diagnostic_cart_details
  );
  const navigate = useNavigate();
  const patientsList = useSelector((state) => state.patient_members_list);
  const delete_patient_response = useSelector((state) => state.delete_patient_members);
  const cartDetailsList = useSelector(
    (state) => state?.diagnostic_cart_details
  );
 

  useEffect(() => {
    dispatch(fetchPatients());
    dispatch(fetchDiagnosticCartDetails());
  }, [dispatch]);

  // useEffect(() => {
  //   if (savePatientResponse?.data && savePatientResponse?.status === "success") {
  //     toast.success(savePatientResponse.data.message);
  //   }

  // }, [savePatientResponse]);


  const handleEditPatient = (patientData) => {
    setSelectedPatient(patientData);
    setModalMode("edit");
    setShowModal(true);
  };

  const handleAddPatient = () => {
    navigate("/family-member")
    // setSelectedPatient(null);
    // setModalMode("add");
    // setShowModal(true);
  };

  const handleContinue = async () =>{
    await dispatch(savePatientMembers(patientCheckedData));
    const data = {
      patientCount : patientCheckedData?.data?.length,
      tabkey : "diagnostics"
    }
    setTimeout(() =>{
      navigate('/address-list' , {state : data});
    },200)
  }


  return (
    <>
      <div className="home-review-banner">
        <AppHeader Title={Title}  />
        <AppModalLoader showModal={savePatientResponse?.loader || delete_patient_response?.loader} />
        <div className="patients-main-wrapper-cover">
          <>
            <div className="patient-main-box">
              <img src={patientsUser} width="100%" alt="review" />
              <p>You can add upto 6 patients details</p>
            </div>
            <>
              {Array.isArray(patientsList?.data?.response) &&
                patientsList?.data?.response.length !== 6 && (
                  <div class="patient-add-button d-flex justify-content-center">
                    <button
                      className=""
                      type="button"
                      onClick={() => handleAddPatient()}
                    >
                      <p>ADD MEMBER</p>
                    </button>
                  </div>
                )}
              <PatientDetailsModal
                setShowModal={setShowModal}
                showModal={showModal}
                modalMode={modalMode}
                selectedPatient={selectedPatient}
                patientsList={patientsList}
              />
            </>
            {patientsList?.loader || cartDetailsList?.loader ? (
              <Loader />
            ) : (
              <div className="patient-detail-main-box-cover">
                <PatientCardDetails
                  setPatientCheckedData={setPatientCheckedData}
                  cartDetailsList={cartDetailsList}
                  patientsList={patientsList}
                  handleEditPatient={handleEditPatient}
                  patientCheckedData={patientCheckedData}
                 
                />
              </div>
            )}
          </>
        </div>
        {savePatientResponse?.data && savePatientResponse?.status === "success" && (
          <ToastBox/>
        )}
        {cartDetailsResponse &&
          patientCheckedData?.data?.length > 0 &&
          patientCheckedData.data.every(
            (item) => item.test_id?.length > 0 || item.package_id?.length > 0
          ) && (
            <div className="go-to-cart-main-page-wrapper">
              <div className="cart-count-box-cover">
                  <div className="cart-count-text">
                    <p>
                      <span>{patientCheckedData?.data?.length || 0}</span> Patient(s)
                      Selected
                    </p>
                  </div>
                  <div className="go-cart-btn">
                    <button
                      className="patient-btn"
                      onClick={() =>handleContinue()}
                    >
                      Continue
                    </button>
                  </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default Patients;
