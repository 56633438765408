import React, { useEffect, useState } from 'react'
import { appNotification, appPlayButton, headerLogo } from '../../images'
import { json, NavLink, useNavigate } from 'react-router-dom'
import '../styles/homeStyle.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { WellnessBenefits, WellnessHomeSlideModules } from '../../data'
import { useFetchHome } from '../../QueryClient/MedicineQuery'
import MediLoader from '../../Pages/Medicine/animation/MediLoader'
import { MdArrowRightAlt } from 'react-icons/md'
import NoDataFoundComp from '../../components/NoDataFound'
import { IMAGE_HEALTH_URL } from '../../Constants'
import { no_preview } from '../../Pages/Medicine/Images'
import { cart } from '../images'
import { useDispatch, useSelector } from 'react-redux'
import LoaderAnime from '../../Pages/Medicine/animation/prescription_loader'
import { fetchvendor } from '../../Redux/actions/PostApiCall'

const NewHome = () => {
    const navigate = useNavigate()
    const {
        data: HomeData,
        error: medicineQueryError,
        isLoading: isLoadingHome,
    } = useFetchHome();
    const dispatch = useDispatch();
    const { data: VendorData, loader, error } = useSelector(state => state.vendor_api)
    const [modalShow, setModalShow] = useState(false);
    console.log(VendorData)

    useEffect(() => {
        if (HomeData) {
            const data = {
                name: HomeData?.profile_details?.name,
                mobile_number: HomeData?.profile_details?.mobile_number,
                email_id: HomeData?.profile_details?.email_id
            }
            localStorage.setItem("UserData", JSON.stringify(data))
        }
    }, [HomeData])

    const AllPackages = (Data) => {
        // console.log(Data)
        navigate('/all-packages', { state: Data })
    }
    const handlePackageDetails = (data) => {
        const PackData = { id: data?.id, name: data?.name };
        localStorage.setItem("PakageData", JSON.stringify(PackData));
        navigate('/packagedetailbox', { state: data?.id })
    }

    const handleVideoAll = () => {
        const VideoData = {
            videoData: HomeData?.videos
        }

        navigate("/videobox", { state: VideoData })
    }

    const handleSingleVideo = (item) => {
        navigate('/videodetailbox', { state: item })
    }

    const handleBlogDetails = (item) => {
        navigate('/blogdetail', { state: item })
    }

    const handleSlider = (item) => {
        switch (item.name) {
            case "OPD":
                navigate('/doctor-consultation');
                break;

            case "Call a Doctor":
                navigate('/teleconsultation');
                break;

            case "Lab Test":
                navigate('/diagnostictest');
                break;
            case "Medicines":
                navigate('/buyMedicines');
                break;
            case "Hospitals":
                navigate('/hospital-consultation');
                break;

            default:
                console.warn(`No route defined for: ${item.name}`);
                break;
        }
    };


    // const handleWellness = (item) => {
    //     console.log(item)
    //     switch (item?.name) {
    //         case "Dental\nCare":
    //             navigate('/dental')
    //             break;
    //         case "Eye\nCare":
    //             navigate('/eye')
    //             break;
    //         case "Ambulance\nService":
    //             navigate('/ambulanceassistance')
    //             break;
    //         case "Diabetes\nCare":
    //             navigate('/common-wellness', { state: "diabetes" })
    //             break;
    //         default:
    //             break;
    //     }
    // }
    const AppToken = localStorage.getItem("APP_TOKEN");


    const handleOceanType = async (item) => {
        console.log(item);

        if (item?.name === "Ambulance\nService") {
            navigate('/ambulanceassistance'); // Directly navigate without API call or modal
            return;
        }

        setModalShow(true);
        const formData = new FormData();
        let navigatePath = '';
        let title = '';
        let type = ''; // Changed from 'Type' to 'type' for consistency

        switch (item?.name) {
            case "Dental\nCare":
                formData.append("type", "ocean_dental");
                navigatePath = "/dental";
                title = "Dental Care";
                type = "ocean_dental";
                break;
            case "Eye\nCare":
                formData.append("type", "ocean_eye");
                navigatePath = "/eye";
                title = "Eye Care";
                type = "ocean_eye";
                break;
            case "Diabetes\nCare":
                formData.append("type", "ocean_diabetes");
                navigatePath = "/common-wellness?diabetes=1";
                title = "Diabetes Care";
                type = "ocean_diabetes";
                break;
            case "Maternity\nCare":
                formData.append("type", "ocean_maternity");
                navigatePath = "/maternity";
                title = "Maternity Care";
                type = "ocean_maternity";
                break;
            case "Elder\nCare":
                formData.append("type", "ocean_elders");
                navigatePath = "/elder";
                title = "Elder Care";
                type = "ocean_elders";
                break;
            case "Nutrition\nCare":
                formData.append("type", "ocean_nutrition");
                navigatePath = "/common-wellness?nutrition=1";
                title = "Nutrition Care";
                type = "ocean_nutrition";
                break;
            case "Mental\nCare":
                formData.append("type", "ocean_mental");
                navigatePath = "/mental";
                title = "Mental Care";
                type = "ocean_mental";
                break;

            default:
                console.log("No matching case found");
                return;
        }

        // Replace with your API endpoint
        const apiEndpoint = `${process.env.REACT_APP_NEW_APP_API_URL}/vendor/vendor-details`;

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                body: formData,
                headers: {
                    "Authorization": AppToken
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data) {
                setModalShow(false);
                const OceanData = {
                    data: data,
                    title: title,
                    Type: type
                }
                if (data?.is_form === 1) {
                    navigate("/ocean-form", { state: OceanData });
                } else {
                    navigate(navigatePath, { state: OceanData });
                }
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }
    };

    const handleBannerSlide = (data) => {
        console.log(data)

        if (data?.redirect_id === "33") {
            return
        }

        switch (data?.redirect_page) {
            case "medicine":
                navigate("/buyMedicines")
                break;

            case "diagnostic":
                navigate("/diagnostictest")
                break;

            default:
                break;
        }
    }


    return (
        <div className="home-review-banner">
            <div className="home-app-header-new sticky-top">
                {modalShow && <LoaderAnime showModal={modalShow} />}
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        {HomeData?.profile_details?.name && (
                            <p> Welcome {HomeData?.profile_details?.name}</p>
                        )}
                    </div>
                </div>
                <div className="icons-box">
                    <div className="notification-icon">
                        <NavLink to="/cart">
                            <img src={cart} width="100%" alt="notification" />
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className="home-app-slider" style={{ paddingTop: '10px' }}>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={4.6}
                >
                    <div className="swiper-wrapper">
                        {WellnessHomeSlideModules.map((modulesData) =>
                            modulesData?.show_hide === 0 ? null : (
                                <SwiperSlide
                                    className="applabtest-repo"
                                    key={modulesData.name}
                                >
                                    <button className="btn-slider-repo-new" onClick={() => handleSlider(modulesData)}>
                                        <div className="slider-img-repo-new">
                                            <img
                                                src={modulesData.image}
                                                width="100%"
                                                alt="labtest"
                                            />
                                        </div>
                                        <p>{modulesData.name === "home-teleconsultation" ? modulesData.name = "Teleconsultation" : modulesData.name}</p>
                                    </button>
                                </SwiperSlide>
                            )
                        )}
                    </div>
                </Swiper>
            </div>

            <div className="home-package-slider">
                {HomeData && HomeData?.active_package.length > 0 && (
                    <div className="header-package-text-clover">
                        <h6>Packages</h6>
                        {HomeData?.active_package.length > 1 && (
                            <p onClick={() => AllPackages(HomeData?.active_package)}>View All</p>
                        )}
                    </div>
                )}
                <Swiper spaceBetween={10} slidesPerView={1} autoplay={{ delay: 2000, disableoninteraction: false }}
                    modules={[Autoplay]}
                    loop="true">
                    {isLoadingHome === true ? (
                        <MediLoader />
                    ) :
                        HomeData && HomeData?.active_package.length > 0 ? (
                            HomeData?.active_package.map((packageBox, inde) => (
                                <>
                                    <SwiperSlide key={packageBox.id} className="package-repo-data">
                                        <div
                                            onClick={() => handlePackageDetails(packageBox)}
                                            className="home-section-banner"

                                        >
                                            <img src={packageBox?.banner} alt="" />
                                            {packageBox?.package_text_visibility === 0 && (
                                                <div className="container">
                                                    <div className="home-section-main-banner">
                                                        <div className="row">
                                                            <div className="col-lg-8 col-8">
                                                                <div className="banner-left-side">
                                                                    <div className="left-image-box">
                                                                        <p dangerouslySetInnerHTML={{ __html: packageBox?.description }}></p>
                                                                    </div>
                                                                    <div className="konw-more-btn">
                                                                        <a href="#">
                                                                            <p>
                                                                                Know more <MdArrowRightAlt />
                                                                            </p>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-4 pe-0">
                                                                <div className="banner-right-side">
                                                                    <div className="package-title">
                                                                        <p>{packageBox?.name}</p>
                                                                    </div>
                                                                    <div className="package-date">
                                                                        <p>
                                                                            package Valid Till{" "}
                                                                            <span>{packageBox?.expiring_on}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </SwiperSlide>
                                </>
                            ))
                        ) : (
                            <>

                            </>)}
                </Swiper>
            </div>

            <div className="repo-welless-benefit-cover-new">
                <div className="head-wellnes-benfit">
                    <h6>Health Ocean</h6>
                </div>
                <div className="wellness-benefit-repo-new-cover">
                    {WellnessBenefits.map((item, index) => (
                        <div className="wellness-boxs-new-form" key={index} onClick={() => handleOceanType(item)}>
                            <img src={item?.image} alt="" />
                            <p>{item?.name}</p>
                        </div>
                    ))}
                </div>
            </div>

            {
                isLoadingHome === true ? (
                    <p>
                        <MediLoader />
                    </p>
                ) : HomeData?.home_slider.length > 0 ? (
                    <div className="dental-wrapper-new">
                        <Swiper
                            spaceBetween={5}
                            slidesPerView={1.1}
                            loop="true"
                            autoplay={{ delay: 1800, disableoninteraction: false }}
                            modules={[Autoplay]}
                        >
                            <div className="swiper-wrapper">
                                {HomeData?.home_slider?.map((bannerslide) => (
                                    <SwiperSlide key={bannerslide.id} className="bannersliderimg-new">
                                        <div className="denatl-box-new" onClick={() => handleBannerSlide(bannerslide)}>
                                            <img
                                                src={bannerslide.slider_image}
                                                width="100%"
                                                alt="app-banner-img"
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </div>
                        </Swiper>
                    </div>
                ) : (
                    <NoDataFoundComp />
                )}

            <div className="videos-wrapper">
                <div className="video-box">
                    <div className="health-heading">
                        <h5>Videos</h5>
                    </div>
                    <div className="view-all" onClick={handleVideoAll}>
                        <p>View All</p>
                    </div>
                </div>
                {isLoadingHome === true ? (
                    <p>
                        <MediLoader />
                    </p>
                ) : HomeData?.videos && HomeData?.videos.length > 0 ? (
                    <div className="video-box-cover">
                        <Swiper spaceBetween={10} slidesPerView={1.8}>
                            <div className="swiper-wrapper">
                                {HomeData?.videos &&
                                    HomeData?.videos.map((videoData, ind) => (
                                        <SwiperSlide className="video-slider-repo">
                                            <div className="video-content-box" onClick={() => handleSingleVideo(videoData)}>
                                                <div className="video-time">
                                                    <p>{videoData.video_time}</p>
                                                </div>
                                                <div
                                                    className="video-image"
                                                    style={{
                                                        backgroundImage: `url(${videoData.icon})`,
                                                    }}
                                                >
                                                    <div className="video-play">
                                                        <img
                                                            src={appPlayButton}
                                                            width="100%"
                                                            alt="play-button"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="video-text">
                                                    <p>{videoData?.title}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                            </div>
                        </Swiper>
                    </div>
                ) : (
                    <NoDataFoundComp />
                )}
            </div>


            <div className="blog-wrapper">
                <h3>Latest Blog</h3>
                <div className="blog-main-cover">
                    <Swiper spaceBetween={10} slidesPerView={1.1} loop="true">
                        <div className="swiper-wrapper">
                            {isLoadingHome === true ? (
                                <p>
                                    <MediLoader />
                                </p>
                            ) : HomeData && HomeData?.health_feed.length > 0 ? (
                                <div className="blog-wrapper">
                                    <h3>Health Corner</h3>
                                    <div className="blog-main-cover">
                                        <Swiper spaceBetween={10} slidesPerView={1.1} loop="true">
                                            <div className="swiper-wrapper">
                                                {HomeData?.health_feed &&
                                                    HomeData?.health_feed.map((blogData, index) => (
                                                        <SwiperSlide className="blogslide-repo">
                                                            <div className="blog-content-box" onClick={() => handleBlogDetails(blogData)}>
                                                                <div className="blog-image-box">
                                                                    <img src={blogData?.feature_image ? `${IMAGE_HEALTH_URL}${blogData?.feature_image
                                                                        }` : no_preview}
                                                                        onError={(e) => {
                                                                            e.target.onerror = null;
                                                                            e.target.src = no_preview;
                                                                        }}
                                                                        width="100%"
                                                                        alt="product-icon" />
                                                                </div>
                                                                <div className="blog-heading-box blog-subject-text">
                                                                    <p>{blogData.subject}</p>
                                                                </div>
                                                                <div className="blog-text-box short-desc-repo">
                                                                    <p>{blogData.short_description}</p>
                                                                </div>
                                                                <div className="blog-post">
                                                                    <p>
                                                                        Posted on{" "}
                                                                        {
                                                                            new Date(blogData.created_on)
                                                                                .toISOString()
                                                                                .split("T")[0]
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>

                                                        </SwiperSlide>
                                                    ))}
                                            </div>
                                        </Swiper>
                                    </div>
                                </div>
                            ) : (
                                <NoDataFoundComp />
                            )}
                        </div>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default NewHome