import React, { useEffect, useRef, useState } from 'react'
import { headerLogo } from '../images'
import { ban1 } from '../Pages/Medicine/Images'
import '../Styles/BuyPackage.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPackageDetailApi } from '../Redux/actions/GetApiCall'
import OTPInput from 'react-otp-input'
import { capture_user_creation, capture_user_package, generate_otp, verify_login_otp, verify_package_creation_otp, verify_package_otp } from '../Redux/actions/PostApiCall'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import { DEFAULT_CAPTCHA_KEY } from '../Constants'
import toast from 'react-hot-toast'
import ToastBox from '../Pages/Medicine/animation/ToastBox'
import useRazorpay from 'react-razorpay'
import PresciptionLoader from '../Pages/Medicine/animation/prescription_loader'
import Medicine_Success from '../Pages/Medicine/animation/Medicine_Success'
import { useNavigate } from 'react-router'
import MediLoader from '../Pages/Medicine/animation/MediLoader'

const BuyPackage = () => {
    const dispatch = useDispatch();
    const [otp, setOtp] = useState("");
    const handleChange = (code) => setOtp(code);
    const recaptchaRef = useRef(null);
    const [successModal, setSuccessModal] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [canResend, setCanResend] = useState(false);
    const [otpGenerated, setOtpGenerated] = useState(false);
    const [timer, setTimer] = useState(5);
    const [orderData, setOrderData] = useState([])
    const [Razorpay] = useRazorpay();
    const { data, loader, error } = useSelector(state => state.package_details);
    const { data: verfiyData, loader: verfiyLoader, error: verifyError } = useSelector(state => state.package_creation_verify_response);
    const generate_otp_response = useSelector(
        (state) => state?.generate_otp_response
    );

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm();
    const captchaToken = watch("g-recaptcha-response");
    const onCaptchaChange = (value) => {
        setValue("g-recaptcha-response", value);
    };
    const packageData = data?.data

    useEffect(() => {
        const id = 384
        dispatch(fetchPackageDetailApi(id))
    }, [])
    const handleGenerateOtp = () => {
        const data = {
            mobile_number: packageData?.user_data?.mobile_number,
            "g-recaptcha-response": captchaToken,
            template: 'login'
        };

        dispatch(generate_otp(data))
            .then(() => setOtpGenerated(true)) // If using async actions with promises
            .catch(() => setOtpGenerated(false));
    };


    const handleResendOTP = () => {
        setTimer(5);
        setCanResend(false);
        setOtpGenerated(false)
    };

    useEffect(() => {
        if (otpGenerated === true) {
            toast.success(generate_otp_response?.data?.message)
        }
    }, [otpGenerated])

    useEffect(() => {
        if (generate_otp_response?.data?.success !== true || generate_otp_response?.error !== null) {
            if (recaptchaRef?.current) {
                recaptchaRef.current.reset();
            }
        }
    }, [generate_otp_response])
    useEffect(() => {
        if (timer === 0) {
            setCanResend(true);
        }
    }, [timer]);

    useEffect(() => {
        // Start the countdown when OTP is visible
        if (otpGenerated === true && timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval); // Cleanup interval on unmount
        }
    }, [otpGenerated, timer]);

    const handleVerifyOtp = async (event) => {
        event.preventDefault();
        const Data = {
            package_code: packageData?.package_details?.code,
            source: 0,
            otp: otp,
            mobile_number: packageData?.user_data?.mobile_number,
            name: packageData?.user_data?.name,
            email_id: packageData?.user_data?.email_id
        };
        try {
            await dispatch(verify_package_creation_otp(Data));

        } catch (error) {
            console.error("OTP verification failed:", error);
            toast.error("Failed to verify OTP. Please try again.");
        }
    };

    useEffect(() => {
        if (verfiyData?.success === true) {
            const options = {
                ...verfiyData?.razorpay_details,
                order_id: verfiyData?.razorpay_details?.razorpay_order_id,
                handler: async function (response) {
                    setShowModal(true);
                    try {
                        const requestData = {
                            source: 0,
                            package_code: packageData?.package_details?.code,
                            mobile_number: packageData?.user_data?.mobile_number,
                            name: packageData?.user_data?.name,
                            email_id: packageData?.user_data?.email_id,
                            transaction_id: response.razorpay_payment_id,
                            order_id: response?.order_id,
                        };

                        // ✅ Call API using fetch
                        const apiResponse = await fetch(`${process.env.REACT_APP_NEW_APP_API_URL}/package/capture-user-package-creation`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(requestData),
                        });

                        const result = await apiResponse.json();
                        setOrderData(result)

                        if (apiResponse.ok) {
                            setShowModal(false)
                            setSuccessModal(true)
                            setTimeout(() => {
                                setSuccessModal(false)
                                navigate('/home')
                            }, 2000);
                        } else {
                            console.error("Error capturing user:", result);
                        }
                    } catch (error) {
                        console.error("Error capturing user:", error);
                    }
                },
            };

            const rzp1 = new Razorpay(options);
            rzp1.open();
        }
    }, [verfiyData]);

    const height = '75vh'

    return (
        <div className="home-review-banner">
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <h6>Buy Package</h6>
                    </div>
                </div>
            </div>
            {showModal && <PresciptionLoader showModal={showModal} />}
            {loader ? (
                <MediLoader height={height} />
            ) : (
                <div className="repo-buy-now-repo-loki">
                    <div className="package-image-repo-buy">
                        <img src={packageData?.package_details?.banner} alt="" />
                    </div>
                    <div className="price-pack-loki-repo">
                        <div className="repo-clone-loki">
                            <p>Package Price:</p>
                        </div>
                        <div className="repo-clone-text">
                            <p>{packageData?.package_details?.selling_price}</p>
                            {packageData?.package_details?.selling_price !== packageData?.package_details?.mrp && (
                                <span>{packageData?.package_details?.mrp}</span>
                            )}
                        </div>

                    </div>
                    <div className="package-details-loki">
                        <p>{packageData?.package_details?.description}</p>
                    </div>

                    {packageData?.info_data?.length > 0 && (
                        <div className="repo-benefit-loki-buy">
                            <div className="title-loki-repo">
                                <h6>Benefits</h6>
                            </div>
                            {packageData?.info_data?.map((item, index) => (
                                <div className="repo-card-drive-loki">
                                    <div className="repo-image-iron">
                                        <img src={item?.icon} alt="" />
                                    </div>
                                    <div className="repo-package-cover-loki">
                                        <div className="repo-line-loki-text">{item?.title}</div>
                                        {/* <div className='repo-line-loki'></div> */}
                                    </div>
                                    <div className="repo-text-desc-para">
                                        <p>{item?.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {(otpGenerated === false || generate_otp_response?.data?.success !== true || generate_otp_response?.error !== null) && (
                        <>
                            <div className="recaptch-cover-wrapper mt-2 mb-2" style={{ display: 'flex', justifyContent: 'center' }}>
                                <ReCAPTCHA
                                    sitekey={DEFAULT_CAPTCHA_KEY}
                                    onChange={onCaptchaChange}
                                    ref={recaptchaRef}
                                />
                                <input
                                    type="hidden"
                                    {...register("g-recaptcha-response", {
                                        required: "reCAPTCHA is required",
                                    })}
                                />
                                {errors["g-recaptcha-response"] && (
                                    <span className="auth-error-msg">
                                        {errors["g-recaptcha-response"].message}
                                    </span>
                                )}
                            </div>
                            <div className="genrate-otp-btn" onClick={handleGenerateOtp}>
                                <button disabled={generate_otp_response?.loader}>
                                    {generate_otp_response?.loader ? "GENERATING..." : "GENERATE OTP"}
                                </button>
                            </div>

                        </>
                    )}

                    {otpGenerated === true && (
                        <>
                            <div className="repo-otp-clover-loki">
                                <OTPInput
                                    value={otp}
                                    onChange={handleChange}
                                    numInputs={6}
                                    renderInput={(props) => (
                                        <input
                                            {...props}
                                            className="otp-input"
                                        />
                                    )}
                                    shouldAutoFocus
                                    inputType="number"
                                    inputStyle={{
                                        width: "40px",
                                        height: "40px",
                                        fontSize: "18px",
                                        textAlign: "center",
                                        margin: "5px",
                                        border: "1px solid #ccc",
                                        borderRadius: "8px",
                                    }}
                                />
                            </div>
                            <div className="otp-timer">
                                {timer > 0 ? (
                                    <p>Please wait {timer} seconds to request a new OTP.</p>
                                ) : (
                                    <button
                                        onClick={handleResendOTP}
                                        disabled={!canResend} // Disable until timer reaches 0
                                    >
                                        Resend OTP
                                    </button>
                                )}
                            </div>
                            <div className="verify-otp-repo-loki">
                                <button onClick={handleVerifyOtp} disabled={verfiyLoader}>{verfiyLoader ? "Verifying..." : "VERIFY"}</button>
                            </div>
                        </>
                    )}
                </div>
            )}
            {orderData && <Medicine_Success showModal={successModal} message={orderData?.message} status={orderData?.success} />}
            <ToastBox />
        </div>
    )
}

export default BuyPackage