import { createApiAction } from "./apiActions";
import {
  SEARCH_DIAGNOSTIC_TEST_API,
  HEALTH_PACKAGE_API,
  CATEGORY_TEST_API,
  DIAGNOSTIC_CART_DETAILS_API,
  DIAGNOSTIC_TEST_API,
  PATIENT_LIST_API,
  GET_TIME_SLOTS_API,
} from "../../Constants";

const API_BASE_URL = `${process.env.REACT_APP_NEW_APP_API_URL}`;
// const API_BASE_URL = "https://beta.medibhai.com/api";
const pincode = localStorage.getItem("pincode");

// For Employee Auto Login
export const getQueryParams = (url) => {
  const urlObj = new URL(url, window.location.origin);
  const queryParams = new URLSearchParams(urlObj.search);
  return {
    auto: queryParams.get("auto"),
    id: queryParams.get("id"),
    agentcode: queryParams.get("agentcode"),
  };
};

const currentUrl = window.location.href; // Get the current URL
const { auto } = getQueryParams(currentUrl);
const AutoDiago = auto ? "employee-auto-login" : "diagnostic";

const fetchApi = (endpoint, actionType) => {
  const AppToken = localStorage.getItem("APP_TOKEN");
  const AutoToken = localStorage.getItem("AUTO_TOKEN");
  const Package = JSON.parse(localStorage.getItem("PakageData"));
  console.log(Package);
  return createApiAction(actionType, () =>
    fetch(`${API_BASE_URL}/${endpoint}`, {
      method: "GET",
      headers: {
        Authorization: auto ? AutoToken : AppToken,
        PackageId: Package?.id,
      },
    })
  );
};

// Use the value as needed for medicine
const AutoApi = auto ? "employee-auto-login" : "medicine";
const AutoApiPack = auto ? "employee-auto-login" : "package";

// Use the value as needed for teleconsultation
const AutoTele = auto ? "employee-auto-login" : "teleconsultation";

export const fetchMedicine = () => {
  return fetchApi("medicine/medicine-category-details", "FETCH_MEDICINE");
};

export const fetchHomeApi = () => fetchApi("employee-home-data", "FETCH_HOME");

export const fetchPackageDetailApi = (id) =>
  fetchApi(`package/package-details?package_id=${id}`, "FETCH_PACKAGE_DETAIL");

export const fetchTeleconsultationList = () =>
  fetchApi("teleconsultation/consultations", "FETCH_TELECONSULTATION_LIST");

export const fetchTeleconsultationListDetails = (orderId) =>
  fetchApi(
    `${AutoTele}/get-consultation-details/${orderId}`,
    "FETCH_TELECONSULTATION_LIST_DETAILS"
  );

export const fetchThankyouData = (orderId) =>
  fetchApi(
    `medicine/payment-summary?medicine_order_id=${orderId}`,
    "FETCH_THANK_YOU"
  );

export const fetchAutoThankyouData = (orderId) =>
  fetchApi(
    `employee-auto-login/payment-summary?medicine_order_id=${orderId}`,
    "FETCH_AUTO_THANK_YOU"
  );

export const fetchorderhistory = (orderId) => {
  if (orderId) {
    return fetchApi(
      `medicine/order-history?order_id=${orderId}`,
      "FETCH_HISTORY"
    );
  } else {
    return fetchApi(`medicine/order-history?order_id=`, "FETCH_HISTORY");
  }
};

export const fetchLabOrderList = (orderId) => {
  if (orderId) {
    return fetchApi(`diagnostic/order-list/${orderId}`, "FETCH_LAB_ORDER");
  } else {
    return fetchApi(`diagnostic/order-list`, "FETCH_LAB_ORDER");
  }
};

export const fetchRadiologyOrderList = (orderId) => {
  if (orderId) {
    return fetchApi(`radiology/order-list/${orderId}`, "FETCH_RADIOLOGY_ORDER");
  } else {
    return fetchApi(`radiology/order-list`, "FETCH_RADIOLOGY_ORDER");
  }
};

export const fetchLabOrderDetails = (orderId) => {
  if (orderId) {
    return fetchApi(
      `${AutoDiago}/order-details/${orderId}`,
      "FETCH_LAB_ORDER_LIST"
    );
  }
};

export const fetchRadiologyOrderDetails = (orderId) => {
  if (orderId) {
    return fetchApi(
      `radiology/order-details/${orderId}`,
      "FETCH_RADIOLOGY_LIST"
    );
  }
};

export const fetchTrackOrder = (order_id, meg_id) =>
  fetchApi(
    `medicine/order-details/?medicine_order_id=${order_id}&onemg_order_id=${meg_id}`,
    "FETCH_TRACK_ORDER"
  );

export const fetchMedicinelist = (medicineName, simulated) => {
  const IsSimulated = simulated ? simulated : "";
  const encodedMedicineName = encodeURIComponent(medicineName);
  return fetchApi(
    `medicine/medicine-list?pincode=${pincode}&medicine_name=${encodedMedicineName}&is_simulated=${IsSimulated}`,
    "FETCH_MEDICINE_LIST"
  );
};

export const fetchPincode = (Citypincode) =>
  fetchApi(`medicine/city/serviceable?pincode=${Citypincode}`, "FETCH_PINCODE");

export const fetchMedicineAddress = () =>
  fetchApi("medicine/user-address-list", "FETCH_MEDICINE_ADDRESS");

export const fetchSingleMedicinelist = (id, type) =>
  fetchApi(
    `medicine/medicine-details?sku_id=${id}&type=${type}&pincode=${pincode}`,
    "FETCH_SINGLE_MEDICINE_LIST"
  );

export const fetchMedicineRecent = (pageNo) =>
  fetchApi(
    `medicine/previous-ordered-list?pincode=${pincode}&page=${pageNo}`,
    "FETCH_MEDICINE_RECENT_LIST"
  );

export const fetchMedicineCartList = (wallet_id) =>
  fetchApi(
    `${AutoApi}/medicine-cart-details?pincode=${pincode}&wallet_id=${
      wallet_id || ""
    }`,
    "FETCH_MEDICINE_CART_LIST"
  );

export const fetchPrescriptionList = () =>
  fetchApi("medicine/uploaded-prescriptions-list", "FETCH_PRESCRIPTION_LIST");

export const fetchOfferList = () =>
  fetchApi("medicine/banners-list", "FETCH_OFFER_LIST");

export const fetchMedicineOrderList = () =>
  fetchApi(`${AutoApi}/place-order-page-details`, "FETCH_MEDICINE_ORDER_LIST");

// DIAGNOSTIC API STARTS HERE

export const fetchDiagnosticTestList = (testQuery) =>
  fetchApi(
    `${SEARCH_DIAGNOSTIC_TEST_API}?query=${testQuery}`,
    "SEARCH_DIAGNOSTIC_TEST"
  );

export const fetchCategoriesTestList = (id) => {
  const CatId = id ? id : "";
  return fetchApi(`${CATEGORY_TEST_API}/${CatId}`, "GET_CATEGORIES_TEST");
};

export const fetchDiagnosticCartDetails = () =>
  fetchApi(`${DIAGNOSTIC_CART_DETAILS_API}`, "DIAGNOSTIC_CART_DETAILS");

export const getDiagnosticTestList = () =>
  fetchApi(`${DIAGNOSTIC_TEST_API}`, "DIAGNOSTIC_TEST_LIST");

export const getTestDescription = (id) =>
  fetchApi(`${DIAGNOSTIC_TEST_API}/${id}`, "TEST_DESCRIPTION");

export const getpackageDescription = (id) =>
  fetchApi(`${HEALTH_PACKAGE_API}/${id}`, "PACKAGE_DESCRIPTION");

export const getDiagnosticPackageList = () =>
  fetchApi(`${HEALTH_PACKAGE_API}`, "DIAGNOSTIC_PACKAGE_LIST");

export const getRadiologyPackageList = () =>
  fetchApi(`radiology/get-packages`, "RADIOLOGY_PACKAGE_LIST");

export const fetchPatients = () =>
  fetchApi(`${PATIENT_LIST_API}`, "PATIENT_MEMBERS_LIST");

export const fetchTimeSlot = () =>
  fetchApi(`${GET_TIME_SLOTS_API}`, "GET_TIME_SLOTS");

export const fetchPackageList = () =>
  fetchApi(`${AutoApiPack}/my-package-list`, "FETCH_PACKAGE_LIST");

export const fetchDiagnosticPrescriptionList = () =>
  fetchApi(
    "diagnostic/uploaded-prescriptions-list",
    "FETCH_DIAGNOSTIC_PRESCRIPTION_LIST"
  );

export const fetchRadiologyPrescriptionList = () =>
  fetchApi(
    "radiology/uploaded-prescriptions-list",
    "FETCH_RADIOLOGY_PRESCRIPTION_LIST"
  );

export const fetchLabReports = () =>
  fetchApi(`diagnostic/users-all-reports`, "FETCH_LAB_REPORTS");

// ======================= TELECONSULTATION ==================

export const fetchDepartmentsList = () =>
  fetchApi(`teleconsultation/departments`, "FETCH_DEPARTMENT_LIST");

export const fetchSymptomsList = () =>
  fetchApi(`teleconsultation/symptoms`, "FETCH_SYMPTOMS_LIST");

export const fetchConsultTimeSlot = () =>
  fetchApi(`teleconsultation/symptoms`, "FETCH_SYMPTOMS_LIST");

export const getConsultationDetails = (order_id) =>
  fetchApi(
    `${AutoTele}/get-consultation-details/${order_id}`,
    "CONSULTATION_DETAILS"
  );

export const getWalletUtilization = (package_id, wallet_id) =>
  fetchApi(
    `package/wallet-transaction-history?package_id=${package_id}&wallet_id=${wallet_id}`,
    "WALLET_UTILIZATION_DETAILS"
  );

export const fetchHealthRecords = () =>
  fetchApi(`teleconsultation/health-record`, "FETCH_HEALTH_RECORDS");

export const getNeedHelpData = () => fetchApi(`faq-master`, "NEED_HELP");

export const fetchReimbursementStatusData = () =>
  fetchApi(`fetch-reimbursement-status`, "REIMBURSEMENT_DATA");

export const fetchReimbursementFormData = (packType) =>
  fetchApi(
    `fetch-reimbursement-details?reimbursement_type=${packType}`,
    "REIMBURSEMENT_FORM_DATA"
  );

export const fetchBannerList = () =>
  fetchApi(`teleconsultation/banners`, "TELECONSULTATION_BANNERS_LIST");

//MY PROFILE ----------------------------

export const getPrivacy = () =>
  fetchApi(`privacy-policy`, "PRIVACY_POLICY_DATA");

export const getTerms = () =>
  fetchApi(`terms-condition`, "TERMS_CONDITION_DATA");

// =================== Employee Auto Login =================

// export const fetchAutoMedicineCartList = (package_id, wallet_id) =>
//   fetchApi(
//     `employee-auto-login/medicine-cart-details?pincode=${pincode}&package_id=${
//       package_id || ""
//     }&wallet_id=${wallet_id || ""}`,
//     "FETCH_AUTO_MEDICINE_CART_LIST"
//   );

// export const fetchAutoMedicineOrderList = () =>
//   fetchApi(
//     "employee-auto-login/place-order-page-details",
//     "FETCH_AUTO_MEDICINE_ORDER_LIST"
//   );

// export const fetchAutoPackageList = () =>
//   fetchApi("employee-auto-login/my-package-list", "FETCH_AUTO_PACKAGE_LIST");

// ==================== Radiology Apis =======================
export const fetchRadioApi = (id) => {
  const url = id ? `radiology/get-tests/${id}` : "radiology/get-tests";
  return fetchApi(url, "FETCH_HOME_RADIO");
};

export const fetchRadioCategoriesApi = (id) =>
  fetchApi(`radiology/get-categories/${id}`, "FETCH_RADIO_CATEGORIES");

export const fetchRadioPackagesApi = (id) =>
  fetchApi(`radiology/get-packages/${id}`, "FETCH_RADIO_PACKAGES");

export const getRadioPackagesDescription = (id) =>
  fetchApi(`radiology/get-packages/${id}`, "RADIO_PACKAGE_DESCRIPTION");

export const fetchRadiologyTestList = (testQuery) =>
  fetchApi(
    `radiology/diagnostic-search?query=${testQuery}`,
    "SEARCH_RADIOLOGY_TEST"
  );

export const fetchRadioCartDetails = () =>
  fetchApi(`radiology/cart-details`, "RADIO_CART_DETAILS");

export const getRadioTestList = (id) =>
  fetchApi(`radiology/get-tests/${id}`, "RADIO_TEST_LIST");

export const fetchPatientsRadio = () =>
  fetchApi(`radiology/fetch-members`, "RADIO_PATIENT_MEMBERS_LIST");

export const fetchTimeSlotRadio = () =>
  fetchApi(`radiology/fetch-time-slot`, "RADIO_GET_TIME_SLOTS");

export const fetchStateRadio = () =>
  fetchApi(`radiology/get-state/`, "RADIO_STATE");

export const fetchCenterRadio = (id) =>
  fetchApi(`radiology/get-center-test/${id}`, "RADIO_CENTER");

// Hospitals APIS
export const fetchHospitalCategoriesList = () =>
  fetchApi(`opdconsultation/categories/list`, "HOSPITAL_CATEGORIES_LIST");

export const fetchHospitalList = (category_id, params) => {
  const catId = category_id ? category_id : "";
  const parmasId = params ? params : "";
  return fetchApi(
    `opdconsultation/hospitals?category_id=${catId}&search=${parmasId}`,
    "HOSPITAL_LIST"
  );
};

export const fetchHospitalServices = () =>
  fetchApi(`opdconsultation/services`, "HOSPITAL_SERVICES");

export const fetchRecentDoctors = () =>
  fetchApi(`opdconsultation/recent-doctors`, "RECENT_DOCTORS");

export const fetchRecentHospitals = () =>
  fetchApi(`opdconsultation/recent-hospital`, "RECENT_HOSPITALS");

export const fetchDoctorsBanners = () =>
  fetchApi(`opdconsultation/banners`, "DOCTORS_Banners");

export const fetchDentalPartners = () =>
  fetchApi(`dental/partner`, "DENTAL_PARTNERS");

export const fetchEyePartners = () => fetchApi(`eye/partner`, "EYE_PARTNERS");

export const fetchHospitalDetails = (id) =>
  fetchApi(`opdconsultation/hospitals/details/${id}`, "HOSPITAL_DETAILS");

export const fetchDoctorDetails = (id, departId, hospitalId) => {
  const DepartmentId = departId ? departId : "";
  const hospital_id = hospitalId ? hospitalId : "";
  return fetchApi(
    `opdconsultation/doctor/details/${id}?department_id=${DepartmentId}&hospital_id=${hospital_id}`,
    "DOCTOR_DETAILS"
  );
};

export const fetchDoctorOrderList = (id) =>
  fetchApi(
    `opdconsultation/opdconsultations-list?page=${id}`,
    "DOCTOR_ORDER_LIST"
  );

// Doctor's Apis
export const fetchDoctorList = (id, params, page_no) => {
  // Set default values if id or params are not provided
  const departmentId = id ? id : ""; // If id is not provided, default to an empty string
  const searchParams = params ? params : ""; // If params is not provided, default to an empty string
  const page = page_no ? page_no : "";

  return fetchApi(
    `opdconsultation/doctor/list?department_id=${departmentId}&search=${searchParams}&page=${page}`,
    "DOCTOR_LIST"
  );
};

export const fetchDoctorOrderDetail = (docId, walletId) => {
  // Set default values if id or params are not provided
  const WalletId = walletId ? walletId : ""; // If params is not provided, default to an empty string

  return fetchApi(
    `opdconsultation/get-opdconsultation-details/${docId}?wallet_id=${WalletId}`,
    "DOCTOR_ORDER_DETAIL"
  );
};

export const fetchDentalOrderDetail = (docId, walletId) => {
  // Set default values if id or params are not provided
  const WalletId = walletId ? walletId : ""; // If params is not provided, default to an empty string

  return fetchApi(
    `dental/get-dental-details/${docId}?wallet_id=${WalletId}`,
    "DENTAL_ORDER_DETAIL"
  );
};

export const fetchDentalPartnersDetails = (id) =>
  fetchApi(`dental/partner/${id}`, "DENTAL_PARTNERS_DETAILS");

export const fetchDentalOrderList = (id) =>
  fetchApi(`dental/dental-list`, "DENTAL_ORDER_LIST");

export const fetchEyeOrderDetail = (docId, walletId) => {
  // Set default values if id or params are not provided
  const WalletId = walletId ? walletId : ""; // If params is not provided, default to an empty string

  return fetchApi(
    `eye/get-eye-details/${docId}?wallet_id=${WalletId}`,
    "EYE_ORDER_DETAIL"
  );
};

export const fetchEyePartnersDetails = (id) =>
  fetchApi(`eye/partner/${id}`, "EYE_PARTNERS_DETAILS");

export const fetchEyeOrderList = (id) =>
  fetchApi(`eye/eye-list`, "EYE_ORDER_LIST");

export const fetchOPDDepartmentsList = (search) =>
  fetchApi(
    `opdconsultation/departments${search ? `?search=${search}` : ""}`,
    "FETCH_OPD_DEPARTMENT_LIST"
  );
