import React from 'react'
import { no_preview, pdf_icon, savings_image } from '../../../Medicine/Images';

const PaymentSummaryDetails = ({ TrackSummaryData, TeleKey }) => {

    const calculationCharges = TrackSummaryData?.vas_charges?.details || TrackSummaryData?.calculation || TrackSummaryData?.payment_summary;
    const userAddress = TrackSummaryData?.address || TrackSummaryData?.user_details;

    return (
        <div>
            <div className="payment-repo-cover-box shipment-order-summary">
                <div className="payment-text-repo">
                    <p>Payment Summary</p>
                </div>

                <div className="payment-summary-box-repo">
                    {calculationCharges?.map((item, index) => {
                        const excludedKeys = ["MB Wallet", "Total Savings", "Total saving"]; // Keys to skip
                        const isExcluded = excludedKeys.includes(item.key);
                        const hasValidAmount = item.amount !== null && item.amount !== undefined;
                        const hasValidValue = item.value !== null && item.value !== undefined;

                        if (!isExcluded && (hasValidAmount || hasValidValue)) {
                            return (
                                <div className="price-payment-sum-repo repo-total" key={index}>
                                    <p>{item.display_text || item?.key}</p>
                                    <p>₹ {(item.amount ?? item.value)?.toLocaleString('en-IN')}</p>
                                </div>
                            );
                        }
                        return null;
                    })}



                    {TrackSummaryData?.amount_paid && TrackSummaryData.amount_paid.some(item => item?.amount > 0) && (
                        <div className="price-payment-sum-repo">
                            <p className="total-repo-class">Total Payable</p>
                            {TrackSummaryData.amount_paid.map((item, index) => (
                                item?.amount && (
                                    <p key={index} className="total-repo-class">
                                        ₹ {item?.amount?.toLocaleString('en-IN')}
                                    </p>
                                )
                            ))}
                        </div>
                    )}


                </div>
                <div className="payment-mode-data repo-mode-text">
                    {(TrackSummaryData?.payment_mode?.length > 0 ||
                        TrackSummaryData?.upi_deducted?.length > 0 ||
                        TrackSummaryData?.wallet_deducted?.length > 0) && (
                            <div className="payment-text-repo">
                                <p>Payment Mode</p>
                            </div>
                        )}

                    <div className="payment-data-cover">
                        {TrackSummaryData?.upi_deducted?.map((item, index) => (
                            item.amount !== 0 && (
                                <div className="price-payment-sum-repo repo-total" key={index}>
                                    <p>{item.display_text}</p>
                                    <p>₹ {item.amount?.toLocaleString('en-IN')}</p>
                                </div>
                            )
                        ))}
                    </div>

                    <div className="payment-data-cover">
                        {TrackSummaryData?.wallet_deducted?.map((item, index) => (
                            item.amount !== 0 && (
                                <div className="price-payment-sum-repo repo-total" key={index}>
                                    <p>{item.display_text}</p>
                                    <p>₹ {item.amount?.toLocaleString('en-IN')}</p>
                                </div>
                            )
                        ))}
                    </div>

                    <div className="payment-data-cover">
                        {TrackSummaryData?.payment_mode?.map((item, index) => (
                            item.value !== 0 && ( // Condition to check if item value is not zero
                                <div className="price-payment-sum-repo repo-total" key={index}>
                                    <p>{item.key}</p>
                                    <p>₹ {item.value?.toLocaleString('en-IN')}</p>
                                </div>
                            )
                        ))}
                    </div>


                </div>
            </div>

            {(TrackSummaryData?.total_saving > 0 || calculationCharges?.some(item => item.key === "Total Savings" && item.value > 0)) && (
                <div className="totoal-savings-data-repo">
                    <div className="price-payment-sum-repo saving-data-p">
                        <p><img src={savings_image} alt="" /> Total Savings</p>
                        <p>
                            ₹ {(TrackSummaryData?.total_saving > 0
                                ? TrackSummaryData?.total_saving
                                : calculationCharges?.find(item => item.key === "Total Savings")?.value
                            )?.toLocaleString('en-IN')}
                        </p>
                    </div>
                </div>
            )}


            {TeleKey !== 1 && (
                <div className="shipment-order-cover-info">
                    <div className="order-info-head">
                        <p>Order Information</p>
                    </div>

                    <div className="user-data-repo-box">
                        <p>Name: {userAddress?.name}</p>
                        <p>Mobile Number: {userAddress?.mobile_no || userAddress?.mobile_number}</p>
                        {userAddress?.address && (
                            <p>Address: {userAddress?.address},{userAddress?.landmark}, {userAddress?.pincode}, {userAddress?.city}, {userAddress?.state}</p>
                        )}

                        {TrackSummaryData?.prescription?.length > 0 && (
                            <>
                                <hr />

                                <div className="presciption-head-repo-text">
                                    <div className="order-info-head prscrption">
                                        <p>Prescription</p>
                                    </div>

                                    <div className="data-presc-wrapper-box">
                                        {TrackSummaryData?.prescription?.map((item, index) => (
                                            <div className="prescipion-image-box-repo-data" key={index}>
                                                {item?.file_url?.endsWith(".pdf") ? (
                                                    <a href={item.file_url} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={pdf_icon} // Use a placeholder image for PDF files
                                                            alt="PDF File"
                                                        />
                                                    </a>
                                                ) : (
                                                    <a href={item.file_url} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={item.file_url}
                                                            alt="Prescription Image"
                                                        />
                                                    </a>
                                                )}
                                            </div>
                                        ))}
                                    </div>

                                </div>

                            </>
                        )}
                    </div>
                </div>
            )}

        </div>
    )
}

export default PaymentSummaryDetails