import React, { useEffect } from 'react'
import { headerLogo, needHelp, teleBanner1, teleBanner2 } from '../../images'
import { FiSearch } from 'react-icons/fi';
import '../Doctor/doctorhome.css'
import { ban1, ban2, HospitalBannerImage, hospitalIcon, hospitalIconbhai } from '../Medicine/Images';
import { DoctorData, HospitalCategories, HospitalData, HospitalFaq } from '../../data';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router';
import { Accordion } from 'react-bootstrap';
import DoctorCard from '../Doctor/DoctorCard';
import RecentHospitalCard from './RecentHospitalCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHospitalCategoriesList, fetchHospitalList, fetchRecentHospitals } from '../../Redux/actions/GetApiCall';
import MediLoader from '../Medicine/animation/MediLoader';
import CommonPackage from '../MyProfile/Components/CommonPackage';

const HospitalHome = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const hospitalData = useSelector(state => state.hospital_list)
    const hospitalloader = useSelector(state => state.hospital_list.loader)
    const hospitalCategoriesData = useSelector(state => state.hospital_categories_list)
    const hospitalCategoriesloader = useSelector(state => state.hospital_categories_list.loader)
    const { loader: HospitalRecentloader, data: HospitalRecentData, } = useSelector(state => state.hospital_recent_list);
    const handleDoctor = () => {
        navigate("/hospitals", { state: HospitalData })
    }

    useEffect(() => {
        dispatch(fetchHospitalList())
        dispatch(fetchHospitalCategoriesList())
        dispatch(fetchRecentHospitals())
    }, [])

    const handleHospitalDetails = (item) => {
        navigate('/hospital-details', { state: item })
    }

    const handleSearch = () => {
        navigate("/search-hospital")
    }

    const handleCategory = (item) => {
        navigate('/hospital-category', { state: item })
    }

    const handleFormRepo = () => {
        navigate("/opd-form")
    }

    const height = "150px"

    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="tele-data-wrapper">
                    <div className="header-logo tele-logo mr-3">
                        <img
                            src={headerLogo}
                            className="header-medibhai-logo"
                            width="100%"
                            alt="header-logo"
                        />
                    </div>
                    <h6 className="mb-0">Hospitals</h6>
                </div>
                <div className="home-header-teleconsultation d-flex justify-content-end align-items-center" style={{ gap: '75px' }}>
                    <div className="header-navigation-icon head-repo-bor">
                        <CommonPackage />
                    </div>
                </div>
            </div>

            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search hospitals..."
                    onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s]*$/;  // Regular expression to allow letters and spaces only
                        if (!regex.test(e.key)) {
                            e.preventDefault(); // Prevent non-letter characters
                        }
                    }}
                    onClick={handleSearch}
                />
                <FiSearch />

            </div>


            <div className="hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <p>Categories</p>
                    {/* <button>View All</button> */}
                </div>
                {hospitalCategoriesloader ? (
                    <MediLoader height={height} />
                ) : (
                    <div className="hospital-categories-wrapper">
                        <Swiper
                            spaceBetween={15}
                            slidesPerView={3.2}
                            loop={true}
                            // modules={[Autoplay]}
                            // autoplay={{
                            //     delay: 3000, // Adjust the delay for autoplay
                            //     disableOnInteraction: false, // Allows autoplay to continue after user interactions
                            // }}
                            speed={600}
                            className='pb-2'
                        >
                            {hospitalCategoriesData?.data?.data?.map((item, index) => (
                                <SwiperSlide key={index}

                                >
                                    <div
                                        className="category-data-cover"
                                        // style={{ backgroundColor: item.bgColor }}
                                        style={{ backgroundImage: `url(${item.file_name || 'default-image-url'})`, backgroundSize: "cover" }}
                                        onClick={() => handleCategory(item)}
                                    >
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                )}

            </div>


            <div className="hospital-wrapper-box">
                <div className="hospital-text-head">
                    <p>Hospitals</p>
                    <button onClick={handleDoctor}>View All</button>
                </div>
                {hospitalloader ? (
                    <MediLoader height={height} />
                ) : (
                    <div className="hospital-cover-repo-box">
                        {hospitalData?.data?.data?.slice(0, 2)?.map((item, index) => (
                            <div className="hospital-card-repo" key={index} onClick={() => handleHospitalDetails(item)}>
                                <div className="hospital-card-image-loki" style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                                    <img src={item?.icon ? `${item?.icon}` : hospitalIconbhai}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = hospitalIconbhai;
                                        }} alt="hospital-image" />
                                    {item?.name && (
                                        <p>
                                            <span>{item?.name}</span>
                                        </p>
                                    )}
                                </div>
                                <div className="hospital-data-wrap" style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: '2px' }}>
                                    {item?.city && (
                                        <p style={{ color: 'black', fontSize: '12px' }}>
                                            City: <span>{item?.city}</span>
                                        </p>
                                    )}
                                    {item?.doctors?.length > 0 && (
                                        <p style={{ color: 'black', fontSize: '12px' }}>
                                            Doctors: <span>{item?.doctors.length}</span>
                                        </p>
                                    )}
                                </div>

                                <div className="hospital-data-wrap-loki" style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    {item?.speciality_type && (
                                        <p style={{ color: 'black', fontSize: '12px', marginBottom: '0px' }}>
                                            Speciality: <span>{item?.speciality_type}</span>
                                        </p>
                                    )}
                                    {item?.number_of_beds && (
                                        <p style={{ color: 'black', fontSize: '12px', marginBottom: '0px' }}>
                                            No.of beds: <span>{item?.number_of_beds}</span>
                                        </p>
                                    )}
                                </div>

                                {/* <div className="ratings-hospital">
                                <p><FaStar /> <span>{item?.ratings}</span></p>
                            </div> */}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="Hospital-banner-repo-clover hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '6px' }}>
                    <p>Banners</p>
                    {/* <button>View All</button> */}
                </div>

                <div className="data-box-image-presc" onClick={handleFormRepo} style={{ cursor: 'pointer' }}>
                    <img src={HospitalBannerImage} alt="prescription-banner" />
                </div>
            </div>

            {HospitalRecentloader ? (
                <MediLoader height={height} />
            ) : (
                HospitalRecentData?.data?.length > 0 && (
                    <div className="hospital-wrapper-box">
                        <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                            <p>Recent Hospital</p>
                            {/* <button>View All</button> */}
                        </div>
                        <div className="hospital-categories-wrapper">
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={1.3}
                                loop={true}
                                modules={[Autoplay]}
                                // autoplay={{
                                //     delay: 2500,
                                //     disableOnInteraction: false,
                                // }}
                                speed={600}
                                className='pb-2'
                            >
                                {HospitalRecentData?.data?.map((item, index) => (
                                    <SwiperSlide key={index}
                                    >
                                        <RecentHospitalCard item={item} index={index} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>

                    </div>
                )
            )
            }



            {/* <div className="hospital-faq-department-data hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <div className="need-help-banner p-0">
                        <img src={needHelp} alt="need help"></img>
                    </div>
                </div>

                <div className="need-help-accordion-main-cover-wrapper">
                    <Accordion defaultActiveKey="0" className='faq-box-clover'>
                        {HospitalFaq?.map((helpData, ind) => {
                            return (
                                <Accordion.Item eventKey={ind} key={ind}>
                                    <Accordion.Header>
                                        {helpData?.question}
                                    </Accordion.Header>
                                    <Accordion.Body>{helpData?.answer}</Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>
                </div>
            </div> */}
        </div >
    )
}

export default HospitalHome