import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios"; // Ensure axios is imported
import { getFormAuthHeader } from "../../utils/TokenUtils";

// Async thunk to fetch fuel data
const AppToken = localStorage.getItem("APP_TOKEN");

export const fetchFamilyFormData = createAsyncThunk(
  "familyform/fetchFamilyForm",
  async ({ formData }, { rejectWithValue }) => {
    // Remove unnecessary destructuring
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NEW_APP_API_URL}/family-member`,
        formData,
        { headers: getFormAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch family data"
      );
    }
  }
);

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const FamilyFormSlice = createSlice({
  name: "familyform",
  initialState,
  reducers: {
    resetFamilyFormState(state) {
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFamilyFormData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFamilyFormData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchFamilyFormData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetFamilyFormState } = FamilyFormSlice.actions;
export default FamilyFormSlice.reducer;
