import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios"; // Ensure axios is imported

// Async thunk to fetch fuel data
const AppToken = localStorage.getItem("APP_TOKEN");
export const fetchVendorCaptureData = createAsyncThunk(
  "vendorcapture/fetchVendorCapture",
  async ({ formData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NEW_APP_API_URL}/vendor-payment-capture`,
        formData,
        {
          headers: {
            Authorization: AppToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch fuel data"
      );
    }
  }
);

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const VendorCaptureSlice = createSlice({
  name: "vendorcapture",
  initialState,
  reducers: {
    resetVendorCaptureState(state) {
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendorCaptureData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVendorCaptureData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchVendorCaptureData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetVendorCaptureState } = VendorCaptureSlice.actions;
export default VendorCaptureSlice.reducer;
