import React, { useEffect, useState } from 'react'
import useLocationState from '../Medicine/hooks/useLocationState'
import { headerLogo } from '../../images';
import { DoctorEyeImage } from '../Medicine/Images';
import { useForm } from 'react-hook-form';
import { genderList, relationshipOPDList } from '../../data';
import { validateNumberInput } from '../../utils/AllFunction';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { fetchPackageList } from '../../Redux/actions/GetApiCall';
import { useDispatch, useSelector } from 'react-redux';
import { dentalSaveForm, dentalTimeSlot } from '../../Redux/actions/PostApiCall';
import { format } from 'date-fns';
import LoaderAnime from "../Medicine/animation/prescription_loader";
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import ToastBox from '../Medicine/animation/ToastBox';
const DentalDetailForm = () => {
    const Data = useLocationState();
    const [showModal, setShowModal] = useState(false)
    console.log(Data)
    const minDate = new Date(); // Current date
    const maxDate = new Date(); // 3 months from now
    maxDate.setMonth(maxDate.getMonth() + 3);
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const package_list = useSelector((state) => state?.package_list);
    const DentalFormRepo = useSelector((state) => state?.dental_form);
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(fetchPackageList())
    }, [dispatch])

    const [selectedDate, setSelectedDate] = useState('');
    const onSubmit = async (data) => {
        const formattedDate = selectedDate ? format(selectedDate, 'dd-MM-yyyy') : null;
        setShowModal(true)
        const DataForm = {
            doctor_id: Data?.data?.doctor_id,
            patient_name: data?.patient_name,
            age: data?.patient_age,
            age_type: data?.age_type,
            gender: data?.gender?.toLowerCase(),
            contact: package_list?.data?.profile_details?.mobile_number,
            email: package_list?.data?.profile_details?.email_id,
            appointment_date: formattedDate,
            appointment_time: data?.time,
            relation: data?.relation,
            clinic_id: Data?.data?.clinic_id,
            remark: data?.remark
        }
        await dispatch(dentalSaveForm(DataForm))
    }

    useEffect(() => {
        if (DentalFormRepo?.status === "success") {
            setShowModal(false)

            console.log(DentalFormRepo)

            setTimeout(() => {
                navigate("/dental-summary-details", { state: DentalFormRepo?.data?.data?.consultation?.id })
            }, 500);
        }
    }, [DentalFormRepo])



    const DoctorTimeSlot = useSelector(state => state.dental_time_slot);
    const [loading, setLoading] = useState(false);

    const handleDateChange = (date) => {
        setLoading(true)
        const formData = new FormData();
        setSelectedDate(date);
        const formattedDate = format(date, 'dd-MM-yyyy');

        if (formattedDate) {
            const RepoData = {
                doctor_id: Data?.data?.doctor_id,
                clinic_id: Data?.data?.clinic_id,
                date: formattedDate
            }
            dispatch(dentalTimeSlot(RepoData))
                .finally(() => setLoading(false)); // End loading after the API call completes
        }
    };

    useEffect(() => {
        if (DoctorTimeSlot?.data?.available_times?.length === 0) {
            toast.error("No slots are available")
        }
    }, [DoctorTimeSlot?.data?.available_times])

    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>{Data?.partner_name}</p>
                    </div>
                </div>
            </div>
            {showModal && <LoaderAnime showModal={showModal} />}
            <div className="card-wrapper-bankai-rpeo mt-3" style={{ border: 'none' }}>
                <div className="image-repo-doc-banaki" style={{ boxShadow: 'none' }}>
                    <img src={DoctorEyeImage} alt="" />
                </div>
                <div className="content-repo-doc-ank-ai">
                    <h6>{Data?.data?.doctor_name}</h6>
                    <p>{Data?.data?.doctor_designation}</p>
                    <div className="repo-proicce-bankai">
                        <div className="crop-pricerepo">
                            {Math.round(Data?.data?.clinic_medibhai_price) === Math.round(Data?.data?.clinic_mrp) ? (
                                <span>Consultation Fees: ₹ {Math.round(Data?.data?.clinic_medibhai_price)}</span>
                            ) : (
                                <>
                                    <span>Consultation Fees: ₹ {Math.round(Data?.data?.clinic_medibhai_price)}</span>
                                    <p>₹ {Math.round(Data?.data?.clinic_mrp)}</p>
                                </>
                            )}
                        </div>
                    </div>

                </div>
            </div>

            <div className="repo-card-bankai-one">
                <span className='repo-span-ban-cvd'>{Data?.data?.clinic_name}</span>
                <p>Address: <span>{Data?.data?.clinic_address}</span></p>
            </div>

            <div className="form-repo-bankai-repo-tepo">
                <div className="head-text-nade-repo">
                    <p>Enter Patient Details</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-page" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <input type="text" placeholder='Enter Patient Name' {...register('patient_name', { required: true })} />
                        {errors.patient_name && <span className='error-text-mssg'>This field is Required</span>}
                        <div className="option-data-page">
                            <div className="gender-select-page">
                                <select
                                    name="gender"
                                    {...register('gender', { required: true })}>
                                    <option value="" selected disabled>Select Gender</option>
                                    {genderList.map((data, index) => (
                                        <option key={index} value={data?.text}>{data?.text}</option>

                                    ))}
                                </select>
                                {errors.gender && <span className='error-text-mssg gen-text'>Gender is required</span>}
                            </div>
                            <div className="gender-select-page">
                                <select
                                    name="relation"
                                    {...register('relation', { required: true })}>
                                    <option value="">Select Relation</option>
                                    {relationshipOPDList.map((data, index) => (
                                        <option key={index} value={data?.text}>{data?.text}</option>
                                    ))}
                                </select>
                                {errors.relation && <span className='error-text-mssg gen-text'>Relation is required</span>}
                            </div>
                        </div>
                        <div className="option-data-page mb-0">
                            <div className="gender-select-page">
                                <input
                                    type="text"
                                    placeholder="Enter Patient Age"
                                    onInput={validateNumberInput}
                                    maxLength={3}
                                    {...register('patient_age', {
                                        required: 'Age is required',
                                        validate: (value) => value > 0 || 'Age must be greater than 0',
                                    })}
                                />
                                {errors.patient_age && <span className="error-text-mssg">{errors.patient_age.message}</span>}
                            </div>

                            <div className="gender-select-page">
                                <select
                                    {...register("age_type", {
                                        required: "Age type is required",
                                    })}
                                    defaultValue="" // sets default selected value
                                >
                                    <option value="" disabled>
                                        Select Age Type
                                    </option>
                                    <option value="days">Days</option>
                                    <option value="months">Month</option>
                                    <option value="years">Years</option>
                                </select>

                                <span className="error-text-mssg gen-text">
                                    {errors.age_type && errors.age_type.message}
                                </span>
                            </div>
                        </div>
                        <div className="option-data-page mb-0">
                            <div className="gender-select-page">
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    className="bankai-date"
                                    id="cancelDate"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select Date"
                                    minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                                    maxDate={maxDate}
                                    autoComplete="off"
                                    auto
                                    required
                                />
                            </div>
                            <div className="gender-select-page">
                                <select
                                    id="cancelReason"
                                    defaultValue=""
                                    {...register("time", {
                                        required: "Time is required",
                                    })}
                                    disabled={loading || !DoctorTimeSlot?.data?.available_times?.some(({ available }) => available)}
                                    style={{ padding: '0px', height: '40px' }}
                                >
                                    {loading ? (
                                        <option value="" disabled>
                                            Loading...
                                        </option>
                                    ) : (
                                        <>
                                            <option value="" disabled>
                                                Select Time
                                            </option>
                                            {DoctorTimeSlot?.data?.available_times
                                                ?.filter(({ available }) => available === true) // Only include available times
                                                .map(({ time }) => (
                                                    <option key={time} value={time}>
                                                        {time}
                                                    </option>
                                                ))}
                                        </>
                                    )}
                                </select>
                                <span className="error-text-mssg gen-text">
                                    {errors.time && errors.time.message}
                                </span>
                            </div>


                        </div>
                        <textarea name="remark" id="" placeholder='Enter remark' {...register("remark")}></textarea>
                        <div className="form-data-repo-box banaki-text-input">
                            <input type="text" value={package_list?.data?.profile_details?.mobile_number} disabled />
                            <input type="text" value={package_list?.data?.profile_details?.email_id} disabled />
                        </div>
                    </div>
                    <div className="submit-btn-wrap wrapper-bankai-repo">
                        <button style={{ fontSize: '16px', marginTop: '10px' }}>Book Appointment</button>
                    </div>
                </form>
            </div>
            <ToastBox />
        </div>
    )
}

export default DentalDetailForm