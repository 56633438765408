import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios"; // Ensure axios is imported

// Async thunk to fetch fuel data
const AppToken = localStorage.getItem("APP_TOKEN");
export const fetchNotificationData = createAsyncThunk(
  "notification/fetchNotification",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEW_APP_API_URL}/get-user-notification-list`,
        {
          headers: {
            Authorization: AppToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("API error:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch fuel data"
      );
    }
  }
);

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const NotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    resetNotificationState(state) {
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchNotificationData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetNotificationState } = NotificationSlice.actions;
export default NotificationSlice.reducer;
