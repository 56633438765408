import React, { useState, useEffect } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import DiagnosticOrder from "./components/DiagnosticOrder";
import { LabTestIcon, appTabletMed, savings_image } from "../../images";
import Medicine_Header from "../Medicine/Components/Medicine_Header";
import Medicine_footer from "../Medicine/Components/Medicine_footer";
import useLocationState from "../Medicine/hooks/useLocationState";
import { useSelector, useDispatch } from "react-redux";
import { orderDiagnostic, fetchDiagnosticPlaceOrderDetails } from "../../Redux/actions/PostApiCall";
// import LoaderAnime from "../Medicine/animation/prescription_loader";
import CartLoader from "../Medicine/animation/prescription_loader"
import Medicine_order from "../Medicine/Pages/Medicine_order";
import { orderMedicine } from "../../Redux/actions/PostApiCall";
import { fetchPackageList, getQueryParams } from "../../Redux/actions/GetApiCall";
import { useLocation } from "react-router";
import ToastBox from "../Medicine/animation/ToastBox";


const MainOrder = () => {
  const Title = "Placed Order";
  const dispatch = useDispatch();
  const mediCart = 1;
  const tabKey = useLocationState();
  const { data: MedicineOrderData } = useSelector(state => state.medicine_order_list);
  const [cartData, setCartData] = useState([]);
  const AppSimulated = localStorage.getItem("is_simulated");
  const [key, setKey] = useState(tabKey);
  const cartDetailsResponse = useSelector(
    (state) => state.diagnostic_cart_details
  );
  const [showModal, setShowModal] = useState(false);
  const [showModalCapture, setShowModalCapture] = useState(false);
  const [showModalWallet, setShowModalWallet] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [walletId, setWalletId] = useState("");

  const placeOrderDetailsData = useSelector(
    (state) => state.diagnostic_place_order_details_response
  );
  const currentUrl = window.location.href; // Get the current URL
  const { auto } = getQueryParams(currentUrl);

  const cartMediData = useSelector((state) => state.medicine_cart_list.data);

  // Medicine Order Function
  const handleOrderMedicine = async () => {
    if (cartMediData?.payable_amount === 0) {
      setShowModalWallet(true)
    } else {
      setShowModalCapture(true)
    }
    const orderData = {
      medicine_order_id: MedicineOrderData?.data?.medicine_order_id,
      package_id: selectedPackage,
      wallet_id: walletId,
      source: 0
    }
    setTimeout(async () => {
      await dispatch(orderMedicine(orderData))
    }, 2500);

  }

  // -----------------------DIAGNOSTICS-----------------------------
  const [selectedDiagnosticPackageData, setSelectedDiagnosticPackageData] = useState("");

  const [placeOrderDiagnosticApiData, setPlaceOrderDiagnosticApiData] = useState({
    package_id: null,
    wallet_id: null,
    source: 0
  });

  useEffect(() => {
    if (placeOrderDetailsData?.status === "loading") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [placeOrderDetailsData]);

  const handleDiagnosticOrder = async () => {
    await dispatch(orderDiagnostic(placeOrderDiagnosticApiData));
  }

  useEffect(() => {
    // if (tabKey === "diagnostics") {
    dispatch(fetchPackageList())
    dispatch(fetchDiagnosticPlaceOrderDetails(placeOrderDiagnosticApiData));
    // }
  }, [dispatch]);




  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const autoParam = queryParams.get('auto');

  // Function to extract query parameters from the URL
  // const getQueryParamsCode = (url) => {
  //   const params = new URLSearchParams(url.split('?')[1]); // Get the part after the '?' in the URL
  //   return Object.fromEntries(params.entries()); // Convert the params to an object
  // };

  // Usage

  useEffect(() => {
    const storedToken = localStorage.getItem("AUTO_TOKEN");
    const tokenTimestamp = localStorage.getItem("AUTO_TOKEN_TIMESTAMP");

    const THREE_HOURS = 3 * 60 * 60 * 1000;
    const currentTime = new Date().getTime();

    // Handle reload logic
    // if (localStorage.getItem("reload_triggered") === "true") {
    //     window.history.pushState({}, "", window.location.pathname);
    //     localStorage.removeItem("reload_triggered");
    //     return;
    // }

    // Set token and pincode if conditions are met, and store the current timestamp
    if (autoParam && storedToken !== autoParam) {
      localStorage.setItem("AUTO_TOKEN", autoParam);
      localStorage.setItem("AUTO_TOKEN_TIMESTAMP", currentTime.toString()); // Save timestamp
      window.location.reload();
    }
    // If there's an AUTO_TOKEN and timestamp, check if it has expired (3 hours passed)
    if (storedToken && tokenTimestamp && (currentTime - tokenTimestamp) > THREE_HOURS) {
      // Clear specific token-related items instead of clearing the whole localStorage
      localStorage.removeItem("AUTO_TOKEN");
      localStorage.removeItem("AUTO_TOKEN_TIMESTAMP");
    }

  }, [autoParam, location.search]);

  return (
    <div className="home-review-banner">
      <Medicine_Header Title={Title} cartData={cartData} labCartCount={placeOrderDetailsData?.data?.response?.members?.length} prescrip={key} />

      {auto ? (
        <>
          {placeOrderDetailsData?.status === "loading" && (
            <CartLoader showModal={showModal} />
          )}
          {placeOrderDetailsData && placeOrderDetailsData?.data && (
            <DiagnosticOrder
              showModal={showModal}
              setShowModal={setShowModal}
              selectedDiagnosticPackageData={selectedDiagnosticPackageData}
              setSelectedDiagnosticPackageData={setSelectedDiagnosticPackageData}
              setPlaceOrderDiagnosticApiData={setPlaceOrderDiagnosticApiData}
              placeOrderDiagnosticApiData={placeOrderDiagnosticApiData}
              labCartData={
                placeOrderDetailsData &&
                placeOrderDetailsData?.data?.response
              }
            />
          )}
        </>
      ) : (
        <div className="app-main-cart-cover-wrapper">
          <div className="tab-main-cover-wrapper">
            <Tab.Container id="left-tabs-example" defaultActiveKey={key}>
              <Row>
                <Col lg={12} className=" tab-cover tab-cover2">
                  <div className="cart-header-tabs d-flex justify-content-center">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="medicines"
                          onClick={() => setKey("medicines")}
                          className={
                            key === "medicines" ? "active-link" : "not-active"
                          }
                          disabled={key === "diagnostics"}
                        >
                          <div className="medicine-box lab-box">
                            <div className="medi-image">
                              <img
                                src={appTabletMed}
                                width="100%"
                                alt="Tablet-capsule"
                              />
                            </div>
                            <div className="box-heading">
                              <p>Medicines</p>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="diagnostics"
                          onClick={() => setKey("diagnostics")}
                          className={
                            key === "diagnostics" ? "active-link" : "not-active"
                          }
                          disabled={key === "medicines"}
                        >
                          <div className="lab-box">
                            <div className="medi-image">
                              <img
                                src={LabTestIcon}
                                width="100%"
                                alt="Lab-Test"
                              />
                            </div>
                            <div className="box-heading">
                              <p>Lab Tests</p>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="tab-content-main-cover">
                    <Tab.Content>
                      <Tab.Pane eventKey="medicines">
                        <Medicine_order tabkey={key} setCartData={setCartData} walletId={walletId} selectedPackage={selectedPackage} setWalletId={setWalletId} setSelectedPackage={setSelectedPackage} showModal={showModal} setShowModal={setShowModal} setShowModalCapture={setShowModalCapture} showModalCapture={showModalCapture} setShowModalWallet={setShowModalWallet} showModalWallet={showModalWallet}/>
                      </Tab.Pane>
                      <Tab.Pane eventKey="diagnostics">
                        {placeOrderDetailsData?.status === "loading" && (
                          <CartLoader showModal={showModal} />
                        )}
                        {placeOrderDetailsData && placeOrderDetailsData?.data && (
                          <DiagnosticOrder
                            showModal={showModal}
                            setShowModal={setShowModal}
                            selectedDiagnosticPackageData={selectedDiagnosticPackageData}
                            setSelectedDiagnosticPackageData={setSelectedDiagnosticPackageData}
                            setPlaceOrderDiagnosticApiData={setPlaceOrderDiagnosticApiData}
                            placeOrderDiagnosticApiData={placeOrderDiagnosticApiData}
                            labCartData={
                              placeOrderDetailsData &&
                              placeOrderDetailsData?.data?.response
                            }
                          />
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      )}


      {AppSimulated ? (
        <>

        </>
      ) : (
        <>
          {((key === "medicines" && cartMediData && ((cartMediData?.total_item > 0 || cartMediData?.total_items > 0))) ||
            (key === "diagnostics" &&
              placeOrderDetailsData?.data) || auto) && (
              <Medicine_footer
                cartData={cartMediData}
                labCartData={placeOrderDetailsData?.data?.response}
                tabkey={key}
                mediCart={mediCart}
                handleDiagnosticOrder={handleDiagnosticOrder}
                handleOrderMedicine={handleOrderMedicine}
              />
            )}
        </>
      )}
      <ToastBox />
    </div>
  );
};

export default MainOrder;
