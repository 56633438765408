import React, { useEffect, useState, useRef } from 'react';
import useLocationState from '../hooks/useLocationState';
import Medicine_Header from '../Components/Medicine_Header';
import Medicine_footer from '../Components/Medicine_footer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedicineCartList, fetchMedicineRecent } from '../../../Redux/actions/GetApiCall';
import RecentLoader from '../animation/recentloader';
import MediLoader from '../animation/MediLoader';
import Recent_Card from '../Components/Recent_card';

const RecentProduct = () => {
    const Data = useLocationState();
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [recentloaderStatus, setRecentLoaderStatus] = useState(false);
    const Title = "Recent Products";
    const corner = "data";
    const cartListLength = useSelector(state => state.medicine_cart_list);
    const key = "medicines";
    const [pageNo, setPageNo] = useState(1);
    const dispatch = useDispatch();
    const [hasMore, setHasMore] = useState(true);
    const scrollContainerRef = useRef(null);
    const [allMedicines, setAllMedicines] = useState([]);
    const recentData = useSelector(state => state.medicine_recent_list.data);
    const recentDataStatus = useSelector(state => state.medicine_recent_list.loader);

    const fetchData = async (page) => {
        if (recentData?.total_pages && page <= recentData.total_pages) {
            setRecentLoaderStatus(true);
            const response = await dispatch(fetchMedicineRecent(page));
            setRecentLoaderStatus(false);
            if (response?.data?.length === 0 || page >= recentData.total_pages) {
                setHasMore(false);
            } else {
                setAllMedicines(prevMedicines => [...prevMedicines, ...recentData?.data]);
                setHasMore(true);
            }
        } else {
            setHasMore(false);
        }
    };

    useEffect(() => {
        dispatch(fetchMedicineRecent(pageNo))
        dispatch(fetchMedicineCartList())
    }, []);

    useEffect(() => {
        if (pageNo > 1) {
            fetchData(pageNo);
        }
    }, [dispatch, pageNo]);

    useEffect(() => {
        setAllMedicines(Data?.data)
    }, [])

    const handleScroll = () => {
        if (scrollContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;

            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !recentloaderStatus) {
                setPageNo(prevPageNo => prevPageNo + 1);
            }
        }
    };

    useEffect(() => {
        const currentRef = scrollContainerRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [loaderStatus, hasMore]);

    const height = "75vh"

    return (
        <div className="home-review-banner">
            <Medicine_Header Title={Title} />
            {/* {recentDataStatus && !loaderStatus ? (
                <MediLoader height={height} />
            ) : ( */}
                <div className="recent-product-data-repo-fixed" ref={scrollContainerRef} style={{ overflowY: 'auto', maxHeight: '670px' }}>
                    <div className="row">
                        <Recent_Card
                            mediData={allMedicines}
                            corner={corner}
                            setLoaderStatus={setLoaderStatus}
                        />
                    </div>
                    {recentloaderStatus && <div>
                        <RecentLoader />
                    </div>}
                </div>
            {/* )} */}

            {cartListLength?.data?.total_items > 0 && cartListLength?.data?.available_stock.length > 0 && (
                <Medicine_footer tabkey={key} />
            )}
        </div>
    );
}

export default RecentProduct;
