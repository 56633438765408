import React from 'react'
import './verticaldoctorcard.css'
import { appOPDIcon } from '../../images'
import { useNavigate } from 'react-router';
import { DoctorEyeImage, inpersonsvg, onlinepersonsvg } from '../Medicine/Images';
import { IoLocationSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { opd_book } from '../../Redux/actions/PostApiCall';

const VerticalDoctorCard = ({ Data }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log(Data)
    const handleDetails = () => {
        const DataRepo = {
            doctor_id: Data?.doctor_id,
            departments: Data?.departments?.[0]?.department_id,
            hospitalID: Data?.hospitalDetails?.[0]?.hospital_id
        }
        navigate("/doctor-detail", { state: DataRepo })
    }
   
    return (
        <div>
            <div
                className="doc-card"
                onClick={handleDetails}
            >

                <div class="doc-profile-image">
                    <img src={DoctorEyeImage} alt="Doctor Image" class="doc-profile-image" />
                </div>

                <div class="doc-doctor-name">{Data?.doctor_name}</div>
                <div class="doc-specialization">{Data?.departments?.[0]?.department_name}</div>

                <div class="doc-qualification">{Data?.education}</div>
                <div class="doc-location"><IoLocationSharp /> {Data?.location}</div>

                <div class="doc-appointment-options">
                    {(Data?.opd_price != null || Data?.online_price != null) && (
                        <div className="repo-clover-price-data">
                            <div className="online">
                                <img src={onlinepersonsvg} alt="online-consultation" />
                            </div>
                            <div className="price-tag-doc">
                                Online
                                <p>₹ {Data?.online_price || Data?.opd_price}</p>
                            </div>
                        </div>
                    )}
                    {(Data?.price != null || Data?.in_person_price != null) && (
                        <div className="repo-clover-price-data">
                            <div className="online">
                                <img src={inpersonsvg} alt="in-person-consultation" />
                            </div>
                            <div className="price-tag-doc">
                                In-person
                                <p>₹ {Data?.price || Data?.in_person_price}</p>
                            </div>
                        </div>
                    )}
                </div>

                {Data?.in_person_price === null && Data?.online_price === null ? (
                    <button class="doc-book-button">Enquire Now</button>
                ) : (
                    <button class="doc-book-button">Book Appointment</button>
                )}
            </div>

        </div>
    )
}

export default VerticalDoctorCard