import { AMBULANCE_REQUEST_API } from "../../Constants";
import { createApiAction } from "./apiActions";

const API_BASE_URL = process.env.REACT_APP_NEW_APP_API_URL;
// const API_BASE_URL = "https://beta.medibhai.com/api";

const AppToken = localStorage.getItem("APP_TOKEN");
const AutoToken = localStorage.getItem("AUTO_TOKEN");

export const getQueryParams = (url) => {
  const urlObj = new URL(url, window.location.origin);
  const queryParams = new URLSearchParams(urlObj.search);
  return {
    auto: queryParams.get("auto"),
  };
};

const currentUrl = window.location.href; // Get the current URL
const { auto } = getQueryParams(currentUrl);
const AutoApi = auto ? "employee-auto-login" : "medicine";
const AutoTele = auto ? "employee-auto-login" : "teleconsultation";
const AutoDiago = auto ? "employee-auto-login" : "diagnostic";
const capDiago = auto ? "diagnostic-" : "";

const endpoints = {
  //MEDICINES -------------------
  UPLOAD_PRESCRIPTION: `${API_BASE_URL}/medicine/save-selected-prescription`,
  SAVE_UPLOAD_PRESCRIPTION: `${API_BASE_URL}/medicine/upload-prescription`,
  DELETE_PRESCRIPTION: `${API_BASE_URL}/medicine/delete-uploaded-prescription`,
  MEDICINE_CART_ADD: `${API_BASE_URL}/${AutoApi}/medicine-cart-store`,
  MEDICINE_CART_DELETE: `${API_BASE_URL}/${AutoApi}/remove-cart-item`,
  CALL_DOCTOR: `${API_BASE_URL}/medicine/call-doctor-request`,
  SAVE_MEDICINE_ADDRESS: `${API_BASE_URL}/medicine/store-user-address`,
  DELETE_MEDICINE_ADDRESS: `${API_BASE_URL}/medicine/delete-user-address`,
  SELECTED_MEDICINE_ADDRESS: `${API_BASE_URL}/medicine/save-selected-address`,
  ORDER_MEDICINE: `${API_BASE_URL}/${AutoApi}/order-medicine`,
  CAPTURE_ORDER_MEDICINE: `${API_BASE_URL}/medicine/capture-payment-details`,
  CANCEL_ORDER_MEDICINE: `${API_BASE_URL}/medicine/cancel-medicine-order`,
  REORDER_ORDER_MEDICINE: `${API_BASE_URL}/medicine/medicine-reorder-api`,
  FETCH_WELLNESS_FORM: `${API_BASE_URL}/save-wellness-wings-details`,
  BOAT_AND_YOGA: `${API_BASE_URL}/package/utilize-package-additional-benefit`,
  TELECONSULTATION_REBOOK_FORM: `${API_BASE_URL}/teleconsultation/consultations/edit/`,
  OPD_REBOOK_FORM: `${API_BASE_URL}/opdconsultation/opdconsultations/edit/`,

  // RADIOLOGY ---------
  RADIO_CART_ADD: `${API_BASE_URL}/radiology/diagnostic-cart`,
  RADIO_REMOVE_CART: `${API_BASE_URL}/radiology/delete-cart-item`,
  RADIO_ADD_PATIENT_MEMBERS: `${API_BASE_URL}/radiology/create-member`,
  RADIO_DELETE_PATIENT_MEMBERS: `${API_BASE_URL}/radiology/delete-members`,
  // CALL_DOCTOR: `${API_BASE_URL}/call-doctor-request`,
  RADIO_SAVE_PATIENT_MEMBERS: `${API_BASE_URL}/radiology/save-selected-member`,
  RADIO_CITY: `${API_BASE_URL}/radiology/get-city/`,
  DOCTOR_PATIENT_FORM: `${API_BASE_URL}/opdconsultation/post-opdconsultation-details`,
  DOCTOR_AMOUNT_UTILIZATION: `${API_BASE_URL}/opdconsultation/amount/utilization`,
  DOCTOR_CAPTURE_PAYMENT: `${API_BASE_URL}/opdconsultation/capture-payment-details`,
  DOCTOR_TIME_SLOT: `${API_BASE_URL}/opdconsultation/doctor/available-times`,
  DOCTOR_TIME_FORM: `${API_BASE_URL}/opdconsultation/time-slot-opd`,

  // Dental Repo
  DENTAL_TIME: `${API_BASE_URL}/dental/doctor/available-times`,
  DENTAL_FORM: `${API_BASE_URL}/dental/post-dental-details`,
  DENTAL_UTILIZATION: `${API_BASE_URL}/dental/amount/utilization`,
  DENTAL_CAPTURE_PAYMENT: `${API_BASE_URL}/dental/capture-payment-details`,
  DENTAL_RESEDULE: `${API_BASE_URL}/dental/dental-list/edit/`,

  // Eye Repo
  EYE_TIME: `${API_BASE_URL}/eye/doctor/available-times`,
  EYE_FORM: `${API_BASE_URL}/eye/post-eye-details`,
  EYE_UTILIZATION: `${API_BASE_URL}/eye/amount/utilization`,
  EYE_CAPTURE_PAYMENT: `${API_BASE_URL}/eye/capture-payment-details`,
  EYE_RECHEDULE: `${API_BASE_URL}/eye/eye-list/edit/`,

  //DIAGNOSTICS -----------------
  DIAGNOSTIC_CART_ADD: `${API_BASE_URL}/diagnostic/diagnostic-cart`,
  DIAGNOSTIC_REMOVE_CART: `${API_BASE_URL}/diagnostic/delete-cart-item`,
  ADD_PATIENT_MEMBERS: `${API_BASE_URL}/diagnostic/create-member`,
  DELETE_PATIENT_MEMBERS: `${API_BASE_URL}/diagnostic/delete-members`,
  // CALL_DOCTOR: `${API_BASE_URL}/call-doctor-request`,
  SAVE_PATIENT_MEMBERS: `${API_BASE_URL}/diagnostic/save-selected-member`,
  DIAGNOSTIC_PLACE_ORDER_DETAILS: `${API_BASE_URL}/${AutoDiago}/place-order-details`,
  RADIOLOGY_PLACE_ORDER_DETAILS: `${API_BASE_URL}/radiology/place-order-details`,
  SAVE_TIME_SLOT: `${API_BASE_URL}/diagnostic/save-timeslot`,
  SAVE_TIME_SLOT_RADIO: `${API_BASE_URL}/radiology/save-timeslot`,
  ORDER_DIAGNOSTIC: `${API_BASE_URL}/${AutoDiago}/order-diagnostic`,
  ORDER_RADIOLOGY: `${API_BASE_URL}/radiology/order-diagnostic`,
  SELECTED_DIAGNOSTIC_ADDRESS: `${API_BASE_URL}/diagnostic/save-address`,
  CAPTURE_DIAGNOSTIC_PAYMENT_DETAILS: `${API_BASE_URL}/${AutoDiago}/${capDiago}capture-payment-details`,
  CAPTURE_RADIOLOGY_PAYMENT_DETAILS: `${API_BASE_URL}/radiology/capture-payment-details`,
  UPLOAD_DIAGNSOTIC_PRESCRIPTION: `${API_BASE_URL}/diagnostic/upload-prescription`,
  DELETE_DIAGNSOTIC_PRESCRIPTION: `${API_BASE_URL}/diagnostic/delete-uploaded-prescription`,
  UPLOAD_RADIOLOGY_PRESCRIPTION: `${API_BASE_URL}/radiology/upload-prescription`,
  DELETE_RADIOLOGY_PRESCRIPTION: `${API_BASE_URL}/radiology/delete-uploaded-prescription`,

  DEPARTMENT_TIME_SLOT: `${API_BASE_URL}/teleconsultation/department-time-slot`,
  BOOK_TELECONSULT: `${API_BASE_URL}/teleconsultation/post-consultation-details`,
  UTILIZE_TELECONSULT_AMOUNT: `${API_BASE_URL}/${AutoTele}/amount/utilization`,
  CAPTURE_TELECONSULT_PAYMENT_DETAILS: `${API_BASE_URL}/${AutoTele}/capture-payment-details`,
  CANCEL_ORDER_DIAGNOSTIC: `${API_BASE_URL}/diagnostic/cancel-order`,
  CANCEL_ORDER_RADIOLOGY: `${API_BASE_URL}/radiology/cancel-order`,
  REORDER_ORDER_DIAGNOSTIC: `${API_BASE_URL}/diagnostic/reschedule-slot`,
  REORDER_ORDER_RADIOLOGY: `${API_BASE_URL}/radiology/reschedule-slot`,
  SAVE_REIMBURSEMENT: `${API_BASE_URL}/save-reimbursement-details`,
  HELP_API: `${API_BASE_URL}/request-support`,
  FITNESS_PRO: `${API_BASE_URL}/save-fitness-pro-details`,

  //AUTH --------------
  AUTH_PACKAGE_REGISTRATION: `${API_BASE_URL}/package/generate-web-package-registration-otp`,
  AUTH_CREATION_REGISTRATION: `${API_BASE_URL}/package/generate-web-package-creation-otp`,
  VERIFY_PACKAGE_OTP: `${API_BASE_URL}/package/verify-package-registration-otp`,
  VERIFY_PACKAGE_CREATION_OTP: `${API_BASE_URL}/package/verify-package-creation-otp`,
  CAPTURE_USER_PACKAGE: `${API_BASE_URL}/package/capture-user-package-registration`,
  CAPTURE_USER_CREATION: `${API_BASE_URL}/package/capture-user-package-creation`,
  GENERATE_OTP: `${API_BASE_URL}/generate-web-otp`,
  VERIFY_LOGIN_OTP: `${API_BASE_URL}/verify-web-otp`,
  YOGA_SESSION: `${API_BASE_URL}/save-yoga-session`,
  DENTAL_BOOK: `${API_BASE_URL}/save-dental-consultation`,
  EYE_BOOK: `${API_BASE_URL}/save-eye-consultation`,
  OPD_BOOK: `${API_BASE_URL}/opdconsultation/opd-form`,

  //Ambulance
  AMBULANCE_API: `${API_BASE_URL}/save-ambulance-service`,

  // Diabeties and Nutrition
  DIABETIES_API: `${API_BASE_URL}/diabetes-nutrition-data`,

  // Vendor Api
  VENDOR_API: `${API_BASE_URL}/vendor/vendor-details`,
  VENDOR_PACKAGE_API: `${API_BASE_URL}/vendor/vendor-package-details`,
};
const Package = JSON.parse(localStorage.getItem("PakageData"));

const createJsonRequestOptions = (method, body, options = {}) => ({
  method,
  headers: {
    "Content-Type": "application/json",
    Authorization: auto ? AutoToken : AppToken,
    PackageId: Package?.id,
  },
  body: JSON.stringify(body),
  ...options,
});

const createFormRequestOptions = (method, formData, options = {}) => ({
  method,
  headers: {
    Authorization: AppToken,
    PackageId: Package?.id,
  },
  body: formData,
  ...options,
});

export const uploadPrescription = (prescriptionData) =>
  createApiAction("UPLOAD_PRESCRIPTION", (options) =>
    fetch(
      endpoints.UPLOAD_PRESCRIPTION,
      createFormRequestOptions("POST", prescriptionData, options)
    )
  );

export const ambulanceService = (prescriptionData) =>
  createApiAction("AMBULANCE_API", (options) =>
    fetch(
      endpoints.AMBULANCE_API,
      createFormRequestOptions("POST", prescriptionData, options)
    )
  );

export const TeleconsultationRebook = (id, data) =>
  createApiAction("TELECONSULTATION_REBOOK_FORM", (options) =>
    fetch(
      `${endpoints.TELECONSULTATION_REBOOK_FORM}${id}`, // Append id to the endpoint
      createFormRequestOptions("POST", data, options)
    )
  );

export const OpdRebook = (id, data) =>
  createApiAction("OPD_REBOOK_FORM", (options) =>
    fetch(
      `${endpoints.OPD_REBOOK_FORM}${id}`, // Append id to the endpoint
      createFormRequestOptions("POST", data, options)
    )
  );

export const saveuploadPrescription = (prescriptionData) =>
  createApiAction("SAVE_UPLOAD_PRESCRIPTION", (options) =>
    fetch(
      endpoints.SAVE_UPLOAD_PRESCRIPTION,
      createFormRequestOptions("POST", prescriptionData, options)
    )
  );

export const diabetesApi = (prescriptionData) =>
  createApiAction("DIABETIES_API", (options) =>
    fetch(
      endpoints.DIABETIES_API,
      createJsonRequestOptions("POST", prescriptionData, options)
    )
  );

export const saveReiumbursementApi = (prescriptionData) =>
  createApiAction("SAVE_REIMBURSEMENT", (options) =>
    fetch(
      endpoints.SAVE_REIMBURSEMENT,
      createFormRequestOptions("POST", prescriptionData, options)
    )
  );

export const deletePrescription = (prescriptionData) =>
  createApiAction("DELETE_PRESCRIPTION", (options) =>
    fetch(
      endpoints.DELETE_PRESCRIPTION,
      createJsonRequestOptions("POST", prescriptionData, options)
    )
  );

export const boatandyoga = (data) =>
  createApiAction("BOAT_AND_YOGA", (options) =>
    fetch(
      endpoints.BOAT_AND_YOGA,
      createJsonRequestOptions("POST", data, options)
    )
  );

export const FitnessPro = (data) =>
  createApiAction("FITNESS_PRO", (options) =>
    fetch(
      endpoints.FITNESS_PRO,
      createJsonRequestOptions("POST", data, options)
    )
  );

export const fetchWellnesForm = (prescriptionData) =>
  createApiAction("FETCH_WELLNESS_FORM", (options) =>
    fetch(
      endpoints.FETCH_WELLNESS_FORM,
      createJsonRequestOptions("POST", prescriptionData, options)
    )
  );

export const CancelOrder = (data) =>
  createApiAction("CANCEL_ORDER_MEDICINE", (options) =>
    fetch(
      endpoints.CANCEL_ORDER_MEDICINE,
      createJsonRequestOptions("POST", data, options)
    )
  );

export const CancelDiagnosticOrder = (data) =>
  createApiAction("CANCEL_ORDER_DIAGNOSTIC", (options) =>
    fetch(
      endpoints.CANCEL_ORDER_DIAGNOSTIC,
      createJsonRequestOptions("POST", data, options)
    )
  );

export const CancelRadiologyOrder = (data) =>
  createApiAction("CANCEL_ORDER_RADIOLOGY", (options) =>
    fetch(
      endpoints.CANCEL_ORDER_RADIOLOGY,
      createJsonRequestOptions("POST", data, options)
    )
  );

export const ReorderApi = (data) =>
  createApiAction("REORDER_ORDER_MEDICINE", (options) =>
    fetch(
      endpoints.REORDER_ORDER_MEDICINE,
      createJsonRequestOptions("POST", data, options)
    )
  );

export const ReorderDiagnosticApi = (data) =>
  createApiAction("REORDER_ORDER_DIAGNOSTIC", (options) =>
    fetch(
      endpoints.REORDER_ORDER_DIAGNOSTIC,
      createJsonRequestOptions("POST", data, options)
    )
  );

export const ReorderRadiologyApi = (data) =>
  createApiAction("REORDER_ORDER_RADIOLOGY", (options) =>
    fetch(
      endpoints.REORDER_ORDER_RADIOLOGY,
      createJsonRequestOptions("POST", data, options)
    )
  );

export const medicineCartAdd = (cartData) =>
  createApiAction("MEDICINE_CART_ADD", (options) =>
    fetch(
      endpoints.MEDICINE_CART_ADD,
      createJsonRequestOptions("POST", cartData, options)
    )
  );

export const medicineCartDelete = (cartData) =>
  createApiAction("MEDICINE_CART_DELETE", (options) =>
    fetch(
      endpoints.MEDICINE_CART_DELETE,
      createJsonRequestOptions("POST", cartData, options)
    )
  );

export const callDoctor = (cartData) =>
  createApiAction("CALL_DOCTOR", (options) =>
    fetch(
      endpoints.CALL_DOCTOR,
      createJsonRequestOptions("POST", cartData, options)
    )
  );

export const saveMedicine = (addressData) =>
  createApiAction("SAVE_MEDICINE_ADDRESS", (options) =>
    fetch(
      endpoints.SAVE_MEDICINE_ADDRESS,
      createJsonRequestOptions("POST", addressData, options)
    )
  );
export const orderMedicine = (orderData) =>
  createApiAction("ORDER_MEDICINE", (options) =>
    fetch(
      endpoints.ORDER_MEDICINE,
      createJsonRequestOptions("POST", orderData, options)
    )
  );
export const captureOrderMedicine = (orderData) =>
  createApiAction("CAPTURE_ORDER_MEDICINE", (options) =>
    fetch(
      endpoints.CAPTURE_ORDER_MEDICINE,
      createJsonRequestOptions("POST", orderData, options)
    )
  );

export const deleteMedicineAddress = (addressId) =>
  createApiAction("DELETE_MEDICINE_ADDRESS", (options) =>
    fetch(
      endpoints.DELETE_MEDICINE_ADDRESS,
      createJsonRequestOptions("POST", addressId, options)
    )
  );

export const selectedMedicineAddress = (addressData) =>
  createApiAction("SELECTED_MEDICINE_ADDRESS", (options) =>
    fetch(
      endpoints.SELECTED_MEDICINE_ADDRESS,
      createJsonRequestOptions("POST", addressData, options)
    )
  );

//DIAGNOSTICS --------------------------------

export const diagnosticCartAdd = (cartData) =>
  createApiAction("DIAGNOSTIC_CART_ADD", (options) =>
    fetch(
      endpoints.DIAGNOSTIC_CART_ADD,
      createJsonRequestOptions("POST", cartData, options)
    )
  );

export const diagnosticRemoveCart = (cartData) =>
  createApiAction("DIAGNOSTIC_REMOVE_CART", (options) =>
    fetch(
      endpoints.DIAGNOSTIC_REMOVE_CART,
      createJsonRequestOptions("POST", cartData, options)
    )
  );

export const addPatientMembers = (patientData) =>
  createApiAction("ADD_PATIENT_MEMBERS", (options) =>
    fetch(
      endpoints.ADD_PATIENT_MEMBERS,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const deletePatient = (patient_id) =>
  createApiAction("DELETE_PATIENT_MEMBERS", (options) =>
    fetch(
      endpoints.DELETE_PATIENT_MEMBERS,
      createJsonRequestOptions("POST", patient_id, options)
    )
  );

export const savePatientMembers = (patientData) =>
  createApiAction("SAVE_PATIENT_MEMBERS", (options) =>
    fetch(
      endpoints.SAVE_PATIENT_MEMBERS,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const fetchDiagnosticPlaceOrderDetails = (placeOrderData) =>
  createApiAction("DIAGNOSTIC_PLACE_ORDER_DETAILS", (options) =>
    fetch(
      endpoints.DIAGNOSTIC_PLACE_ORDER_DETAILS,
      createJsonRequestOptions("POST", placeOrderData, options)
    )
  );

export const fetchRadiologyPlaceOrderDetails = (placeOrderData) =>
  createApiAction("RADIOLOGY_PLACE_ORDER_DETAILS", (options) =>
    fetch(
      endpoints.RADIOLOGY_PLACE_ORDER_DETAILS,
      createJsonRequestOptions("POST", placeOrderData, options)
    )
  );

export const orderDiagnostic = (order_diagnostic_data) =>
  createApiAction("ORDER_DIAGNOSTIC", (options) =>
    fetch(
      endpoints.ORDER_DIAGNOSTIC,
      createJsonRequestOptions("POST", order_diagnostic_data, options)
    )
  );

export const orderRadiology = (order_diagnostic_data) =>
  createApiAction("ORDER_RADIOLOGY", (options) =>
    fetch(
      endpoints.ORDER_RADIOLOGY,
      createJsonRequestOptions("POST", order_diagnostic_data, options)
    )
  );

export const package_registration = (signUpData) =>
  createApiAction("AUTH_PACKAGE_REGISTRATION", (options) =>
    fetch(
      endpoints.AUTH_PACKAGE_REGISTRATION,
      createJsonRequestOptions("POST", signUpData, options)
    )
  );
export const creation_registration = (signUpData) =>
  createApiAction("AUTH_CREATION_REGISTRATION", (options) =>
    fetch(
      endpoints.AUTH_CREATION_REGISTRATION,
      createJsonRequestOptions("POST", signUpData, options)
    )
  );

export const verify_package_otp = (signUpData) =>
  createApiAction("VERIFY_PACKAGE_OTP", (options) =>
    fetch(
      endpoints.VERIFY_PACKAGE_OTP,
      createJsonRequestOptions("POST", signUpData, options)
    )
  );

export const verify_package_creation_otp = (signUpData) =>
  createApiAction("VERIFY_PACKAGE_CREATION_OTP", (options) =>
    fetch(
      endpoints.VERIFY_PACKAGE_CREATION_OTP,
      createJsonRequestOptions("POST", signUpData, options)
    )
  );

export const capture_user_package = (signUpData) =>
  createApiAction("CAPTURE_USER_PACKAGE", (options) =>
    fetch(
      endpoints.CAPTURE_USER_PACKAGE,
      createJsonRequestOptions("POST", signUpData, options)
    )
  );

export const capture_user_creation = (signUpData) =>
  createApiAction("CAPTURE_USER_CREATION", (options) =>
    fetch(
      endpoints.CAPTURE_USER_CREATION,
      createJsonRequestOptions("POST", signUpData, options)
    )
  );

export const help_Api = (Data) =>
  createApiAction("HELP_API", (options) =>
    fetch(endpoints.HELP_API, createJsonRequestOptions("POST", Data, options))
  );

export const generate_otp = (loginData) =>
  createApiAction("GENERATE_OTP", (options) =>
    fetch(
      endpoints.GENERATE_OTP,
      createJsonRequestOptions("POST", loginData, options)
    )
  );

export const verify_login_otp = (loginData) =>
  createApiAction("VERIFY_LOGIN_OTP", (options) =>
    fetch(
      endpoints.VERIFY_LOGIN_OTP,
      createJsonRequestOptions("POST", loginData, options)
    )
  );
export const dental_book = (loginData) =>
  createApiAction("DENTAL_BOOK", (options) =>
    fetch(
      endpoints.DENTAL_BOOK,
      createJsonRequestOptions("POST", loginData, options)
    )
  );
export const yoga_session = (loginData) =>
  createApiAction("YOGA_SESSION", (options) =>
    fetch(
      endpoints.YOGA_SESSION,
      createJsonRequestOptions("POST", loginData, options)
    )
  );

export const eye_book = (loginData) =>
  createApiAction("EYE_BOOK", (options) =>
    fetch(
      endpoints.EYE_BOOK,
      createJsonRequestOptions("POST", loginData, options)
    )
  );

export const opd_book = (loginData) =>
  createApiAction("OPD_BOOK", (options) =>
    fetch(
      endpoints.OPD_BOOK,
      createJsonRequestOptions("POST", loginData, options)
    )
  );

export const save_time_slot = (timeSlotData) =>
  createApiAction("SAVE_TIME_SLOT", (options) =>
    fetch(
      endpoints.SAVE_TIME_SLOT,
      createJsonRequestOptions("POST", timeSlotData, options)
    )
  );

export const save_time_slot_radio = (timeSlotData) =>
  createApiAction("SAVE_TIME_SLOT_RADIO", (options) =>
    fetch(
      endpoints.SAVE_TIME_SLOT_RADIO,
      createJsonRequestOptions("POST", timeSlotData, options)
    )
  );

export const capture_diagnostic_payment_details = (capture_data) =>
  createApiAction("CAPTURE_DIAGNOSTIC_PAYMENT_DETAILS", (options) =>
    fetch(
      endpoints.CAPTURE_DIAGNOSTIC_PAYMENT_DETAILS,
      createJsonRequestOptions("POST", capture_data, options)
    )
  );

export const capture_radiology_payment_details = (capture_data) =>
  createApiAction("CAPTURE_RADIOLOGY_PAYMENT_DETAILS", (options) =>
    fetch(
      endpoints.CAPTURE_RADIOLOGY_PAYMENT_DETAILS,
      createJsonRequestOptions("POST", capture_data, options)
    )
  );

export const selectedDiagnosticAddress = (addressData) =>
  createApiAction("SELECTED_DIAGNOSTIC_ADDRESS", (options) =>
    fetch(
      endpoints.SELECTED_DIAGNOSTIC_ADDRESS,
      createJsonRequestOptions("POST", addressData, options)
    )
  );

export const getDepartmentTimeSlot = (departmentData) =>
  createApiAction("DEPARTMENT_TIME_SLOT", (options) =>
    fetch(
      endpoints.DEPARTMENT_TIME_SLOT,
      createFormRequestOptions("POST", departmentData, options)
    )
  );

export const bookTeleconsult = (consultData) =>
  createApiAction("BOOK_TELECONSULT", (options) =>
    fetch(
      endpoints.BOOK_TELECONSULT,
      createFormRequestOptions("POST", consultData, options)
    )
  );

export const uploadDiagnosticPrescription = (prescriptionData) =>
  createApiAction("UPLOAD_DIAGNSOTIC_PRESCRIPTION", (options) =>
    fetch(
      endpoints.UPLOAD_DIAGNSOTIC_PRESCRIPTION,
      createFormRequestOptions("POST", prescriptionData, options)
    )
  );

export const deleteDiagnosticPrescription = (prescriptionData) =>
  createApiAction("DELETE_DIAGNOSTIC_PRESCRIPTION", (options) =>
    fetch(
      endpoints.DELETE_DIAGNSOTIC_PRESCRIPTION,
      createJsonRequestOptions("POST", prescriptionData, options)
    )
  );

export const uploadRadiologyPrescription = (prescriptionData) =>
  createApiAction("UPLOAD_RADIOLOGY_PRESCRIPTION", (options) =>
    fetch(
      endpoints.UPLOAD_RADIOLOGY_PRESCRIPTION,
      createFormRequestOptions("POST", prescriptionData, options)
    )
  );

export const deleteRadiologyPrescription = (prescriptionData) =>
  createApiAction("DELETE_RADIOLOGY_PRESCRIPTION", (options) =>
    fetch(
      endpoints.DELETE_RADIOLOGY_PRESCRIPTION,
      createJsonRequestOptions("POST", prescriptionData, options)
    )
  );

export const utilizeTeleconsultAmount = (utilizeData) =>
  createApiAction("UTILIZE_TELECONSULT_AMOUNT", (options) =>
    fetch(
      endpoints.UTILIZE_TELECONSULT_AMOUNT,
      createJsonRequestOptions("POST", utilizeData, options)
    )
  );

export const capture_teleconsult_payment_details = (capture_data) =>
  createApiAction("CAPTURE_TELECONSULT_PAYMENT_DETAILS", (options) =>
    fetch(
      endpoints.CAPTURE_TELECONSULT_PAYMENT_DETAILS,
      createJsonRequestOptions("POST", capture_data, options)
    )
  );

// export const medicineAutoCartAdd = (cartData) =>
//   createApiAction("MEDICINE_AUTO_CART_ADD", (options) =>
//     fetch(
//       endpoints.MEDICINE_AUTO_CART_ADD,
//       createJsonRequestOptions("POST", cartData, options)
//     )
//   );

// export const medicineAutoCartDelete = (cartData) =>
//   createApiAction("MEDICINE_AUTO_CART_DELETE", (options) =>
//     fetch(
//       endpoints.MEDICINE_AUTO_CART_DELETE,
//       createJsonRequestOptions("POST", cartData, options)
//     )
//   );

// export const orderAutoMedicine = (orderData) =>
//   createApiAction("ORDER_AUTO_MEDICINE", (options) =>
//     fetch(
//       endpoints.ORDER_AUTO_MEDICINE,
//       createJsonRequestOptions("POST", orderData, options)
//     )
//   );

// RADIOLOGY
export const radioCartAdd = (cartData) =>
  createApiAction("RADIO_CART_ADD", (options) =>
    fetch(
      endpoints.RADIO_CART_ADD,
      createJsonRequestOptions("POST", cartData, options)
    )
  );

export const radioRemoveCart = (cartData) =>
  createApiAction("RADIO_REMOVE_CART", (options) =>
    fetch(
      endpoints.RADIO_REMOVE_CART,
      createJsonRequestOptions("POST", cartData, options)
    )
  );

export const radioaddPatientMembers = (patientData) =>
  createApiAction("ADD_PATIENT_MEMBERS", (options) =>
    fetch(
      endpoints.RADIO_ADD_PATIENT_MEMBERS,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const radiodeletePatient = (patient_id) =>
  createApiAction("RADIO_DELETE_PATIENT_MEMBERS", (options) =>
    fetch(
      endpoints.RADIO_DELETE_PATIENT_MEMBERS,
      createJsonRequestOptions("POST", patient_id, options)
    )
  );

export const radiosavePatientMembers = (patientData) =>
  createApiAction("RADIO_SAVE_PATIENT_MEMBERS", (options) =>
    fetch(
      endpoints.RADIO_SAVE_PATIENT_MEMBERS,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const radioCity = (patientData) =>
  createApiAction("RADIO_CITY", (options) =>
    fetch(
      endpoints.RADIO_CITY,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const doctorpatientform = (patientData) =>
  createApiAction("DOCTOR_PATIENT_FORM", (options) =>
    fetch(
      endpoints.DOCTOR_PATIENT_FORM,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const doctorAmountUtilization = (patientData) =>
  createApiAction("DOCTOR_AMOUNT_UTILIZATION", (options) =>
    fetch(
      endpoints.DOCTOR_AMOUNT_UTILIZATION,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const doctorCapturePayment = (patientData) =>
  createApiAction("DOCTOR_CAPTURE_PAYMENT", (options) =>
    fetch(
      endpoints.DOCTOR_CAPTURE_PAYMENT,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const doctorTimeSlot = (patientData) =>
  createApiAction("DOCTOR_TIME_SLOT", (options) =>
    fetch(
      endpoints.DOCTOR_TIME_SLOT,
      createJsonRequestOptions("POST", patientData, options)
    )
  );
export const doctorTimeSlotForm = (patientData) =>
  createApiAction("DOCTOR_TIME_FORM", (options) =>
    fetch(
      endpoints.DOCTOR_TIME_FORM,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const dentalTimeSlot = (patientData) =>
  createApiAction("DENTAL_TIME", (options) =>
    fetch(
      endpoints.DENTAL_TIME,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const dentalSaveForm = (patientData) =>
  createApiAction("DENTAL_FORM", (options) =>
    fetch(
      endpoints.DENTAL_FORM,
      createJsonRequestOptions("POST", patientData, options)
    )
  );
export const dentalUtilization = (patientData) =>
  createApiAction("DENTAL_UTILIZATION", (options) =>
    fetch(
      endpoints.DENTAL_UTILIZATION,
      createJsonRequestOptions("POST", patientData, options)
    )
  );
export const dentalCapturePayment = (patientData) =>
  createApiAction("DENTAL_CAPTURE_PAYMENT", (options) =>
    fetch(
      endpoints.DENTAL_CAPTURE_PAYMENT,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const dentalResedule = (id, patientData) =>
  createApiAction("DENTAL_RESEDULE", (options) =>
    fetch(
      `${endpoints.DENTAL_RESEDULE}${id}`,
      createFormRequestOptions("POST", patientData, options)
    )
  );

// Eye
export const eyeTimeSlot = (patientData) =>
  createApiAction("EYE_TIME", (options) =>
    fetch(
      endpoints.EYE_TIME,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const eyeSaveForm = (patientData) =>
  createApiAction("EYE_FORM", (options) =>
    fetch(
      endpoints.EYE_FORM,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const eyeUtilization = (patientData) =>
  createApiAction("EYE_UTILIZATION", (options) =>
    fetch(
      endpoints.EYE_UTILIZATION,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const eyeCapturePayment = (patientData) =>
  createApiAction("EYE_CAPTURE_PAYMENT", (options) =>
    fetch(
      endpoints.EYE_CAPTURE_PAYMENT,
      createJsonRequestOptions("POST", patientData, options)
    )
  );

export const eyeReschedule = (id, patientData) =>
  createApiAction("EYE_RECHEDULE", (options) =>
    fetch(
      `${endpoints.EYE_RECHEDULE}${id}`,
      createFormRequestOptions("POST", patientData, options)
    )
  );

export const fetchvendor = (type) =>
  createApiAction("VENDOR_API", (options) =>
    fetch(endpoints.VENDOR_API, createFormRequestOptions("POST", type, options))
  );

export const fetchVendorPackageForm = (type) =>
  createApiAction("VENDOR_PACKAGE_API", (options) =>
    fetch(
      endpoints.VENDOR_PACKAGE_API,
      createJsonRequestOptions("POST", type, options)
    )
  );
