import React, { useEffect, useRef, useState } from 'react';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { appCommonlyBookTest } from '../../../../../images';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLabOrderDetails, fetchTimeSlot } from '../../../../../Redux/actions/GetApiCall';
import useLocationState from '../../../../Medicine/hooks/useLocationState';
import PaymentSummaryDetails from '../../OrderMedicinePages/PaymentSummaryDetails';
import Need_Help from '../../../../Medicine/Components/Need_Help';
import { ReorderDiagnosticApi } from '../../../../../Redux/actions/PostApiCall';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import ToastBox from '../../../../Medicine/animation/ToastBox';
import CancelOrderModal from '../../OrderMedicinePages/CancelOrderModal';
import LoaderAnime from '../../../../Medicine/animation/prescription_loader';
import MediLoader from '../../../../Medicine/animation/MediLoader';
import { refund_image, reports_found } from '../../../../Medicine/Images';
import LabTimeData from '../Components/LabTimeData';
import SuccessFull from '../../../../../Animation/SuccessFull';
import { NavLink } from 'react-router-dom';
import { IoHome } from 'react-icons/io5';
import { FaArrowDown } from "react-icons/fa6";
import { Button, Modal } from 'react-bootstrap';

const LabDetailsPage = () => {
    const dispatch = useDispatch();
    const OrderId = useLocationState();
    const OrderDetailsData = useSelector(state => state.lab_order_Data_Details);
    const OrderSummaryData = OrderDetailsData?.data?.response;
    const [showModal, setShowModal] = useState(false);
    const reorderData = useSelector(state => state.reorder_diagnostic)
    console.log(reorderData)
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [showTime, setShowTime] = useState(false)
    const [successmodal, setSuccessModal] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [showModalReport, setShowModalReport] = useState(false);
    const [modalReport, setModalReport] = useState([]);

    const handleShowReport = (report) => {
        setShowModalReport(true);
        setModalReport(report)
    }

    const handleClose = () => setShowModalReport(false);

    useEffect(() => {
        if (OrderId && OrderId !== "")
            dispatch(fetchLabOrderDetails(OrderId?.orderId));
    }, []);

    useEffect(() => {
        dispatch(fetchTimeSlot())
    }, [])

    const refundBoxRef = useRef(null);

    const handleShow = () => setShow(true);

    const handleReorder = async (item) => {
        if (!showTime) {
            setShowTime(true);
            return;
        }
        if (!selectedTimeSlot) {
            toast.error('Please select a time slot.');
            return; // Exit function to avoid API call
        }
        setShowModal(true);
        const data = {
            order_id: item?.order_id,
            date: selectedDate,
            time: selectedTimeSlot
        };

        try {
            await dispatch(ReorderDiagnosticApi(data));

            // setShowModal(false);
        } catch (error) {
            toast.error('An error occurred while processing the reorder.');
        } finally {
            setShowModal(false)
        }
    };

    // Scroll handler to scroll to refund-box-process
    const handleScrollToRefundBox = () => {
        if (refundBoxRef.current) {
            refundBoxRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    useEffect(() => {
        if (reorderData?.data?.success === true) {
            setSuccessModal(true);
            setModalShow(true);
            setTimeout(() => {
                setSuccessModal(false);
                setModalShow(false);
                navigate('/order-details');
                window.location.reload();
            }, 2000);

        } else if (reorderData?.data?.success === false) {
            toast.error(reorderData?.data?.message);
        }
    }, [reorderData])

    const height = "75vh";
    const V2HREF = localStorage.getItem("V2HREF")

    return (
        <div className="home-review-banner">
            <div className="home-app-header order-details-repo-data">
                <div className="header-logo ">
                    <div className="user-name text-center text-repoo">
                        <p>Order Details</p>
                    </div>
                    {!V2HREF && V2HREF === null && (
                        <div className="order-home-repo">
                            <NavLink to={'/diagnostictest'}>
                                <IoHome />
                            </NavLink>
                        </div>
                    )}
                </div>
            </div>
            {showModal && <LoaderAnime showModal={showModal} />}


            {OrderDetailsData?.status === "loading" ? (
                <MediLoader height={height} />
            ) : (
                <>
                    <div className="lab-order-details-wrap">

                        {!showTime && (
                            <div className='lab-order-wrap-details'>
                                {OrderSummaryData?.cancel_data?.length > 0 && (
                                    <div className="refund-data-wrapper lab-refund-data">
                                        <div className="redund-content-box">
                                            <img src={refund_image} alt="refund-icon" />
                                            <p>View your refund status</p>
                                        </div>
                                        <div className="refund-btn">
                                            <button onClick={handleScrollToRefundBox}>Click Here</button>
                                        </div>
                                    </div>
                                )}

                                <div className="lab-booked-order-repo-text">
                                    <h6>Booked on {OrderSummaryData?.booked_at}</h6>
                                    <p>Order ID: {OrderSummaryData?.systemOrderId}</p>
                                    <p className='booked-text'><IoIosCheckmarkCircle /> {OrderSummaryData?.order_message}</p>
                                </div>

                                <hr className='lab-data-hr' />

                                <div className="booked-test-wrapper">
                                    <p className='test-book-test-p'>Booked Tests Details</p>

                                    {
                                        OrderSummaryData?.members?.map((item, index) => (
                                            <div className="booked-wrapper-repo-cover-box" key={item?.report_id}>
                                                {item?.reportData && item?.reportData?.length > 0 && (
                                                    <>
                                                        <div className='report-repo-wrapper-cover'>
                                                            <div className="report-data-repo-class">
                                                                <div className="report-data-content-box">
                                                                    <img src={reports_found} alt="report-found" />
                                                                </div>
                                                                <p>Generated on <br /> {item?.reportData?.[0]?.created_at}</p>
                                                            </div>
                                                            <div className="report-btn-cover-repo-btuton">
                                                                <a href={item?.reportData?.[0]?.generated_filename} target='blank'>
                                                                    VIEW REPORT
                                                                </a>
                                                            </div>
                                                        </div>

                                                        {/* Conditionally show 'View More Reports' if there are more than one */}
                                                        {item?.reportData?.length > 1 && (
                                                            <div className="view-more-reports-btn text-center">
                                                                <button variant="primary" onClick={() => handleShowReport(item?.reportData)}>
                                                                    View All Reports <FaArrowDown />
                                                                </button>
                                                            </div>
                                                        )}
                                                        <hr className='patient-lab-data-hr' />
                                                    </>
                                                )}
                                                <div className="patient-details-wrapper-oreder">
                                                    <p className='patien-text-de'>Patient Details</p>
                                                    <p>Patient Name : {item?.member_name}</p>
                                                    <div className="patient-details-age-wrapper">
                                                        <p>Age : {item?.member_age}</p>
                                                        <p>Gender : {item?.member_gender}</p>
                                                    </div>
                                                </div>
                                                <hr className='patient-lab-data-hr' />

                                                <div className="appoint-date-repo-wrapper">
                                                    <div className="appointment-date-repo">
                                                        <p>Appointment Date</p>
                                                        <p className='bond-text-repo'>{OrderSummaryData?.user_details?.selected_date}</p>
                                                    </div>
                                                    <div className="appointment-date-repo">
                                                        <p>Appointment Time</p>
                                                        <p className='bond-text-repo'>{OrderSummaryData?.user_details?.time_slot}</p>
                                                    </div>
                                                </div>

                                                {item?.diagnosticData?.map((diagnosticItem, index) => (
                                                    <>
                                                        <div className="test-data-content-box">
                                                            <img src={appCommonlyBookTest} alt="" />
                                                            <div className="test-name-data-content">
                                                                <span>{diagnosticItem?.name}</span>
                                                                <p>Test Includes({diagnosticItem?.parameter_count || diagnosticItem?.test_count})</p>
                                                            </div>
                                                        </div>
                                                        {/* Only show <hr /> if it's not the last item */}
                                                        {index !== item?.diagnosticData.length - 1 && <hr />}
                                                    </>
                                                ))}


                                            </div>
                                        ))
                                    }
                                </div>

                                <div className="paymentSummary-data">
                                    <PaymentSummaryDetails TrackSummaryData={OrderSummaryData} />

                                    {OrderSummaryData?.cancel_data?.length > 0 && (
                                        <div className="refund-box-process" ref={refundBoxRef}>
                                            <div className="fund-process-box">
                                                <img src={refund_image} alt="refund-icon" />
                                                <p>Refund Initiated</p>
                                            </div>

                                            {
                                                (() => {
                                                    const refundTotalItem = OrderSummaryData?.cancel_data?.find(item => item?.key === "Refund total");
                                                    return refundTotalItem ? (
                                                        <>
                                                            <div className="fund-total-box">
                                                                <p>Refund Total</p>
                                                                <p>₹ {refundTotalItem?.value?.toLocaleString('en-IN')}</p>
                                                            </div>

                                                            <div className="fund-source">
                                                                <span>Refund Source</span>
                                                            </div>
                                                        </>
                                                    ) : null;
                                                })()
                                            }

                                            {
                                                OrderSummaryData?.cancel_data?.map((item, index) => (
                                                    item?.key !== "Refund total" && item?.value !== 0 && (
                                                        <React.Fragment key={index}>
                                                            <div className="md-func-source-box">
                                                                <p>{item?.key}</p>
                                                                <p>₹ {item?.value?.toLocaleString('en-IN')}</p>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                ))
                                            }
                                        </div>
                                    )}

                                </div>

                                <Need_Help type="medicine" />
                            </div>
                        )}

                        {showTime && (
                            <LabTimeData setSelectedDate={setSelectedDate} setSelectedTimeSlot={setSelectedTimeSlot} />
                        )}

                    </div>


                    {OrderSummaryData?.is_cancelable === 1 && !showTime && (
                        <div className="cancel-order-repo-wrap">
                            <button onClick={handleShow}>CANCEL APPOINTMENT</button>
                        </div>
                    )}

                    {OrderSummaryData?.invoice_url && OrderSummaryData?.invoice_url !== "" && (
                        <div className="cancel-order-repo-wrap download-invoice">
                            <button>DOWNLOAD INVOICE</button>
                        </div>
                    )}

                    {OrderSummaryData?.is_reschedule === 1 && (
                        <div className="reorder-btn-data-repo sticky-bottom">
                            <button onClick={() => handleReorder(OrderSummaryData)}>RESCHEDULE APPOINTMENT</button>
                        </div>
                    )}
                </>
            )
            }
            <CancelOrderModal show={show} setShow={setShow} OrderId={OrderSummaryData?.order_id} labCancelOrderDetails={OrderDetailsData} tabkey={OrderId?.tabKey} />

            {successmodal && (
                <SuccessFull show={modalShow} successMessage={reorderData?.data?.message} animationtype={successmodal} />
            )}
            <ToastBox />


            <Modal show={showModalReport} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>All Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body className='repo-lab-data-box'>
                    {modalReport.map((report, index) => (
                        <>
                            <div key={index} className="report-data-content-modal">
                                <div className="report-data-content-box">
                                    <img src={reports_found} alt="report-found" />
                                    <p>Generated on: {report.created_at}</p>
                                </div>
                                <div className="report-details">
                                    <div className="report-btn-cover-repo-btuton repo-modal-report-data">
                                        <a href={report.generated_filename} target='blank'>
                                            VIEW REPORT
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* <hr className='lab-data-hr'/> */}
                        </>
                    ))}
                </Modal.Body>
            </Modal>

        </div >
    )
}

export default LabDetailsPage