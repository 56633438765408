import React, { useState, useEffect } from "react";
import "../../Medicine/Medicine_Style/cart.css";
import { Need_Help_Image, packAnime_icon } from "../../Medicine/Images";
import { appLabCart, appPackageIcon } from "../../../images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDiagnosticPlaceOrderDetails, capture_diagnostic_payment_details } from "../../../Redux/actions/PostApiCall";
import AppLoader from "../../Medicine/animation/prescription_loader";
import useRazorpay from 'react-razorpay';
import MedicinePopup from "../../Medicine/animation/Medicine_Success";
import { savings_image } from "../../Medicine/Images";
import { patientEdit } from "../../../images";
import { medi_wallet_image } from "../../Medicine/Images";
import Need_Help from "../../Medicine/Components/Need_Help";
import SharModal from "../../Medicine/Components/SharModal";
import ToastBox from "../../Medicine/animation/ToastBox";
import { getQueryParams } from "../../../Redux/actions/GetApiCall";
import toast from "react-hot-toast";
import { IoInformationCircleSharp } from "react-icons/io5";


const DiagnosticOrder = ({ setShowModal, showModal, setSelectedDiagnosticPackageData, placeOrderDiagnosticApiData, setPlaceOrderDiagnosticApiData, selectedDiagnosticPackageData }) => {

  const packagesData = useSelector((state) => state.package_list);
  const Package = JSON.parse(localStorage.getItem("PakageData"));
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const placeOrderDetailsData = useSelector(
    (state) => state.diagnostic_place_order_details_response
  );
  const orderDiagnostic_response = useSelector(
    (state) => state.orderDiagnostic_response
  );

  console.log(placeOrderDetailsData)
  console.log(orderDiagnostic_response)

  useEffect(() => {
    if (orderDiagnostic_response?.error !== null && orderDiagnostic_response?.status === "failure") {
      setShowModal(false)
      toast.error(orderDiagnostic_response?.error)
    }
  }, [orderDiagnostic_response])

  const AutoToken = localStorage.getItem("AUTO_TOKEN")


  const [showShare, setShowShare] = useState(false);
  const handleShareShow = () => setShowShare(true);
  const AppSimulated = localStorage.getItem("is_simulated");

  const capture_diagnostic_payment_details_response = useSelector(
    (state) => state.capture_diagnostic_payment_details_response
  );
  const dispatch = useDispatch();
  const [Razorpay] = useRazorpay();

  const tabDataRepo = "Diagnostic"

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const { agentcode } = getQueryParams(currentUrl);


  const handleSelectPackage = async (e) => {
    const value = e.target.value;

    // If value is empty or falsy, handle it accordingly
    if (!value) {
      const placeOrderApiData = {
        package_id: "",
        wallet_id: "",
        source: 0
      };
      // Fetch with empty package_id and reset selected package data
      await dispatch(fetchDiagnosticPlaceOrderDetails(placeOrderApiData));
      setSelectedDiagnosticPackageData(null);
      setIsChecked(false);
      setShowModal(false);
      return;  // Exit early
    }

    const selectedItem = JSON.parse(value);

    setSelectedDiagnosticPackageData(selectedItem);
    setPlaceOrderDiagnosticApiData((prev) => ({
      ...prev,
      package_id: selectedItem?.id
    }));

    const placeOrderApiData = {
      package_id: selectedItem?.id,
      wallet_id: "",
      source: 0
    };

    await dispatch(fetchDiagnosticPlaceOrderDetails(placeOrderApiData));
    setShowModal(false);
  };

  const handleWalletChange = async (e) => {
    setShowModal(true);

    const isCheckedNow = e.target.checked;
    setIsChecked(isCheckedNow);
    const walletId = isCheckedNow ? selectedDiagnosticPackageData?.wallet_id : "";

    const placeOrderApiData = {
      package_id: selectedDiagnosticPackageData?.id,
      wallet_id: walletId,
      source: 0
    };

    await dispatch(fetchDiagnosticPlaceOrderDetails(placeOrderApiData));
    setShowModal(false);

    setPlaceOrderDiagnosticApiData((prev) => ({
      ...prev,
      wallet_id: isCheckedNow ? selectedDiagnosticPackageData?.wallet_id : null,
    }));
  };

  useEffect(() => {
    if (orderDiagnostic_response?.loader) {
      setShowModal(true);
    }

    if (orderDiagnostic_response?.data?.razorpay_details === null && orderDiagnostic_response?.data?.success === true) {
      setShowModal(false);
      setShowSuccessModal(true);

      setTimeout(() => {
        const data = {
          orderId: orderDiagnostic_response?.data?.diagnostic_order_id,
          tabKey: "second",
        };
        if (AutoToken) {
          navigate(`/thank-you?auto=${AutoToken}`, { state: data });
        }
        else {
          navigate("/thank-you", { state: data });
        }
      }, 2000);
    }
    else if (orderDiagnostic_response?.data?.success === true && orderDiagnostic_response?.data?.is_payable === 1 && orderDiagnostic_response?.status === "success") {
      const options = {
        ...orderDiagnostic_response.data.razorpay_details,
        order_id: orderDiagnostic_response.data.razorpay_details?.razorpay_order_id,
        handler: function async(response) {
          dispatch(capture_diagnostic_payment_details({
            transaction_id: response.razorpay_payment_id,
            diagnostic_order_id: orderDiagnostic_response?.data?.razorpay_details?.diagnostic_order_id
          }));
        },
        modal: {
          ondismiss: function () {
            setShowModal(false);
            setShowSuccessModal(true);
            setTimeout(() => {
              setShowSuccessModal(false)
            }, 3000);
          }
        }
      };

      console.log(options);
      console.log(orderDiagnostic_response.data.razorpay_details)

      const rzp1 = new Razorpay(options);
      rzp1.open();
    }
  }, [orderDiagnostic_response]);


  useEffect(() => {
    if (capture_diagnostic_payment_details_response?.loader) {
      setShowModal(true);
    }
    if (capture_diagnostic_payment_details_response?.status === "success") {
      setShowModal(false);
      // setShowSuccessModal(true);

      const data = {
        orderId: orderDiagnostic_response?.data?.razorpay_details?.diagnostic_order_id,
        tabKey: "second",
      };
      if (AutoToken) {
        navigate(`/thank-you?auto=${AutoToken}`, { state: data });
      }
      else {
        navigate("/thank-you", { state: data });
      }
    }
    else if (capture_diagnostic_payment_details_response?.status === "failure") {
      // setShowSuccessModal(true);
      setTimeout(() => {
        // navigate('/diagnostictest');
        setShowSuccessModal(false);
      }, 3000)
    }
  }, [capture_diagnostic_payment_details_response])

  useEffect(() => {
    if (orderDiagnostic_response?.data?.diagnostic_order_id && orderDiagnostic_response?.data?.razorpay_details === null) {
      const data = {
        orderId: orderDiagnostic_response?.data?.diagnostic_order_id,
        tabKey: "second",
      };
      if (AutoToken) {
        navigate(`/thank-you?auto=${AutoToken}`, { state: data });
      }
      else {
        navigate("/thank-you", { state: data });
      }
    }
  }, [orderDiagnostic_response]);



  useEffect(() => {
    if (capture_diagnostic_payment_details_response?.data !== null && capture_diagnostic_payment_details_response?.status === "idle" && orderDiagnostic_response?.data?.razorpay_details?.diagnostic_order_id) {
      const data = {
        orderId: orderDiagnostic_response?.data?.razorpay_details?.diagnostic_order_id,
        tabKey: "second",
      };
      if (AutoToken) {
        navigate(`/thank-you?auto=${AutoToken}`, { state: data });
      }
      else {
        navigate("/thank-you", { state: data });
      }
    }
  }, [capture_diagnostic_payment_details_response])

  const handleNavigate = () => {
    const data = {
      patientCount: placeOrderDetailsData?.data?.response?.members?.length,
      tabkey: "diagnostics"
    }
    navigate('/address-list', { state: data });
  }

  console.log(placeOrderDetailsData)

  useEffect(() => {
    if (placeOrderDetailsData && placeOrderDetailsData?.error !== null && placeOrderDetailsData?.status === "failure") {
      toast.error(placeOrderDetailsData?.error);
      setTimeout(() => {
        navigate("/diagnostictest")
      }, 1500);
    }
  }, [placeOrderDetailsData])

  console.log(placeOrderDetailsData)

  const payableAmountObj = placeOrderDetailsData?.data?.response?.calculation?.find(
    item => item.key === "Total payable price"
  );

  // Access the value from the found object, if it exists
  const payableAmount = payableAmountObj ? payableAmountObj.value : null;
  const capitalizeFirstLetter = (str) => {
    if (!str) return ''; // Return an empty string if the input is undefined or null
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (packagesData?.data) {
      const data = packagesData?.data?.active_package.find(item => item?.id === Package?.id);
      setSelectedDiagnosticPackageData(data)
    }
  }, [packagesData])


  return (
    <div className="diagnostics-cart-main-cover-wrapper px-2 mt-2">
      <div className="lab-test-tab-content-box">
        {showSuccessModal && (
          <MedicinePopup showModal={showSuccessModal} status={false} message={"Payment Not Completed"} />
        )}
        {placeOrderDetailsData?.data?.response?.members?.length > 0 ? (
          <div className="lab-Cart-data-main-wrapper">
            {showModal && <AppLoader showModal={showModal} />}
            <div class="number-of-package-content">
              <p>{placeOrderDetailsData?.data?.response?.members?.length} Health Package / Lab Test</p>
            </div>
            <div className="patient-test-details-wrapper">
              {placeOrderDetailsData?.data?.response?.members?.map((memberData) => {
                return (
                  <div className="patient-full-detail-payment">
                    <div className="patient-name-heading">
                      <div className="patient-full-name">
                        <p>{memberData?.member_name}</p>
                      </div>
                    </div>
                    <hr />
                    {memberData?.diagnosticData?.map((selectedTestData) => {
                      return (
                        <div className="patient-package-detail-cover">
                          <div className="package-icon">
                            <img
                              src={appPackageIcon}
                              width="100%"
                              alt="review"
                            />
                          </div>
                          <div className="package-details-content" style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>{selectedTestData?.name}</p>

                            {selectedTestData?.type == "package" ? (
                              <span>Included Tests : {selectedTestData?.test_count || 0}</span>
                            ) : (
                              <span>Included Tests : {selectedTestData?.parameter_count || 0}</span>
                            )}
                            {selectedTestData?.is_package_excluded === 1 && (
                              <div className="product-qty">
                                <p style={{ color: "red" }}>
                                  Excluded benefit <IoInformationCircleSharp className="info-icon" />
                                </p>
                                <div className="tooltip-bankai-repo-text">The product is excluded from package benefit</div>
                              </div>
                            )}
                          </div>
                          <div className="package-price-boxs order-price">
                            {selectedTestData?.medibhai_price == 0 ? (
                              <p className="free-card-pin">Free</p>
                            ) : (
                              <>
                                <p>₹ {selectedTestData?.medibhai_price}</p>

                              </>
                            )}

                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>

            {
              AppSimulated !== null &&
              AppSimulated === "1" && (
                <div className="share-box" onClick={handleShareShow}>
                  <div className="content-share-repo">
                    <h6>Share Your Appointment</h6>
                    <p>Let employee know about this appointment!</p>
                  </div>
                  <button aria-label="Share Your Order">
                    Click here
                  </button>
                </div>
              )}

            {showShare && (
              <SharModal
                setShowShare={setShowShare}
                showShare={showShare}
                Data={placeOrderDetailsData?.data}
                tabDataRepo={tabDataRepo}
              />
            )}

            <div className="patient-full-address-box mb-3">
              <div className="patient-address-heading">
                <h4>
                  {placeOrderDetailsData?.data?.response?.address?.address_type === 1 && "Home Address"}
                  {placeOrderDetailsData?.data?.response?.address?.address_type === 2 && "Office Address"}
                  {placeOrderDetailsData?.data?.response?.address?.address_type === 3 && "Other Address"}
                </h4>
                <a onClick={() => handleNavigate()}>

                  <p>
                    <span>
                      <img src={patientEdit}></img>
                    </span>
                    Edit </p>
                </a>
              </div>
              <div className="patient-address-from">
                <p className="user-name-text">{placeOrderDetailsData?.data?.response?.address?.name}</p>
                <p>
                  {placeOrderDetailsData?.data?.response?.address?.mobile_number} <span>{placeOrderDetailsData?.data?.response?.address?.email_id}</span>
                </p>
                <p>{placeOrderDetailsData?.data?.response?.address?.address || ''},{placeOrderDetailsData?.data?.response?.address?.landmark || ''}, <span>
                  {capitalizeFirstLetter(placeOrderDetailsData?.data?.response?.address?.city)}
                </span>, <span>
                    {capitalizeFirstLetter(placeOrderDetailsData?.data?.response?.address?.state)}
                  </span> -<span> {placeOrderDetailsData?.data?.response?.address?.pincode}</span></p>
              </div>
              <div className="patient-time-date">
                <p>
                  <span>Date : </span> {placeOrderDetailsData?.data?.response?.date}
                </p>
                <p>
                  <span>Time : </span>  {placeOrderDetailsData?.data?.response?.time}
                </p>
              </div>
            </div>

            {Package?.id !== "" && (
              packagesData?.data?.active_package?.length > 0 && (
                <div className="packages-cover-wrapper mt-4">
                  <div className="options-wrapper">
                    <img src={packAnime_icon} alt="" />
                    <select value={selectedDiagnosticPackageData ? JSON.stringify(selectedDiagnosticPackageData) : ''} disabled>
                      <option value="">{Package?.name}</option> {/* Default selected and disabled */}
                    </select>

                  </div>
                </div>
              )
            )}
            {selectedDiagnosticPackageData !== "" && selectedDiagnosticPackageData !== undefined && selectedDiagnosticPackageData?.wallet_details?.diagnostic && (
            <>
              <div className="wallet-repo-wrapper">
                <div className="check-data-wrapper">
                  <div className="checkbox-wrapper">
                    <input
                      id="terms-checkbox-37"
                      name="checkbox"
                      type="checkbox"
                      checked={placeOrderDiagnosticApiData?.wallet_id}
                      onChange={handleWalletChange} // Handle change event directly
                    />
                    <label className="terms-label" htmlFor="terms-checkbox-37">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 200 200"
                        className="checkbox-svg"
                      >
                        <mask fill="white" id="path-1-inside-1_476_5-37">
                          <rect height="200" width="200"></rect>
                        </mask>
                        <rect
                          mask="url(#path-1-inside-1_476_5-37)"
                          strokeWidth="40"
                          className="checkbox-box"
                          height="200"
                          width="200"
                        ></rect>
                        <path
                          strokeWidth="15"
                          d="M52 111.018L76.9867 136L149 64"
                          className="checkbox-tick"
                        ></path>
                      </svg>
                    </label>
                  </div>
                  <div className="wrapper-text-content-box">
                    <p>Medibhai Wallet</p>
                    <span>
                      Remaining Balance : ₹{" "}
                      {selectedDiagnosticPackageData?.wallet_details?.diagnostic?.remaining}
                    </span>
                  </div>
                </div>
                <div className="used-data-wrapper">
                  <img src={medi_wallet_image} alt="" />
                </div>
              </div>

              {isChecked && payableAmount > 0 && (
                <div className="wallet-heading-insu">
                  <p>
                    Your wallet balance is insufficient ₹{payableAmount}  payment is required online
                  </p>
                </div>
              )}
            </>
            )}
            <div className="payment-repo-cover-box mx-1">
              <div className="payment-text-repo">
                <p>Payment Summary</p>
              </div>
              <div className="payment-summary-box-repo">
                {placeOrderDetailsData &&
                  placeOrderDetailsData?.data?.response.calculation
                    .filter(
                      (lab_cal_Data) =>
                        lab_cal_Data.key !== "Total payable price" &&
                        lab_cal_Data.key !== "Total saving"
                    )
                    .map((filteredData) => (
                      <div
                        className="price-payment-sum-repo"
                        key={filteredData.key}
                      >
                        <p>{filteredData.key}</p>
                        <p>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 0,
                          }).format(filteredData.value)}
                        </p>
                      </div>
                    ))}

                {placeOrderDetailsData?.data?.wallet_deducted?.length > 0 && (
                  <div className="price-payment-sum-repo">
                    {/* Access wallet_deducted correctly */}
                    <p>{placeOrderDetailsData?.data?.wallet_deducted[0]?.display_text}</p>
                    <p>
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 0,
                      }).format(placeOrderDetailsData?.data?.wallet_deducted[0]?.amount)}
                    </p>
                  </div>
                )}

              </div>
              <div className="fin-text-repo">
                {placeOrderDetailsData &&
                  placeOrderDetailsData?.data?.response?.calculation
                    .filter(
                      (lab_cal_Data) =>
                        lab_cal_Data.key === "Total payable price" ||
                        lab_cal_Data.key === "Total saving"
                    )
                    .map((filteredData) => (
                      <div
                        className={`price-payment-sum-repo ${filteredData.key === "Total payable price"
                          ? "total-pay-text"
                          : "total-save-text"
                          }`}
                        key={filteredData.key}
                      >
                        <p>
                          {filteredData.key == "Total saving" && (
                            <img src={savings_image}></img>
                          )}
                          {filteredData.key}</p>
                        <p>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 0,
                          }).format(filteredData.value)}
                        </p>
                      </div>
                    ))}
              </div>
            </div>
            <Need_Help type="diagnostic" />
          </div>
        ) : (
          <div className="medicines-empty-cart-cover">
            <div className="cart-box-image d-flex flex-column justify-content-center align-items-center">
              <div className="empty-cart-image">
                <img src={appLabCart} width="100%" alt="EmptyCart" />
              </div>
              <div className="cart-content d-flex flex-column justify-content-center align-items-center">
                <p>Your Cart is empty</p>
                <Link to="/diagnostictest">
                  <button>
                    <p>ADD TESTS</p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastBox />
    </div>
  );
};

export default DiagnosticOrder;
