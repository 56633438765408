import React, { useCallback, useEffect, useState } from 'react';
import { headerLogo } from '../../../images';
import { FiSearch } from 'react-icons/fi';
import { DoctorEyeImage, hospitalIcon } from '../../Medicine/Images';
import { FaAngleRight } from 'react-icons/fa';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDoctorList, fetchOPDDepartmentsList } from '../../../Redux/actions/GetApiCall';
import MediLoader from '../../Medicine/animation/MediLoader';
import { useNavigate } from 'react-router';
import NoDoctorFoundAnimation from '../../Medicine/animation/NoDoctorFoundAnimation';
import useInfiniteScroll from '../../../utils/useInfiniteScroll';
import useLocationState from '../../Medicine/hooks/useLocationState';

const SearchDoctor = () => {
    const [inputValue, setInputValue] = useState('');
    const { data: SearchData, loader: SearchLoader } = useSelector(state => state?.doctor_list);
    const { data: departmentData, loader: DepartmentLoader } = useSelector(state => state?.opd_department_list_api);
    const dispatch = useDispatch();
    const [pageNo, setPageNo] = useState(1);
    const [searchResult, setSearchResult] = useState([]);
    const [departmentResult, setDepartmentResult] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [searchTriggered, setSearchTriggered] = useState(false);
    const navigate = useNavigate();
    const name = useLocationState();

    // ✅ Debounce API calls on user input
    const debounceFetchDoctorList = useCallback(
        debounce((value) => {
            if (value.trim() !== '') {
                setSearchResult([]);
                setPageNo(1);
                setHasMore(true);
                setSearchTriggered(true);
            } else {
                setSearchTriggered(false);
                setHasMore(false);
            }
        }, 800),
        []
    );

    useEffect(() => {
        if (inputValue !== "") {
            debounceFetchDoctorList(inputValue);
        } else {
            setSearchResult([]);
            setHasMore(false);
            setSearchTriggered(false);
        }
    }, [inputValue, debounceFetchDoctorList]);

    useEffect(() => {
        if (name?.name === "department" && searchTriggered) {
            dispatch(fetchOPDDepartmentsList(inputValue))
        }
        else {
            if (searchTriggered) {
                dispatch(fetchDoctorList("", inputValue, pageNo));
            }
        }
    }, [pageNo, dispatch, inputValue, searchTriggered]);

    useEffect(() => {
        if (name?.name === "department") {
            if (departmentData) {
                setDepartmentResult(departmentData?.data?.department);
            }
        }
        else {
            if (SearchData) {
                const newResults = [...(SearchData?.data || []), ...(SearchData?.hospital || [])];
                setSearchResult(prev => (pageNo === 1 ? newResults : [...prev, ...newResults]));
                setHasMore(pageNo < SearchData?.total_pages);
            }
        }
    }, [SearchData, departmentData]);


    const handleSearch = (Data) => {
        setInputValue('');
        console.log(Data);

        if (Data?.hospital_id) {
            const hostData = {
                id: Data?.hospital_id
            }
            navigate('/hospital-details', { state: hostData });
        } else {
            // Navigate to doctor-details if hospital_id does not exist
            const data = {
                doctor_id: Data?.doctor_id,
                departments: Data?.departments,
                hospitalID: Data?.hospitalDetails?.[0]?.hospital_id,
            };
            navigate('/doctor-detail', { state: data });
        }
    };

    const handleDepartmentSearch = (Data) => {
        if (Data) {
            navigate('/doctor', { state: Data?.id })
        }
    }
    const handleFormRepo = () => {
        navigate("/opd-form", { state: "Doctor" })
    }


    // ✅ Use custom hook for infinite scrolling
    const containerRef = useInfiniteScroll(() => setPageNo(prev => prev + 1), hasMore, SearchLoader);

    return (
        <div className='home-review-banner'>
            {/* 🔹 HEADER */}
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>
                            Search {name?.name === "department" ? "Department" : "Doctor"}
                        </p>
                    </div>
                </div>
            </div>

            {/* 🔹 SEARCH BAR */}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder={name?.name === "department" ? "Search your department..." : "Search your doctors, hospital..."}
                    onChange={(e) => setInputValue(e.target.value)}
                    ref={containerRef}
                />
                <FiSearch />
            </div>

            {/* 🔹 LOADING STATE */}
            {SearchLoader && pageNo === 1 ? (
                <MediLoader height="75%" />
            ) : (
                <div className="data-repo-cover-medi-search" ref={containerRef} style={{ height: '680px', overflowY: 'auto' }}>

                    {/* 🔹 UI for Departments */}
                    {name?.name === "department" ? (
                        inputValue.trim() !== '' && departmentResult.length > 0 ? (
                            departmentResult.map((dept, index) => (
                                <div className="search-result-data-box-cover" key={index} onClick={() => handleDepartmentSearch(dept)}>
                                    <div className="search-data-box-cont">
                                        <img
                                            src={dept?.file_name ? dept?.file_name : DoctorEyeImage}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = DoctorEyeImage;
                                            }}
                                            alt="department-icon"
                                        />
                                        <p className='ellipsis-text'>{dept?.name}</p>
                                    </div>
                                    <div className="search-left-arrow-box">
                                        <FaAngleRight />
                                    </div>
                                </div>
                            ))
                        ) : inputValue.trim() !== '' && (departmentResult?.length === 0) ? (
                            // Show OOPS when no search results are found
                            <p className='no-medicine-found-bankai' style={{ height: '65vh' }}>
                                <span style={{ fontSize: '15px' }}>OOPS</span>
                                <span className='repo-bank-ichigo' style={{ fontSize: '15px' }}>No such request found</span>
                                <span className='click-bankai-span' style={{ fontSize: '16px' }} onClick={handleFormRepo}>
                                    Please click below to schedule the appointment
                                </span>
                            </p>
                        ) : (
                            <NoDoctorFoundAnimation />
                        )
                    ) : (
                        inputValue.trim() !== '' && searchResult.length > 0 ? (
                            searchResult.map((item, index) => (
                                <div className="search-result-data-box-cover" key={index} onClick={() => handleSearch(item)}>
                                    <div className="search-data-box-cont">
                                        <img src={item?.doctor_name ? DoctorEyeImage : hospitalIcon} alt="doctor/hospital-icon" />
                                        <p className='ellipsis-text'>{item?.doctor_name || item?.hospital_name}</p>
                                    </div>
                                    <div className="search-left-arrow-box">
                                        <FaAngleRight />
                                    </div>
                                </div>
                            ))
                        ) : inputValue.trim() !== '' && (searchResult?.length === 0) ? (
                            // Show OOPS when no search results are found
                            <p className='no-medicine-found-bankai' style={{ height: '65vh' }}>
                                <span style={{ fontSize: '15px' }}>OOPS</span>
                                <span className='repo-bank-ichigo' style={{ fontSize: '15px' }}>No such request found</span>
                                <span className='click-bankai-span' style={{ fontSize: '16px' }} onClick={handleFormRepo}>
                                    Please click below to schedule the appointment
                                </span>
                            </p>
                        ) : (
                            <NoDoctorFoundAnimation />
                        )
                    )}


                    {/* 🔹 Infinite Scroll Loader */}
                    {SearchLoader && pageNo > 1 && <MediLoader height="150px" />}
                </div>
            )}
        </div>
    );

};

export default SearchDoctor;
