import React, { useEffect, useState } from "react";
import {
  headerLogo,
} from "../../images";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Loader from "../../Animation/Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotificationData } from "../../Redux/actions/NotificationSlice";
import MediLoader from "../Medicine/animation/MediLoader";
import { useNavigate } from "react-router";

const Notification = () => {
  const dispatch = useDispatch();
  const [allNotificationData, setAllNotificationData] = useState([])
  const [orderNotificationData, setOrderNotificationData] = useState([])
  const { data, loading, error } = useSelector(state => state.notification)
  console.log(data)
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchNotificationData())
  }, [])

  const height = "75vh"

  const handleOrder = (data) => {
    console.log(data)

    switch (data?.redirection_data?.module_name) {
      case "diagnostics":
        const dataRepo = {
          orderId: data?.redirection_data?.order_id,
        }
        navigate("/lab-order-details", { state: dataRepo })
        break;

      case "opd":
        const dataOpd = {
          orderId: data?.redirection_data?.order_id,
        }
        navigate("/doctor-order-details", { state: dataOpd })
        break;

      case "teleconsultation":
        const datatele = {
          orderId: data?.redirection_data?.order_id,
        }
        navigate("/doctor-order-details", { state: datatele })
        break;

      case "teleconsultation_meet":
        window.location.href = `https://meet.medibhai.com/?user=${data?.user_id}&room=${data?.redirection_data?.order_id}`
        break;

      case "teleconsultationreports":
        navigate("/health-records", { state: 0 })
        break;

      case "diagnostics_reports":
        navigate("/health-records", { state: 1 })
        break;

      case "eye":
        const dataEye = {
          orderId: data?.redirection_data?.order_id,
        }
        navigate("/eye-order-details", { state: dataEye })
        break;
      case "dental":
        const datadental = {
          orderId: data?.redirection_data?.order_id,
        }
        navigate("/dental-order-details", { state: datadental })
        break;

      case "medicine":
        const DataId = {
          medicine_order_id: data?.order_id,
          onemg_order_id: data?.onemg_order_id
        }
        const splitId = {
          order_id: data?.order_id
        }
        if (data?.is_order_splitted === 1) {
          navigate("/split-order-details", { state: splitId })
        }
        else {
          navigate("/shipment-details", { state: DataId })
        }
        break;

      default:
        break;
    }
  }

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header-new sticky-top">
          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name-text-repo">
              <h6>Notification</h6>
            </div>
          </div>
        </div>

        <div className="medicine-cover mt-3">
          <div className="container">
            <div className="tab-main-cover-wrapper mb-5">
              <Tab.Container id="left-tabs-example" defaultActiveKey='first'>
                <Row>
                  <Col lg={12} className="mb-2 tab-cover-repo box-notifi">
                    <div className="cart-header-tabs d-flex justify-content-center gap-3">
                      <Nav
                        variant="pills"
                        className="flex-column tab-box-main-repo"
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="first"
                          // onSelect={() => setKey("first")}
                          // onClick={() => handleClickTab("first")}
                          >
                            <div className="notification-box">
                              <div className="noti-box-heading">
                                <p>All Updates</p>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Nav
                        variant="pills"
                        className="flex-column tab-box-main-repo"
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="second"
                          // onSelect={() => setKey("second")}
                          // onClick={() => handleClickTab("second")}
                          >
                            <div className="notification-box">
                              <div className="noti-box-heading">
                                <p>Order Updates</p>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Col>
                  {loading ? (
                    <MediLoader height={height} />
                  ) : (
                    <Col lg={12} className="placebox">
                      <div className="tab-content-main-cover">
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <div className="all-updates-box">
                              {data?.notifications?.all_notification?.map((item, index) => (
                                <div className="today-box-content" key={index}>
                                  <div className="heading-noti">
                                    <p className="text-center">{item?.day}</p>
                                    {item.notifications_list !== null && item.notifications_list.map((item, index) => (
                                      <div className="content-box" key={index} style={{
                                        border: `1px solid #${item?.color}`,
                                        boxShadow: `0 0px 0px 0 #${item?.color}, 0 1px 1px 0 #${item?.color}`
                                      }}>
                                        <div className="order-box">
                                          <div className="image-data-cop">
                                            <div className="image-box" style={{ background: `#${item?.color}` }}>
                                              <img src={item.icon} alt="" />
                                            </div>
                                            <div className="heading">
                                              <h6 style={{ color: `#${item?.color}` }}>
                                                {item.caption}
                                              </h6>
                                              <p>{item.title}</p>
                                            </div>
                                          </div>
                                          <div className="content-para">
                                            <p>{item.created_at}</p>
                                          </div>
                                        </div>
                                        <div className="order-content-data-box">
                                          <div className="cop-image-repo">
                                            <img src={item.image} alt="" />
                                          </div>
                                          <div className="cop-image-text">
                                            <a href={item?.redirect_url}>
                                              <p>{item?.redirect_url}</p>
                                            </a>
                                          </div>
                                          <div className="cop-desc-text">
                                            <p>{item?.description}</p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                </div>
                              ))}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <div className="all-updates-box">
                              {data?.notifications?.order_notification?.map((item, index) => (
                                <div className="today-box-content" key={index}>
                                  <div className="heading-noti">
                                    <p className="text-center">{item?.day}</p>
                                    {item.notifications_list !== null && item.notifications_list.map((item, index) => (
                                      <div className="content-box" key={index} onClick={() => handleOrder(item)} style={{
                                        border: `1px solid #${item?.color}`,
                                        boxShadow: `0 0px 0px 0 #${item?.color}, 0 1px 1px 0 #${item?.color}`
                                      }}>
                                        <div className="order-box">
                                          <div className="image-data-cop">
                                            <div className="image-box" style={{ background: `#${item?.color}` }}>
                                              <img src={item.icon} alt="" />
                                            </div>
                                            <div className="heading">
                                              <h6 style={{ color: `#${item?.color}` }}>
                                                {item.caption}
                                              </h6>
                                              <p>{item.title}</p>
                                            </div>
                                          </div>
                                          <div className="content-para">
                                            <p>{item.created_at}</p>
                                          </div>
                                        </div>
                                        <div className="order-content-data-box">
                                          <div className="cop-image-repo">
                                            <img src={item.image} alt="" />
                                          </div>
                                          <div className="cop-image-text">
                                            <a href={item?.redirect_url}>
                                              <p>{item?.redirect_url}</p>
                                            </a>
                                          </div>
                                          <div className="cop-desc-text">
                                            <p>{item?.description}</p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                </div>
                              ))}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </Col>
                  )}
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
