import createApiReducer from "../ApiReducer/ApiReducer";


// GET METHODS APIS
export const medicineReducer = createApiReducer('FETCH_MEDICINE');
export const medicineListReducer = createApiReducer('FETCH_MEDICINE_LIST');
export const medicineRecentListReducer = createApiReducer('FETCH_MEDICINE_RECENT_LIST');
export const prescriptionListReducer = createApiReducer('FETCH_PRESCRIPTION_LIST');
export const medicineCartDetailsReducer = createApiReducer('FETCH_MEDICINE_CART_LIST');
export const singleMedicineReducer = createApiReducer('FETCH_SINGLE_MEDICINE_LIST');
export const offerListReducer = createApiReducer('FETCH_OFFER_LIST');
export const packageListReducer = createApiReducer('FETCH_PACKAGE_LIST');
export const medicineAddressReducer = createApiReducer('FETCH_MEDICINE_ADDRESS');
export const pincodeReducer = createApiReducer('FETCH_PINCODE');
export const orderlistReducer=createApiReducer('FETCH_MEDICINE_ORDER_LIST');
export const orderHistory=createApiReducer('FETCH_HISTORY');
export const trackOrderReducer=createApiReducer('FETCH_TRACK_ORDER');
export const LabOrderReducer=createApiReducer('FETCH_LAB_ORDER');
export const RadiologyOrderReducer=createApiReducer('FETCH_RADIOLOGY_ORDER');
export const LabOrderDetailsReducer=createApiReducer('FETCH_LAB_ORDER_LIST');
export const RadiologyOrderDetailsReducer=createApiReducer('FETCH_RADIOLOGY_ORDER_LIST');
export const RadiologylistDetailsReducer=createApiReducer('FETCH_RADIOLOGY_LIST');
export const HomeReducer=createApiReducer('FETCH_HOME');
export const TeleconsultationListReducer=createApiReducer('FETCH_TELECONSULTATION_LIST');
export const TeleconsultationListDetailsReducer=createApiReducer('FETCH_TELECONSULTATION_LIST_DETAILS');
export const WellnessFormReducer=createApiReducer('FETCH_WELLNESS_FORM');
export const PackageDetailReducer=createApiReducer('FETCH_PACKAGE_DETAIL');
export const ThankYouReducer=createApiReducer('FETCH_THANK_YOU');




// POST METHODS APIS
export const uploadPrescriptionReducer = createApiReducer('UPLOAD_PRESCRIPTION');
export const ambulanceReducer = createApiReducer('AMBULANCE_API');
export const deletePrescriptionReducer = createApiReducer('DELETE_PRESCRIPTION');
export const medicineCartData = createApiReducer('MEDICINE_CART_ADD');
export const medicineCartDelete = createApiReducer('MEDICINE_CART_DELETE');
export const orderMedicineReducer = createApiReducer('ORDER_MEDICINE');
export const captureOrderMedicineReducer = createApiReducer('CAPTURE_ORDER_MEDICINE');
export const cancelOrderMedicineReducer = createApiReducer('CANCEL_ORDER_MEDICINE');
export const cancelOrderDiagnosticReducer = createApiReducer('CANCEL_ORDER_DIAGNOSTIC');
export const cancelOrderRadiologyReducer = createApiReducer('CANCEL_ORDER_RADIOLOGY');
export const ReOrderMedicineReducer = createApiReducer('REORDER_ORDER_MEDICINE');
export const ReOrderDiagnosticReducer = createApiReducer('REORDER_ORDER_DIAGNOSTIC');
export const ReOrderRadiologyReducer = createApiReducer('REORDER_ORDER_RADIOLOGY');
export const saveuploadprescriptionReducer = createApiReducer('SAVE_UPLOAD_PRESCRIPTION');
export const rebookTeleconsultation = createApiReducer('TELECONSULTATION_REBOOK_FORM');
export const rebookOpd = createApiReducer('OPD_REBOOK_FORM');
export const boatandyodareducer = createApiReducer('BOAT_AND_YOGA');
export const reimbursementreducer = createApiReducer('REIMBURSEMENT_DATA');
export const reimbursementformreducer = createApiReducer('REIMBURSEMENT_FORM_DATA');
export const savereimbursementreducer = createApiReducer('SAVE_REIMBURSEMENT');
export const HelpApireducer = createApiReducer('HELP_API');
export const FitnessProreducer = createApiReducer('FITNESS_PRO');

// export const packageDescriptionReducer = createApiReducer('FETCH_PACKAGE_DESCRIPTION')
export const callDoctor = createApiReducer('CALL_DOCTOR');
export const saveAddress = createApiReducer('SAVE_MEDICINE_ADDRESS');
export const deleteAddress = createApiReducer('DELETE_MEDICINE_ADDRESS');
export const selectedAddress = createApiReducer('SELECTED_MEDICINE_ADDRESS');

// DIAGNOSTICS -------------------------
export const searchDiagnosticTest = createApiReducer('SEARCH_DIAGNOSTIC_TEST');
export const diagnosticCartDetailsReducer = createApiReducer('DIAGNOSTIC_CART_DETAILS')
export const radioCartDetailsReducer = createApiReducer('RADIO_CART_DETAILS')
export const diagnosticCartAddReducer = createApiReducer('DIAGNOSTIC_CART_ADD');
export const diagnosticRemoveCartReducer = createApiReducer('DIAGNOSTIC_REMOVE_CART');
export const patientmemberlistReducer = createApiReducer('PATIENT_MEMBERS_LIST');
export const addPatientMembersReducers = createApiReducer('ADD_PATIENT_MEMBERS');
export const deletePatientReducer = createApiReducer('DELETE_PATIENT_MEMBERS');
export const savePatientMembersReducers = createApiReducer('SAVE_PATIENT_MEMBERS');
export const getTimeslotReducers = createApiReducer('GET_TIME_SLOTS');
export const getTimeslotRadioReducers = createApiReducer('RADIO_GET_TIME_SLOTS');
export const getDiagnosticTestListReducers = createApiReducer('DIAGNOSTIC_TEST_LIST');
export const getRadioTestListReducers = createApiReducer('RADIO_TEST_LIST');
export const getPackageListReducers = createApiReducer('DIAGNOSTIC_PACKAGE_LIST');
export const getRadioPackageListReducers = createApiReducer('RADIOLOGY_PACKAGE_LIST');
export const diagnosticOrderDetailsReducers = createApiReducer('DIAGNOSTIC_PLACE_ORDER_DETAILS');
export const radiologyOrderDetailsReducers = createApiReducer('RADIOLOGY_PLACE_ORDER_DETAILS');
export const saveSlotTimeReducers = createApiReducer('SAVE_TIME_SLOT');
export const saveSlotTimeRadioReducers = createApiReducer('SAVE_TIME_SLOT_RADIO');
export const orderDiagnosticReducers = createApiReducer('ORDER_DIAGNOSTIC');
export const orderRadiologyReducers = createApiReducer('ORDER_RADIOLOGY');
export const selectedDiganosticAddressReducers = createApiReducer('SELECTED_DIAGNOSTIC_ADDRESS');
export const captureDiagnosticPaymentDetailsReducers = createApiReducer('CAPTURE_DIAGNOSTIC_PAYMENT_DETAILS');
export const captureRadiologyPaymentDetailsReducers = createApiReducer('CAPTURE_RADIOLOGY_PAYMENT_DETAILS');
export const captureTeleconsultPaymentDetailsReducers = createApiReducer('CAPTURE_TELECONSULT_PAYMENT_DETAILS');
export const categoriesListReducers = createApiReducer('GET_CATEGORIES_TEST');
export const test_descriptions_reducers = createApiReducer('TEST_DESCRIPTION');
export const getpackageDescription_reducers = createApiReducer('PACKAGE_DESCRIPTION');
export const getpackageRadio_reducers = createApiReducer('RADIO_PACKAGE_DESCRIPTION');
export const uploadDiagnosticPrescription_reducers = createApiReducer('UPLOAD_DIAGNSOTIC_PRESCRIPTION');
export const deleteDiagnosticPrescriptionReducer = createApiReducer('DELETE_DIAGNOSTIC_PRESCRIPTION');
export const uploadRadiologyPrescriptionReducer = createApiReducer('UPLOAD_RADIOLOGY_PRESCRIPTION');
export const deleteRadiologyPrescriptionReducer = createApiReducer('DELETE_RADIOLOGY_PRESCRIPTION');

export const fetchDiagnosticPrescription_list_reducers = createApiReducer('FETCH_DIAGNOSTIC_PRESCRIPTION_LIST');
export const fetchRadiologyPrescription_list_reducers = createApiReducer('FETCH_RADIOLOGY_PRESCRIPTION_LIST');
export const wallet_utilization_details_reducers = createApiReducer('WALLET_UTILIZATION_DETAILS');
export const get_consultation_reducers = createApiReducer('CONSULTATION_DETAILS');
export const utilize_amount_reducers = createApiReducer('UTILIZE_TELECONSULT_AMOUNT');
export const health_record_reducers = createApiReducer('FETCH_HEALTH_RECORDS');
export const lab_reports_reducers = createApiReducer('FETCH_LAB_REPORTS');
export const need_help_reducers = createApiReducer('NEED_HELP');




export const package_registration_reducers = createApiReducer('AUTH_PACKAGE_REGISTRATION');
export const creation_registration_reducers = createApiReducer('AUTH_CREATION_REGISTRATION');
export const package_verify_reducers = createApiReducer('VERIFY_PACKAGE_OTP');
export const package_creation_verify_reducers = createApiReducer('VERIFY_PACKAGE_CREATION_OTP');
export const capture_user_package_reducers = createApiReducer('CAPTURE_USER_PACKAGE');
export const capture_user_creation_reducers = createApiReducer('CAPTURE_USER_CREATION');
export const generate_otp_reducers = createApiReducer('GENERATE_OTP');
export const verify_otp_reducers = createApiReducer('VERIFY_LOGIN_OTP');
export const yoga_session_reducers = createApiReducer('YOGA_SESSION');
export const dental_book_reducers = createApiReducer('DENTAL_BOOK');
export const eye_book_reducers = createApiReducer('EYE_BOOK');
export const opd_book_reducers = createApiReducer('OPD_BOOK');
export const department_time_slot_reducers = createApiReducer('DEPARTMENT_TIME_SLOT');
export const bookTeleconsult_reducers = createApiReducer('BOOK_TELECONSULT');


//TELCONSULTATION -----------------------------

export const tele_department_list_reducers = createApiReducer('FETCH_DEPARTMENT_LIST');
export const tele_symptoms_list_reducers = createApiReducer('FETCH_SYMPTOMS_LIST');
export const tele_banners_list_reducers = createApiReducer('TELECONSULTATION_BANNERS_LIST');

export const privacy_policy_reducers = createApiReducer('PRIVACY_POLICY_DATA');
export const termsCondition_reducers = createApiReducer('TERMS_CONDITION_DATA');


// AUTO LOGIN EMPLOYEE
// export const Employee_medicine_cart_list_reducer = createApiReducer('FETCH_AUTO_MEDICINE_CART_LIST');
// export const Employee_medicine_order_list_reducer = createApiReducer('FETCH_AUTO_MEDICINE_ORDER_LIST');
// export const Employee_package_list_reducer = createApiReducer('FETCH_AUTO_PACKAGE_LIST');
// export const Employee_medicine_cart_reducer = createApiReducer('MEDICINE_AUTO_CART_ADD');
// export const Employee_medicine_delete_reducer = createApiReducer('MEDICINE_AUTO_CART_DELETE');
// export const Employee_medicine_order_reducer = createApiReducer('ORDER_AUTO_MEDICINE');
export const Employee_auto_thank_reducer = createApiReducer('FETCH_AUTO_THANK_YOU');




// RADIOLOGY REDUCERS
export const HomeRadioReducer=createApiReducer('FETCH_HOME_RADIO');
export const HomeRadioCategoriesReducer=createApiReducer('FETCH_RADIO_CATEGORIES');
export const HomeRadioPackagesReducer=createApiReducer('FETCH_RADIO_PACKAGES');
export const HomeRadioListReducer=createApiReducer('SEARCH_RADIOLOGY_TEST');

export const radioCartAddReducer = createApiReducer('RADIO_CART_ADD');
export const radioRemoveCartReducer = createApiReducer('RADIO_REMOVE_CART');
export const radiopatientmemberlistReducer = createApiReducer('RADIO_PATIENT_MEMBERS_LIST');
export const radioaddPatientMembersReducers = createApiReducer('RADIO_ADD_PATIENT_MEMBERS');
export const radiodeletePatientReducer = createApiReducer('RADIO_DELETE_PATIENT_MEMBERS');
export const radiosavePatientMembersReducers = createApiReducer('RADIO_SAVE_PATIENT_MEMBERS');
export const radiosaveSlotTimeReducers = createApiReducer('RADIO_GET_TIME_SLOTS');
export const radiostateReducers = createApiReducer('RADIO_STATE');
export const radiocityReducers = createApiReducer('RADIO_CITY');
export const radiocenterReducers = createApiReducer('RADIO_CENTER');


// Hospital Reducers
export const HospitalCategoriesListReducers = createApiReducer('HOSPITAL_CATEGORIES_LIST');
export const HospitalListReducers = createApiReducer('HOSPITAL_LIST');
export const HospitalServicesReducers = createApiReducer('HOSPITAL_SERVICES');
export const HospitalDetailsReducers = createApiReducer('HOSPITAL_DETAILS');
export const DoctorDetailsReducers = createApiReducer('DOCTOR_DETAILS');
export const DoctorListReducers = createApiReducer('DOCTOR_LIST');
export const DoctorPatientFormReducers = createApiReducer('DOCTOR_PATIENT_FORM');
export const DoctorOrderDetailReducers = createApiReducer('DOCTOR_ORDER_DETAIL');
export const DoctorAmountUtilizationReducers = createApiReducer('DOCTOR_AMOUNT_UTILIZATION');
export const DoctorCapturePaymentReducers = createApiReducer('DOCTOR_CAPTURE_PAYMENT');
export const DoctorOrderListReducers = createApiReducer('DOCTOR_ORDER_LIST');
export const DoctorRecentReducers = createApiReducer('RECENT_DOCTORS');
export const HospitalRecentReducers = createApiReducer('RECENT_HOSPITALS');
export const DoctorBannersReducers = createApiReducer('DOCTORS_Banners');
export const DentalPartnersReducers = createApiReducer('DENTAL_PARTNERS');
export const EyePartnersReducers = createApiReducer('EYE_PARTNERS');
export const DentalPartnersDetailsReducers = createApiReducer('DENTAL_PARTNERS_DETAILS');
export const EyePartnersDetailsReducers = createApiReducer('EYE_PARTNERS_DETAILS');
export const DoctorTimeSlotReducers = createApiReducer('DOCTOR_TIME_SLOT');
export const DoctorTimeSlotFormReducers = createApiReducer('DOCTOR_TIME_FORM');


export const DentalTimeSlotReducers = createApiReducer('DENTAL_TIME');
export const DentalFormReducers = createApiReducer('DENTAL_FORM');
export const DentalOrderDetailReducers = createApiReducer('DENTAL_ORDER_DETAIL');
export const DentalUtilizationReducers = createApiReducer('DENTAL_UTILIZATION');
export const DentalCapturePaymentReducers = createApiReducer('DENTAL_CAPTURE_PAYMENT');
export const DentalOrderListReducers = createApiReducer('DENTAL_ORDER_LIST');
export const DentalReseduleReducers = createApiReducer('DENTAL_RESEDULE');

// EYE
export const EyeTimeSlotReducers = createApiReducer('EYE_TIME');
export const EyeFormReducers = createApiReducer('EYE_FORM');
export const EyeOrderDetailReducers = createApiReducer('EYE_ORDER_DETAIL');
export const EyeUtilizationReducers = createApiReducer('EYE_UTILIZATION');
export const EyeCapturePaymentReducers = createApiReducer('EYE_CAPTURE_PAYMENT');
export const EyeOrderListReducers = createApiReducer('EYE_ORDER_LIST');
export const EyeRescheduleReducers = createApiReducer('EYE_RECHEDULE');
export const DiabetesReducers = createApiReducer('DIABETIES_API');

// Vendor
export const VendorReducers = createApiReducer('VENDOR_API');
export const VendorCommonPackageReducers = createApiReducer('VENDOR_PACKAGE_API');
export const OPDDepartmentReducers = createApiReducer('FETCH_OPD_DEPARTMENT_LIST');





