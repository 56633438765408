import React, { useState } from 'react'
import { headerLogo } from '../../../images'
import { validateNumberInput } from '../../../utils/AllFunction';
import { genderList, relationshipList } from '../../../data';
import { useForm } from 'react-hook-form';
import useLocationState from '../../Medicine/hooks/useLocationState';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const FamilyForm = () => {
    const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();
    const PackData = useLocationState();
    console.log(PackData)
    const userData = JSON.parse(localStorage.getItem('UserData'))
    const [date, setDate] = useState(null);
    const onSubmit = async (data) => {
        const formattedDate = new Date(data.date_of_birth)
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-");

        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("gender", data.gender);
        formData.append("relation", data.relation);
        formData.append("date_of_birth", formattedDate);

        console.log("Submitting FormData:", Object.fromEntries(formData.entries()));
    }
    return (
        <div className="home-review-banner">
            <div className="home-app-header">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name">
                        <p>Add Family Members</p>
                    </div>
                </div>
            </div>

            <div className="family-repo-loki">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="heading-data-form">
                        {/* <p>Get in person consultation reimbursement up to 5000</p> */}
                    </div>
                    <div className="form-data-repo-box mt-3">
                        <input type="text" value={userData?.mobile_number} disabled />
                        <input type="text" value={userData?.email_id} disabled />
                        <input type="text" value={PackData?.selectedData?.name} disabled />
                    </div>
                    <div className="form-page-data mt-2">
                        <div className="page-heading-text">
                            <p style={{ fontSize: '18px' }}>Family Details</p>
                        </div>

                        <div className="form-page">
                            <input type="text" placeholder='Enter Name' {...register('name', { required: true })} />
                            {errors.patient_name && <span className='error-text-mssg'>This field is Required</span>}
                            <div className="gender-select-family">
                                <select
                                    name="gender"
                                    {...register('gender', { required: true })}>
                                    <option value="" selected disabled>Select Gender</option>
                                    {genderList.map((data, index) => (
                                        <option key={index} value={data?.text}>{data?.text}</option>

                                    ))}
                                </select>
                                {errors.gender && <span className='error-text-mssg gen-text'>Gender is required</span>}
                            </div>
                            <div className="gender-select-family">
                                <select
                                    name="relation"
                                    {...register('relation', { required: true })}>
                                    <option value="">Select Relation</option>
                                    {PackData?.data?.relations?.map((data, index) => (
                                        <option key={index} value={data?.name}>{data?.value}</option>
                                    ))}
                                </select>
                                {errors.relation && <span className='error-text-mssg gen-text'>Relation is required</span>}
                            </div>
                            <div className="gender-select-family bro-class">
                                <DatePicker
                                    name="date_of_birth"
                                    {...register("date_of_birth", {
                                        required: "Please select an appointment date",
                                    })}
                                    selected={date} // ✅ Pass the actual date state
                                    onChange={(date) => {
                                        setDate(date);
                                        setValue("date_of_birth", date, { shouldValidate: true });
                                        clearErrors("date_of_birth");
                                    }}
                                    placeholderText="Select a Booking Date"
                                    showYearDropdown={false}
                                    showMonthDropdown={false}
                                    autoComplete="off"
                                    maxDate={new Date()} // ✅ Users can select today or past dates
                                    dateFormat="dd-MM-yyyy"
                                />

                                <span className="text-danger validation-error">
                                    {errors.date_of_birth && errors.date_of_birth.message}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="submit-btn-wrap">
                        <button>Submit</button>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default FamilyForm