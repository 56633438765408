import React, { useEffect, useState } from 'react'
import { headerLogo, needHelp, teleBanner1, teleBanner2 } from '../../images'
import { FiSearch } from 'react-icons/fi';
import './doctorhome.css'
import { ban1, ban2, DoctorBannerImage, DoctorEyeImage, hospitalIcon, no_preview, prescription_image } from '../Medicine/Images';
import { DoctorData, HospitalCategories, HospitalData, HospitalFaq } from '../../data';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router';
import { Accordion } from 'react-bootstrap';
import DoctorCard from './DoctorCard';
import VerticalDoctorCard from './VerticalDoctorCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDepartmentsList, fetchDoctorList, fetchDoctorsBanners, fetchOPDDepartmentsList, fetchPackageList, fetchRecentDoctors } from '../../Redux/actions/GetApiCall';
import MediLoader from '../Medicine/animation/MediLoader';
import TeleconsultationSection from '../LocationSection/TeleconsultationSection';
import CommonPackage from '../MyProfile/Components/CommonPackage';

// function createRotatingShadowAnimation(color) {
//     const styleSheet = document.styleSheets[0];
//     // const animationName = `rotating-shadow-${color.replace('#', '')}`;

//     // Check if animation already exists to prevent duplicates
//     if ([...styleSheet.cssRules].some(rule => rule.name === animationName)) return animationName;

//     // Create the keyframes with the dynamic color
//     const keyframes = `
//     @keyframes ${animationName} {
//         0%   { box-shadow: 0px -2px 4px ${color}; }
//         25%  { box-shadow: 2px 0px 4px ${color}; }
//         50%  { box-shadow: 0px 2px 4px ${color}; }
//         75%  { box-shadow: -2px 0px 4px ${color}; }
//         100% { box-shadow: 0px -2px 4px ${color}; }
//     }
// `;


//     // Insert the new keyframes rule into the stylesheet
//     styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
//     return animationName;
// }


const DoctorHome = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const DepartmentData = useSelector(state => state.opd_department_list_api);
    const DepartmentLoader = useSelector(state => state.opd_department_list_api.loader);
    const DoctorListData = useSelector(state => state.doctor_list);
    const { loader: doctorrecentloader, data: DoctorRecentData, error: DoctorRecentError, } = useSelector(state => state.doctor_recent_list);
    const { loader: Bannerloader, data: DoctorBanner, } = useSelector(state => state.doctor_banner);
    const DoctorListLoader = useSelector(state => state.doctor_list.loader);
    const packagesData = useSelector((state) => state.package_list);



    useEffect(() => {
        dispatch(fetchOPDDepartmentsList())
        dispatch(fetchRecentDoctors())
        dispatch(fetchPackageList());
        dispatch(fetchDoctorsBanners())
    }, [])

    console.log(DepartmentData?.data?.data?.explore_department)

    useEffect(() => {
        if (DepartmentData) {
            dispatch(fetchDoctorList(DepartmentData?.data?.data?.explore_department?.explore_department_id));
        }
    }, [DepartmentData]);

    const handleHospitalDetails = (item) => {
        navigate('/hospital-details', { state: item })
    }

    const handleDoctorDetails = (id) => {
        navigate('/doctor', { state: id })
    }
    const handleDoctorDepartment = () => {
        navigate('/doctor-department', { state: DepartmentData })
    }

    const handleExploreDoctor = () => {
        navigate('/expolre-doctor', { state: DepartmentData?.data?.data?.explore_department?.explore_department_id })
    }

    const handleSearch = () => {
        navigate("/search-doctor")
    }

    const handleOpdForm = () => {
        navigate("/opd-form")
    }

    const height = "150px"

    console.log(DoctorListData)

    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="tele-data-wrapper">
                    <div className="header-logo tele-logo mr-3">
                        <img
                            src={headerLogo}
                            className="header-medibhai-logo"
                            width="100%"
                            alt="header-logo"
                        />
                    </div>
                    <h6 className="mb-0">OPD</h6>
                </div>
                <div className="home-header-teleconsultation d-flex justify-content-end align-items-center" style={{ gap: '75px' }}>
                    <div className="header-navigation-icon head-repo-bor">
                        <CommonPackage />
                    </div>
                </div>
            </div>

            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search doctor..."
                    onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s]*$/;  // Regular expression to allow letters and spaces only
                        if (!regex.test(e.key)) {
                            e.preventDefault(); // Prevent non-letter characters
                        }
                    }}
                    onClick={handleSearch}
                />
                <FiSearch />

            </div>

            <div className="Hospital-department-repo-clover hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <p>Departments</p>
                    <button onClick={handleDoctorDepartment}>View All</button>
                </div>

                {/* {} */}
                {DepartmentLoader ? (
                    <MediLoader height={height} />
                ) : (
                    <div className="department-clover-repo-hos">
                        {DepartmentData?.data?.data?.department.slice(0, 6).map((item, index) => {
                            return (
                                <div className="department-card-clover" key={index} onClick={() => handleDoctorDetails(item?.id)}>
                                    <div
                                        className="icons-repo-cover-de"
                                    >
                                        <img src={item?.file_name ? `${item?.file_name
                                            }` : DoctorEyeImage}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = DoctorEyeImage;
                                            }}
                                            width="100%"
                                            alt="product-icon" />
                                    </div>

                                    <div className="text-department-repo">
                                        <p>{item?.name}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            <div className="not-have-presciption-repo" style={{ marginTop: '0px', marginBottom: '15px', justifyContent: 'space-between' }} onClick={handleOpdForm}>
                <div className="data-box-image-presc" style={{ cursor: 'pointer' }}>
                    <img style={{ width: '95%' }} src={DoctorBannerImage} alt="prescription-banner" />
                </div>
            </div>

            <div className="Hospital-department-repo-clover hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <p>Explore Our {DepartmentData?.data?.data?.explore_department?.explore_department_name}</p>
                    <button onClick={handleExploreDoctor}>View All</button>
                </div>

                {DoctorListLoader ? (
                    <MediLoader height={height} />
                ) : (
                    <div className="hospital-categories-wrapper mt-2">
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={1.2}
                            loop={true}
                            modules={[Autoplay]}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            speed={600}
                            className='pb-2'
                        >
                            {DoctorListData?.data?.data?.map((item, index) => (
                                <SwiperSlide key={index}
                                >
                                    <VerticalDoctorCard Data={item} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                )}
            </div>




            {/* <div className="Hospital-banner-repo-clover hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <p>Banners</p>
                </div>

                {Bannerloader ? (
                    <MediLoader height={height} />
                ) : (
                    <div className="hospital-categories-wrapper">
                        <Swiper
                            spaceBetween={5}
                            slidesPerView={1}
                            loop={true}
                            modules={[Autoplay]}
                            autoplay={{
                                delay: 3000, // Adjust the delay for autoplay
                                disableOnInteraction: false, // Allows autoplay to continue after user interactions
                            }}
                            speed={600}
                            className='pb-2'
                        >
                            <SwiperSlide>
                                <div className="banner-image-repo-clover">
                                    <img src={ban1} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="banner-image-repo-clover">
                                    <img src={ban2} alt="" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                )}

            </div> */}

            {doctorrecentloader ? (
                <MediLoader height={height} />
            ) : (
                DoctorRecentData?.data?.length > 0 && (
                    <div className="hospital-wrapper-box">
                        <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                            <p>Recent Doctors</p>
                            {/* <button>View All</button> */}
                        </div>
                        <div className="hospital-categories-wrapper">
                            <Swiper
                                spaceBetween={1}
                                slidesPerView={1}
                                loop={true}
                                modules={[Autoplay]}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                speed={600}
                                className='pb-2'
                            >
                                {DoctorRecentData?.data?.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <DoctorCard doctor={item} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                )
            )}



            {/* <div className="hospital-faq-department-data hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <div className="need-help-banner p-0">
                        <img src={needHelp} alt="need help"></img>
                    </div>
                </div>

                <div className="need-help-accordion-main-cover-wrapper">
                    <Accordion defaultActiveKey="0" className='faq-box-clover'>
                        {HospitalFaq?.map((helpData, ind) => {
                            return (
                                <Accordion.Item eventKey={ind} key={ind}>
                                    <Accordion.Header>
                                        {helpData?.question}
                                    </Accordion.Header>
                                    <Accordion.Body>{helpData?.answer}</Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>
                </div>
            </div> */}
        </div>
    )
}

export default DoctorHome