import { useState, useEffect } from 'react';

const GoogleLocation = () => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  const getCurrentLocation = async () => {
    if (navigator.geolocation) {
      try {
        const position = await getCurrentPosition();
        const { latitude, longitude } = position.coords;

        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBz8qUh5g91GyggiZf6eNqOC6jKMErllxA`
        );

        const data = await response.json();

        if (data.results && data.results.length > 0) {
          const addressComponents = data.results[0].address_components;
          const pincode = addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || '';
          const state = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name || '';
          const city = addressComponents.find((component) =>
            component.types.includes("locality")
          )?.long_name || '';
          const fullAddress = data.results[0].formatted_address;
          const shortAddress = `${addressComponents[1]?.short_name},${addressComponents[4]?.short_name}`;

          setLocation({
            latitude,
            longitude,
            pincode,
            state,
            city,
            fullAddress,
            shortAddress,
          });
        } else {
          console.error(
            "Invalid response structure from Google Maps Geocoding API:",
            data
          );
        }
      } catch (error) {
        setError(`Error getting location: ${error.message}`);
      }
    } else {
      setError("Geolocation is not supported by your browser");
    }
  };

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error),
        { enableHighAccuracy: true }
      );
    });
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  return { location, error, getCurrentLocation };
};

export default GoogleLocation;
