import React, { useEffect, useState } from 'react';
import '../Medicine_Style/thankyou.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchDentalOrderDetail,
    fetchDoctorOrderDetail,
    fetchEyeOrderDetail,
    fetchLabOrderDetails,
    fetchRadiologyOrderDetails,
    fetchThankyouData,
    getConsultationDetails,
    getQueryParams,
} from '../../../Redux/actions/GetApiCall';
import LoaderAnime from '../animation/prescription_loader';
import { useNavigate } from 'react-router-dom';
import useLocationState from '../hooks/useLocationState';
import ThankMedicine from '../Components/ThankMedicine';
import ThankTeleconsultation from '../Components/ThankTeleconsultation';
import ThankDiagontics from '../Components/ThankDiagontics';
import ThankRadiology from '../Components/ThankRadiology';
import ThankOpdOrder from '../../Radio/ThankOpdOrder';
import ThankDentalOrder from '../../Radio/ThankDentalOrder';
import ThankEyeOrder from '../../Radio/ThankEyeOrder';

const ThankYou = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const orderData = useLocationState();
    const OrderLabData = useLocationState();
    const OrderTeleData = useLocationState();
    const OrderRadioData = useLocationState();
    const OrderDoctorData = useLocationState();
    const OrderDentalData = useLocationState();
    const OrderEyeData = useLocationState(); // Eye order state

    const ThankData = useSelector((state) => state.thankyou_data);
    const OrderDetailsData = useSelector((state) => state.lab_order_Data_Details);
    const radioDetailsData = useSelector((state) => state.radiology_order_list_response);
    const DoctorOrderData = useSelector((state) => state.doctor_order_detail);
    const DentalOrderData = useSelector((state) => state.dental_order_detail);
    const EyeOrderData = useSelector((state) => state.eye_order_detail); // Eye order data
    const consultation_details_response = useSelector(
        (state) => state.get_consultation_details_response
    );

    const navigate = useNavigate();
    const V2HREF = localStorage.getItem('V2HREF');

    const currentUrl = window.location.href;
    const { auto } = getQueryParams(currentUrl);
    const { id } = getQueryParams(currentUrl);

    useEffect(() => {
        if (orderData && orderData?.medicine_order_id) {
            dispatch(fetchThankyouData(orderData?.medicine_order_id));
        }
    }, []);

    useEffect(() => {
        if (OrderLabData && OrderLabData?.tabKey === 'second')
            dispatch(fetchLabOrderDetails(OrderLabData?.orderId));
    }, []);

    useEffect(() => {
        if (OrderLabData && OrderLabData?.tabKey === 'forth')
            dispatch(fetchRadiologyOrderDetails(OrderLabData?.orderId));
    }, []);

    useEffect(() => {
        if ((OrderTeleData && OrderTeleData?.tabKey === 'third') || id) {
            dispatch(getConsultationDetails(OrderTeleData?.orderId || id));
        }
    }, []);

    useEffect(() => {
        if (OrderDoctorData && OrderDoctorData?.tabKey === 'fifth') {
            dispatch(fetchDoctorOrderDetail(OrderDoctorData?.orderId));
        }
    }, []);

    useEffect(() => {
        if (OrderDentalData && OrderDentalData?.tabKey === 'Six') {
            dispatch(fetchDentalOrderDetail(OrderDentalData?.orderId));
        }
    }, []);

    useEffect(() => {
        if (OrderEyeData && OrderEyeData?.tabKey === 'Seven') {
            dispatch(fetchEyeOrderDetail(OrderEyeData?.orderId));
        }
    }, []);

    useEffect(() => {
        if (
            ThankData?.status === 'loading' ||
            OrderDetailsData?.status === 'loading' ||
            consultation_details_response?.status === 'loading' ||
            radioDetailsData?.status === 'loading' ||
            DoctorOrderData?.status === 'loading' ||
            DentalOrderData?.status === 'loading' ||
            EyeOrderData?.status === 'loading' // Eye loading state
        ) {
            setShowModal(true);
        } else if (
            ThankData?.status === 'success' ||
            OrderDetailsData?.status === 'success' ||
            consultation_details_response?.status === 'success' ||
            radioDetailsData?.status === 'success' ||
            DoctorOrderData?.status === 'success' ||
            DentalOrderData?.status === 'success' ||
            EyeOrderData?.status === 'success' // Eye success state
        ) {
            setShowModal(false);
        }
    }, [ThankData, OrderDetailsData, consultation_details_response, radioDetailsData, DoctorOrderData, DentalOrderData, EyeOrderData]);

    const handleHome = () => {
        if (orderData?.tabKey === 'third') {
            navigate('/home');
            window.location.reload();
        } else if (OrderLabData?.tabKey === 'second') {
            navigate('/home');
            window.location.reload();
        } else if (OrderRadioData?.tabKey === 'forth') {
            navigate('/home');
            window.location.reload();
        } else if (OrderDoctorData?.tabKey === 'fifth') {
            navigate('/home');
            window.location.reload();
        } else if (OrderDentalData?.tabKey === 'Six') {
            navigate('/home');
            window.location.reload();
        } else if (OrderEyeData?.tabKey === 'Seven') {
            navigate('/home');
            window.location.reload();
        } else {
            navigate('/home');
            window.location.reload();
        }
    };
    const handleAutoclose = () => {
        if (V2HREF) {
            window.location.href = V2HREF;
        }
    };

    const TeleDataThank = consultation_details_response?.data?.data;

    const DataId = {
        medicine_order_id: orderData?.medicine_order_id,
        onemg_order_id: orderData?.onemg_order_id,
    };

    const TeleData = {
        orderId: TeleDataThank?.id,
        tabKey: 'third',
    };

    const DignosticsData = {
        orderId: OrderLabData?.orderId,
        tabKey: 'second',
    };

    const RadioData = {
        orderId: OrderRadioData?.orderId,
        tabKey: 'forth',
    };

    const OrderDoctorDataRepo = {
        orderId: OrderDoctorData?.orderId,
        tabKey: 'fifth',
    };

    const OrderDentalDataRepo = {
        orderId: OrderDentalData?.orderId,
        tabKey: 'Six',
    };
    const OrderEyeDataRepo = {
        orderId: OrderEyeData?.orderId,
        tabKey: 'Seven',
    };

    const handleOrderDetails = () => {
        if (orderData?.tabKey === 'third') {
            navigate('/teleconsultation-order-details', { state: TeleData });
        } else if (OrderLabData?.tabKey === 'second') {
            navigate('/lab-order-details', { state: DignosticsData });
        } else if (OrderRadioData?.tabKey === 'forth') {
            navigate('/radio-order-details', { state: RadioData });
        }
        else if (OrderDoctorData?.tabKey === 'fifth') {
            navigate('/doctor-order-details', { state: OrderDoctorDataRepo });
        }
        else if (OrderDentalData?.tabKey === 'Six') {
            navigate('/dental-order-details', { state: OrderDentalDataRepo });
        }
        else if (OrderDentalData?.tabKey === 'Seven') {
            navigate('/eye-order-details', { state: OrderEyeDataRepo });
        }
        else {
            navigate('/shipment-details', { state: DataId });
        }
    };

    const product = ThankData?.data?.medicines || (orderData?.orderId && orderData?.AutoData === null) || (OrderLabData?.tabKey == "second") || (OrderRadioData?.tabKey == "forth") || (OrderDoctorData?.tabKey === "fifth") || (OrderDentalData?.tabKey === "Six") || (OrderEyeData?.tabKey === "Seven");
    const address = ThankData?.data?.address;

    const AppointmentDetails = OrderTeleData?.tabKey === 'third' || OrderDoctorData?.tabKey === 'fifth' || OrderDentalData?.tabKey === 'Six' || OrderEyeData?.tabKey === 'Seven'

    return (
        <div className="home-review-banner">
            <div className="home-app-header order-details-repo-data sticky-top">
                <div className="header-logo">
                    <div className="user-name text-center text-repoo">
                        {AppointmentDetails ? (
                            <p>View Details</p>
                        ) : (
                            <p>Order Details</p>
                        )}
                    </div>
                </div>
            </div>

            {showModal ? (
                <LoaderAnime showModal={showModal} />
            ) : (
                <>
                    {!OrderTeleData?.tabKey && !OrderLabData?.tabKey && orderData && (
                        <ThankMedicine ThankData={ThankData} product={product} address={address} />
                    )}

                    {OrderTeleData?.tabKey === 'third' && (
                        <ThankTeleconsultation ThankData={TeleDataThank} />
                    )}

                    {OrderLabData?.tabKey === 'second' && (
                        <ThankDiagontics ThankData={OrderDetailsData?.data?.response} />
                    )}

                    {OrderRadioData?.tabKey === 'forth' && (
                        <ThankRadiology ThankData={radioDetailsData?.data?.response} />
                    )}

                    {OrderDoctorData?.tabKey === 'fifth' && (
                        <ThankOpdOrder ThankData={DoctorOrderData?.data?.data} />
                    )}
                    {OrderDentalData?.tabKey === 'Six' && (
                        <ThankDentalOrder ThankData={DentalOrderData?.data?.data} />
                    )}
                    {OrderEyeData?.tabKey === 'Seven' && (
                        <ThankEyeOrder ThankData={EyeOrderData?.data?.data} />
                    )}

                    {V2HREF && (
                        <div className="order-placed-data-buttons sticky-bottom">
                            <button className="back-home-order" onClick={handleAutoclose}>
                                BACK TO HOME
                            </button>
                        </div>
                    )}

                    {product && !auto && !V2HREF && (
                        <div className="order-placed-data-buttons sticky-bottom">
                            <button className="back-home-order" onClick={handleHome}>
                                BACK TO HOME
                            </button>
                            {AppointmentDetails ? (
                                <button className="details-home-order" onClick={handleOrderDetails}>
                                    APPOINTMENT DETAILS
                                </button>

                            ) : (
                                <button className="details-home-order" onClick={handleOrderDetails}>
                                    ORDER DETAILS
                                </button>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ThankYou;
