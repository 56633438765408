import { useEffect, useRef } from "react";

/**
 * Custom hook to handle infinite scrolling
 * @param {Function} loadMore - Function to load more data when scrolled to bottom
 * @param {boolean} hasMore - Boolean flag to check if more data is available
 * @param {boolean} isLoading - Boolean flag to prevent multiple API calls
 */
const useInfiniteScroll = (loadMore, hasMore, isLoading) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const container = containerRef.current;
            if (!container) return;

            const isAtBottom = container.scrollTop + container.clientHeight >= container.scrollHeight - 10;

            if (isAtBottom && hasMore && !isLoading) {
                loadMore();
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [loadMore, hasMore, isLoading]);

    return containerRef;
};

export default useInfiniteScroll;
