import React, { useEffect, useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import TeleconsultantModal from '../TeleconsultantModal';
import { PackageImage } from '../../images';

const TeleconsultationSection = ({ packageList }) => {
  const [selectedPackage, setSelectedPackage] = useState('');
  const [loading, setLoading] = useState(true);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const storedPackage = localStorage.getItem('PakageData');

  useEffect(() => {
    if (storedPackage) {
      setSelectedPackage(JSON.parse(storedPackage));
    }
    setLoading(false);
  }, [show]);

  console.log(selectedPackage?.id)

  useEffect(() => {
    if (selectedPackage?.id === "" && selectedPackage) {
      setShow(true)
    }
  }, [packageList])


  // useEffect(() => {
  //   if (!selectedPackage && packageList?.length > 0 ) {
  //     setShow(true);
  //   }
  // }, [packageList]);


  return (
    <div className="header-top-section-category-section-location tele-data">
      <button variant="primary" onClick={() => setShow(true)}>
        <img src={PackageImage} alt="" />
        <h5>
          {loading ? 'Loading...' : selectedPackage ? selectedPackage?.name : 'Select Package'}
        </h5>
        <IoMdArrowDropdown />
      </button>
      <TeleconsultantModal packageList={packageList} handleClose={handleClose} handleShow={handleShow} setShow={setShow} show={show} onHide={() => setShow(false)} />
    </div>
  );
};

export default TeleconsultationSection;
